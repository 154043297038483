import axios from "..";
import { IAppIntegrationOption, IAppIntegrationOptionsGetParams } from "../../types";

export const newIAppToken = async (tenantId?: string) => {
  const resp = await axios.post<any, { token: string }>("/iapp/token", tenantId ? { tenantId } : undefined);
  return resp.token;
};
export const newIAppSoloToken = async () => {
  const resp = await axios.post<any, { token: string }>("/iapp/solo-token");
  return resp.token;
};

export const iAppIntegrationOptions = async (params?: IAppIntegrationOptionsGetParams) => {
  const qs = new URLSearchParams();

  if (params?.limit) {
    qs.append("limit", params.limit.toString());
  } else {
    qs.append("limit", "100");
  }

  if (params?.offset) {
    qs.append("offset", params.offset.toString());
  } else {
    qs.append("offset", "0");
  }

  if (params?.search) {
    qs.append("search", params.search);
  }

  const resp = await axios.get<any, { apps: IAppIntegrationOption[] }>(`/iapp/integration-options?${qs.toString()}`);
  return resp.apps;
};
