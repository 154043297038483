import { Store } from "../types";
import * as API from "../../axios/tenants";
import { StateCreator } from "zustand";

export const tenantSlice: StateCreator<Store, [], [], Store["tenant"]> = (set, get) => ({
  selectedTenant: undefined,
  tenants: [],
  resetTenantInfo: () => {
    set((state) => ({
      tenant: { ...state.tenant, selectedTenant: undefined },
    }));
  },
  createTenant: (data) => {
    return API.createTenant(data).then(() => {
      set((state) => ({
        tenant: {
          ...state.tenant,
          selectedTenant: { ...(state.tenant.selectedTenant as any), onboarded: true },
        },
      }));
    });
  },
  getTenantInfo: async (id?: string) =>
    API.getTenant(id).then(async (data) => {
      if (data) {
        const integrations = data?.id ? await get().integrations.listIntegrations([data.id]) : [];
        set((state) => ({
          tenant: { ...state.tenant, selectedTenant: { ...data, integrations } },
        }));
      }
      return data;
    }),
  getTenants: async () => {
    return API.getTenants().then(async (res) => {
      // NOTE(taras) Do not show the "root" tenant
      const tenants = (res || []).filter((tenant) => tenant.name !== "root");
      const tenantIds = tenants.map((tenant) => tenant.id);
      const integrations = tenantIds.length > 0 ? await get().integrations.listIntegrations(tenantIds) : [];
      const mappedTenants = tenants.map((tenant) => {
        const tenantIntegrations = integrations.filter(
          (integration) => integration.tenant_id === tenant.id || integration.tenant_id === "all"
        );
        return { ...tenant, integrations: tenantIntegrations };
      });

      set((state) => ({
        tenant: {
          ...state.tenant,
          // Refresh the selected tenant if it exists in the list
          selectedTenant: mappedTenants.find((tenant) => tenant.id === state.tenant.selectedTenant?.id),
          tenants: mappedTenants,
        },
      }));
    });
  },
});
