import React, { CSSProperties, Dispatch, FC, SetStateAction, useCallback } from "react";
import { AntDropDown, Icon, IconName, Typography } from "shared/components";
import { ReportRow, pnlOptions, Option } from "shared/types";
import styles from "./styles.module.css";
import { addMonths, format, startOfMonth } from "date-fns";
import { Button as AntButton, Tooltip } from "antd";
import { financialFormat } from "shared/utils";

interface IProps {
  columnIndex: number;
  rowIndex: number;
  style: CSSProperties;
  data: {
    computedReportRows: ReportRow[];
    startDate: Date;
    selectedDateOptionLabel: string;
    setSelectedOption: Dispatch<SetStateAction<pnlOptions>>;
    monthCount: number;
    rowCount: number;
    onDrillChanged: (rowIndex: number, drillIndex: number) => void;
    rowDrillIndexer: { [x: number]: number };
  };
}

export const Cell: FC<IProps> = ({ columnIndex, rowIndex, style, data }) => {
  const evenRowClass = rowIndex % 2 === 0 ? styles.even : undefined;
  const handleDrillChanged = useCallback((drillIndex: number) => {
    data.onDrillChanged(rowIndex - 1, drillIndex);
  }, []);
  if (rowIndex === 0) return <HeaderRow data={data} style={style} columnIndex={columnIndex} />;
  if (rowIndex > 0 && columnIndex === 0)
    return (
      <div
        className={`${styles.cell} ${styles.top} ${evenRowClass}`}
        style={{ ...style, padding: `0 ${1.6 * (data.computedReportRows[rowIndex - 1]?.parentLevel || 1)}em` }}
      >
        {data.computedReportRows[rowIndex - 1]?.image && (
          <img
            src={data.computedReportRows[rowIndex - 1].image}
            alt={data.computedReportRows[rowIndex - 1]?.title}
            className={styles.image}
          />
        )}
        <Tooltip placement={"topLeft"} title={data.computedReportRows[rowIndex - 1]?.title}>
          <Typography
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            variant={"body2"}
          >
            {data.computedReportRows[rowIndex - 1]?.title}
          </Typography>
        </Tooltip>
        {(data.computedReportRows[rowIndex - 1].possibleDrillDowns || []).length > 0 && (
          <Selector
            value={data.rowDrillIndexer[rowIndex - 1] || 0}
            options={data.computedReportRows[rowIndex - 1].possibleDrillDowns || []}
            onChange={handleDrillChanged}
          />
        )}
      </div>
    );
  return (
    <div className={`${styles.cell} ${evenRowClass}`} style={style}>
      <Typography style={{ textAlign: "right", width: "100%" }} variant={"body2"}>
        {financialFormat(Number(data.computedReportRows[rowIndex - 1]?.cells[columnIndex - 1].value))}
      </Typography>
    </div>
  );
};

const HeaderRow: FC<Omit<IProps, "rowIndex">> = ({
  data: { startDate, monthCount, selectedDateOptionLabel, setSelectedOption },
  columnIndex,
  style,
}) => {
  const handleOptionClicked = useCallback((e: Option) => {
    setSelectedOption(e.value as pnlOptions);
  }, []);

  if (columnIndex === 0)
    return (
      <div style={style} className={styles.header}>
        <AntDropDown
          width={"30.2em"}
          options={[
            { label: "This Quarter", value: "this_quarter" },
            { label: "Last Quarter", value: "last_quarter" },
            { label: "This Year", value: "this_year" },
            { label: "Last Year", value: "last_year" },
          ]}
          handleSelected={handleOptionClicked}
        >
          <AntButton
            onClick={(e) => {
              e.stopPropagation();
            }}
            type="text"
            className={styles.antBtn}
          >
            <Typography variant={"body2"}>{selectedDateOptionLabel}</Typography>
            <div className={styles.separator} />
            <div className={styles.dropdownIcon}>
              <Icon size={"1.6em"} name={IconName.CHEVRON_DOWN} color="var(--deep-slate-grey)" />
            </div>
          </AntButton>
        </AntDropDown>
      </div>
    );
  if (columnIndex > monthCount)
    return (
      <div style={style} className={styles.header}>
        <Typography
          style={{ textAlign: "right", width: "100%", fontFamily: "SecondaryFont" }}
          variant={"body3"}
          bold
          color={"var(--grey-scale-50)"}
        >
          TOTAL
        </Typography>
      </div>
    );
  return (
    <div style={style} className={styles.header}>
      <Typography
        style={{ textAlign: "right", width: "100%", fontFamily: "SecondaryFont" }}
        variant={"body3"}
        bold
        color={"var(--grey-scale-50)"}
      >
        {format(startOfMonth(addMonths(startDate, columnIndex - 1)), "MMMM").toUpperCase()}
      </Typography>
    </div>
  );
};

const Selector: FC<{ onChange: (drillIndex: number) => void; value: number; options: string[] }> = ({
  onChange,
  value,
  options,
}) => {
  const handleSwitchLeft = useCallback(() => {
    const newSelected = (value + options.length - 1) % options.length;
    onChange(newSelected);
  }, [value, options.length]);
  const handleSwitchRight = useCallback(() => {
    const newSelected = (value + options.length + 1) % options.length;
    onChange(newSelected);
  }, [value, options.length]);
  return (
    <div className={styles.selector}>
      <Icon size={"1.6em"} onClick={handleSwitchLeft} name={IconName.CHEVRON_LEFT} />
      <Typography className={styles.selectorText} variant={"body2"}>
        {options[value]}
      </Typography>
      <Icon size={"1.6em"} onClick={handleSwitchRight} name={IconName.CHEVRON_RIGHT} />
    </div>
  );
};
