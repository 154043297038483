import axios from "..";
import { Role, Token, User } from "../../types";

export const listTokens = (): Promise<Token[]> => axios.get("/token");
export const getUserProfile = (): Promise<User & { user: User }> => axios.get("/user/config");
export const updateUserProfile = ({ ui, id, phoneNumber, picture, givenName, familyName }: Omit<User, "appMetadata">) =>
  axios.post("/user/config", { id, ui, user: { id, phoneNumber, picture, givenName, familyName } });
export const getTeamMembers = (): Promise<User[]> => axios.get("/user");
export const updateTeamMember = (payload: Omit<User, "ui">) => axios.post("/user", payload);
export const updateUserTenants = (payload: { id: string; name: string; authz: { tenantIDs: string[] } }) =>
  axios.post("/role", payload);
export const getUserRoles = (): Promise<Role[]> => axios.get("/role");
export const removeTeamMember = (userId: string) => axios.delete(`/user/${userId}`);
