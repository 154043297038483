import { Button, Icon, IconName, Typography } from "shared/components";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import styles from "./style.module.css";
import { ICategory } from "shared/types";
import { Table } from "shared/components/Table";
import { EditableCell, EditableRow } from "shared/components/Table/TableComponents";
import { generateGUID } from "shared/utils";

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

interface IProps {
  categories: ICategory[];
  setCategories?: Dispatch<SetStateAction<ICategory[]>>;
  system?: boolean;
  editable?: boolean;
  nested?: boolean;
}
export const CategoriesTable: FC<IProps> = ({ categories, setCategories, editable, nested, system }) => {
  const [selectedRows, setSelectedRows] = useState<ICategory[]>([]);

  const handleSave = (row: ICategory) => {
    const newData = [...categories];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      id: row.id === "new" ? generateGUID() : row.id,
    });
    setCategories && setCategories(newData);
  };
  const handleAdd = () => {
    setCategories && setCategories((prevState) => [{ id: "new", name: "New Category" }, ...prevState]);
  };

  const handleDelete = (keys: React.Key[]) => {
    setCategories && setCategories((prevState) => prevState.filter((item) => !keys.includes(item.id)));
  };
  const handleDeleteMultiple = () => {
    const selectedKeys = selectedRows.map((row) => row.id);
    handleDelete(selectedKeys);
    setSelectedRows([]);
  };

  return (
    <Table
      data={categories}
      className={styles.table}
      components={components}
      rowClassName={() => "editable-row"}
      rowKey={"id"}
      setSelectedRows={editable ? setSelectedRows : undefined}
      nested={nested}
      columns={[
        {
          title: editable ? "UPLOADED CATEGORIES" : `${system ? "SOLO " : ""}CATEGORIZATION`,
          dataIndex: "name",
          onCell: (record) => ({
            record,
            editable,
            dataIndex: "name",
            autoFocus: record.id === "new",
            title: editable ? "UPLOADED CATEGORIES" : `${system ? "SOLO " : ""}CATEGORIZATION`,
            handleSave,
          }),
          sorter: (a, b) => a.name.localeCompare(b.name),
          render: (value) => <Typography variant={"body2"}>{value}</Typography>,
        },
        {
          title: () =>
            selectedRows.length === 0 ? (
              <div className={styles.itemAdd} onClick={handleAdd}>
                <div className={styles.iconWrapper}>
                  <Icon color={"var(--white-scale-100)"} name={IconName.PLUS} size={"1.6em"} />
                </div>
                <Typography variant={"body3"} style={{ fontFamily: "SecondaryFont" }} color={"var(--gold)"}>
                  ADD
                </Typography>
              </div>
            ) : (
              <Button
                variant={"alternative"}
                type={"button"}
                onClick={handleDeleteMultiple}
                icon={IconName.CIRCLE_MINUS}
                style={{ padding: "0.2em 1em" }}
              >
                Remove
              </Button>
            ),
          width: "14.5em",
          hidden: !editable,
          render: (_, record) => (
            <Icon
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={() => handleDelete([record.id])}
              name={IconName.TRASH}
              size={"2em"}
            />
          ),
        },
      ]}
    />
  );
};
