import React, { FC, createContext, PropsWithChildren, useState } from "react";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { ConfigProvider as AntDConfigProvider } from "antd";
import { Store } from "shared/store/types";
import { useSdkStore } from "shared/store";
import { newIAppToken, setAxiosAccessToken, useGlobalStyles } from "shared";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IntegrationAppProvider } from "@integration-app/react";

export const ROOT_HTML_ID = "solo-sdk-root";

const queryClient = new QueryClient();

export interface IContextValues {
  accessToken: string;
  tenantId?: string;
  secondaryFont?: boolean;
}

export const SoloConfigContext = createContext<IContextValues | null>(null);

export const ConfigProvider: FC<PropsWithChildren<IContextValues>> = ({
  accessToken,
  tenantId: defaultTenantId,
  secondaryFont,
  children,
}) => {
  const [iaToken, setIaToken] = useState<string>();
  const [tenantId, setTenantId] = useState<string | undefined>(defaultTenantId);
  const getTenantInfo = useSoloStore((state) => state.tenant.getTenantInfo);
  const resetTenantInfo = useSoloStore((state) => state.tenant.resetTenantInfo);
  // const getSDKConfigurations = useSoloStore((state) => state.sdk.getSDKConfigurations);
  const setInitialized = useSoloStore((state) => state.sdk.setInitialized);
  useGlobalStyles(ROOT_HTML_ID, secondaryFont);

  const init = async () => {
    setInitialized(false);
    if (accessToken) {
      setAxiosAccessToken(accessToken);
    }
    const tenantInfo = await getTenantInfo(tenantId);
    setIaToken(await newIAppToken(tenantId !== "root" ? tenantId : undefined));
    if (tenantInfo?.id) {
      setTenantId(tenantInfo.id);
    } else {
      resetTenantInfo();
    }

    if (accessToken) {
      setInitialized(true);
    }

    if (import.meta.env.MODE && import.meta.env.MODE !== "development" && tenantInfo) {
      Sentry.init({
        environment: import.meta.env.MODE,
        dsn: import.meta.env.SOLO_SDK_SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
      Sentry.setUser({
        id: tenantInfo.id,
      });
    }
  };
  useEffect(() => {
    init();
  }, [accessToken, tenantId]);
  useEffect(() => {
    setTenantId(defaultTenantId);
  }, [defaultTenantId]);

  return (
    <QueryClientProvider client={queryClient}>
      <SoloConfigContext.Provider value={{ accessToken, tenantId }}>
        <IntegrationAppProvider token={iaToken}>
          <AntDConfigProvider
            theme={{
              token: {
                colorPrimary: "var(--grey-scale-95)",
                fontFamily: "MainFont",
                fontWeightStrong: 400,
              },
            }}
            getTargetContainer={() => {
              const domElement = document.getElementById(ROOT_HTML_ID);
              if (domElement) {
                return domElement;
              }
              return window;
            }}
            getPopupContainer={() => {
              const domElement = document.getElementById(ROOT_HTML_ID);
              if (domElement) {
                return domElement;
              }
              return document.body;
            }}
          >
            <div style={{ width: "100%", display: "flex" }} id={ROOT_HTML_ID}>
              {children}
            </div>
          </AntDConfigProvider>
        </IntegrationAppProvider>
      </SoloConfigContext.Provider>
    </QueryClientProvider>
  );
};

export function useSoloStore<T>(selector: (state: Store) => T) {
  return useSdkStore(selector);
}
