import axios from "..";
import { SoloFile, SoloFileData, SoloFileMapParams, SoloFilePatchParams, SoloFilePreview } from "../../types";
import { SoloFileListParams } from "./types";
import { PredictFileResponse } from "../../store";

export const getFileById = <T>(id: string, param?: string) => {
  return axios.get<null, T>(`/file/${id}${param || ""}`);
};

export const patchFileById = (id: string, params: SoloFilePatchParams) => {
  return axios.patch<null, SoloFile>(`/file/${id}`, params);
};

export const mapFileById = (id: string, params: SoloFileMapParams) => {
  return axios.post<null, SoloFileData>(`/file/${id}/map`, params);
};

export const getFiles = (params?: SoloFileListParams): Promise<SoloFile[]> => {
  const urlParams = new URLSearchParams();

  if (params?.tenantId) {
    urlParams.append("tenantId", params.tenantId);
  }

  return axios.get<null, SoloFile[]>(`/file?${urlParams.toString()}`);
};

export const getFilePreview = (id: string) => {
  return axios.get<FormData, SoloFilePreview>(`/file/${id}/preview`);
};

export const uploadFile = (rawFile: File, tenantId: string) => {
  const formData = new FormData();

  formData.append("file", rawFile);
  formData.append("tenantId", tenantId);

  return axios.post<FormData, SoloFile>("/file-upload", formData);
};

export const predictFile = (rawFile: File) => {
  const formData = new FormData();

  formData.append("file", rawFile);

  return axios.post<FormData, PredictFileResponse>("/file-predict", formData);
};
