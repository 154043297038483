import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";
import { Root } from "./App";
import "react-datepicker/dist/react-datepicker.css";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { AppState, Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

if (import.meta.env.MODE && import.meta.env.MODE !== "development") {
  Sentry.init({
    environment: import.meta.env.MODE,
    dsn: import.meta.env.SOLO_PLATFORM_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter>
    <Auth0ProviderWithRedirectCallback
      domain={import.meta.env.SOLO_PLATFORM_AUTH0_DOMAIN}
      clientId={import.meta.env.SOLO_PLATFORM_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "solo",
      }}
    >
      <Root />
    </Auth0ProviderWithRedirectCallback>
  </BrowserRouter>
);
