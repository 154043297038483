import { Typography, CustomTabs } from "shared/components";
import React, { FC, useEffect } from "react";
import { useSoloStore } from "../../contexts/soloConfig";
import { ISDKComponentProps } from "../types";
import { ConfigFinancials } from "shared/types";
import styles from "./styles.module.css";
import { withOnboardingRequired } from "../guard";
import { Report } from "./Report";

const FinancialsPage: FC<ISDKComponentProps<ConfigFinancials>> = ({ isPreview, ...sdkCustomProps }) => {
  const { screen_name: sdkName, ...sdkActions } = useSoloStore((state) => state.sdk.sdkConfigurations.financials);

  const { actions, screen_name } =
    (Object.keys(sdkCustomProps).length > 0 ? sdkCustomProps : { screen_name: sdkName, actions: sdkActions }) || {};

  const reports = useSoloStore((state) => state.reports.reports);
  const listReports = useSoloStore((state) => state.reports.listReports);

  useEffect(() => {
    listReports();
  }, []);

  return (
    <>
      <div className={styles.screen}>
        <Typography style={{ fontWeight: 400 }} variant="h1" color="var(--grey-scale-95)">
          {screen_name || "Financials"}
        </Typography>
      </div>
      <CustomTabs
        tabs={[
          ...reports.map((report) => ({
            tabName: report.title,
            component: <Report selectedReport={report} />,
          })),
        ]}
      />
    </>
  );
};

export const Financials = withOnboardingRequired(FinancialsPage);
