import { FC } from "react";
import { Contact } from "shared/types";
import { Drawer } from "antd";
import { EditDetail } from "../EditDetail";

interface IProps {
  relation?: Contact;
  close: () => void;
  submitUpdate: (e: Omit<Contact, "id">) => Promise<void>;
  isPreview?: boolean;
  open: boolean;
}

export const EditDetailDrawer: FC<IProps> = ({ relation, open, isPreview, close, submitUpdate }) => {
  return (
    <Drawer
      maskStyle={{ background: "transparent" }}
      contentWrapperStyle={{
        width: isPreview ? "40em" : "60em",
        boxShadow: "-10px 0px 8px -6px rgba(0, 0, 0, 0.1)",
        borderLeft: "1px solid var(--grey-scale-20)",
      }}
      rootStyle={{
        fontSize: "inherit",
      }}
      styles={{
        body: { padding: "1.6em 0 0 2.4em", display: "flex", flexDirection: "column" },
      }}
      placement="right"
      onClose={close}
      closeIcon={false}
      getContainer={false}
      open={open}
    >
      <EditDetail submitUpdate={submitUpdate} relation={relation} close={close} />
    </Drawer>
  );
};
