import React, {
  ChangeEvent,
  Dispatch,
  FC,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Aggregate, ExpressionItem, Option } from "../../../types";
import { FILTER_OPERATORS, FUNCTION_OPERATORS, generateGUID } from "../../../utils";
import { Button } from "../../Button";
import { Typography } from "../../Typography";
import styles from "./styles.module.css";
import { IconName } from "../../Icon/types";
import { Icon } from "../../Icon";
import { AntDropDown } from "../../AntDropDown";
import { Inputfield } from "../../Inputfield";
import { DatePickerField } from "../../DatePicker";
import { format } from "date-fns";

interface Shared {
  setExpression: Dispatch<SetStateAction<ExpressionItem[]>>;
  type?: "filter" | "function";
  resultScrollableRef?: RefObject<HTMLDivElement>;
}
interface IProps extends Shared {
  expressionOptions: Option[];
  expressionSearch: string;
  header: string;
  hide?: boolean;
  stringResultEnabled?: boolean;
  newAggregateConfig?: boolean;
  searchBar?: boolean;
  setOpenLayout: Dispatch<SetStateAction<"dropdown" | "drawer" | undefined>>;
  onSelectAggregate: (agg: Aggregate) => void;
  resultRef: RefObject<HTMLDivElement>;
  openNewAggregate?: () => void;
  handleRemoveExpression: (id: string) => void;
  handleExprSelect: (
    extraConfig: { option: Option; operatorOption?: Option; value?: string },
    aggregateId?: string
  ) => string;
  nestedOperatorOptions?: Option[];
}
export const ExpressionBuilder: FC<IProps> = ({
  type,
  expressionOptions,
  expressionSearch,
  setExpression,
  header,
  newAggregateConfig,
  resultRef,
  resultScrollableRef,
  handleRemoveExpression,
  hide,
  stringResultEnabled,
  searchBar,
  handleExprSelect,
  onSelectAggregate,
  openNewAggregate,
  setOpenLayout,
  nestedOperatorOptions,
}) => {
  const builderRef = useRef<HTMLDivElement>(null);
  const [selectedExpression, setSelectedExpression] = useState<Option>();
  const [selectedNestedOperator, setSelectedNestedOperator] = useState<Option | undefined>(
    (nestedOperatorOptions || [])[0]
  );
  const [stringValue, setStringValue] = useState<string>("");

  const operationOptions = type === "filter" ? FILTER_OPERATORS : FUNCTION_OPERATORS;
  const filteredOptions =
    selectedExpression && nestedOperatorOptions
      ? nestedOperatorOptions
      : expressionOptions.filter((op) => op.label.includes(expressionSearch));
  const filteredOperators = operationOptions.filter(
    (op) => op.label.includes(expressionSearch) || op.value.includes(expressionSearch)
  );

  const measures = useMemo(() => {
    const table: any = document.getElementById("solo-view-report");
    const style = window.getComputedStyle(table || document.body);

    const dropdownHeight = builderRef.current?.clientHeight || 0;
    const windowHeight = window.innerHeight;
    const margin = parseInt(style.marginBottom);
    const dropdownTop = resultRef.current?.getBoundingClientRect().top || 0;
    const dropdownBtnHeight = resultRef.current?.getBoundingClientRect().height || 0;

    if (windowHeight - dropdownTop - margin - dropdownBtnHeight > dropdownHeight) {
      return {
        top: "100%",
        bottom: "auto",
        margin: "0.8em 0 0",
      };
    } else {
      return {
        // top: "auto",
        // bottom: "100%",
        // margin: "0 0 0.8em",
        top: "100%",
        bottom: "auto",
        margin: "0.8em 0 0",
      };
    }
  }, [builderRef.current, resultRef.current, hide]);

  useEffect(() => {
    if (hide) {
      handleBack();
    }
  }, [hide]);

  const handleExpressionClicked = (option: Option) => {
    const exprId = handleExprSelect({ option });
    setStringValue(option.type === "time" ? format(Date.now(), "yyyy-MM-dd") : "");
    if (nestedOperatorOptions) {
      setSelectedExpression({ ...option, id: exprId });
    }
  };
  const handleExpressionFunctionClicked = (option: Option) => {
    if (!selectedExpression) return;
    handleExprSelect({ option: selectedExpression, operatorOption: option }, selectedExpression?.id);
    handleBack(true);
  };
  const handleBack = (finished?: boolean) => {
    setSelectedExpression((prevState) => {
      if (!finished) {
        handleRemoveExpression(prevState?.id || "");
      }
      return undefined;
    });
    setStringValue("");
    setSelectedNestedOperator((nestedOperatorOptions || [])[0]);
  };
  const handleOperatorSelect = useCallback((option?: Option) => {
    setSelectedNestedOperator(option);
  }, []);
  const handleOpenChange = (state: boolean) => {
    if (state) {
      setOpenLayout("drawer");
    } else {
      setTimeout(() => {
        setOpenLayout(undefined);
      }, 50);
    }
  };
  const handleConfirmation = () => {
    if (!selectedExpression) return;
    handleExprSelect(
      {
        option: selectedExpression,
        operatorOption: (nestedOperatorOptions || []).find((op) => op.label === selectedNestedOperator?.label),
        value: stringValue,
      },
      selectedExpression?.id
    );
    handleBack(true);
  };
  const handleStringValueChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setStringValue(value);
  }, []);

  return (
    <div
      className={`${styles.container} ${hide && styles.hide} ${searchBar && styles.searchBar}`}
      style={{ ...measures, margin: 0 }}
      ref={builderRef}
    >
      <div className={styles.wrapper} style={{ margin: measures.margin }}>
        <NoResult
          result={
            filteredOptions.length > 0 || (filteredOperators.length > 0 && !(type === "filter" && selectedExpression))
          }
        >
          <>
            {(filteredOptions.length > 0 || stringResultEnabled) && (
              <>
                <div className={styles.header}>
                  <div className={styles.headerTitle}>
                    {selectedExpression && (
                      <Icon onClick={() => handleBack()} name={IconName.CHEVRON_LEFT} size={"1.6em"} />
                    )}
                    <Typography variant={"body3"} bold style={{ fontFamily: "SecondaryFont" }} color={"var(--gold)"}>
                      {selectedExpression
                        ? type === "filter"
                          ? "SELECT FILTER VALUE"
                          : "SELECT FUNCTION"
                        : `${header}s`.toUpperCase()}
                    </Typography>
                  </div>
                  {header === "Aggregate" && (
                    <div onClick={openNewAggregate} className={styles.addWrapper}>
                      <Button variant={"alternative"} className={styles.add} icon={IconName.PLUS} iconSize={"1.2em"} />
                      <Typography variant={"body3"} bold style={{ fontFamily: "SecondaryFont" }} color={"var(--gold)"}>
                        NEW AGGREGATE
                      </Typography>
                    </div>
                  )}
                </div>
                {type === "filter" && selectedExpression ? (
                  <div className={styles.body}>
                    <AntDropDown
                      placeholder={"Select Operator"}
                      borderType={"full"}
                      className={styles.operatorSelect}
                      options={nestedOperatorOptions || []}
                      handleSelected={handleOperatorSelect}
                      selected={selectedNestedOperator}
                      onOpenChange={handleOpenChange}
                    />
                    {selectedExpression.type === "time" ? (
                      <div className={styles.date}>
                        <DatePickerField
                          size="big"
                          date={new Date(stringValue)}
                          onChange={(date) => setStringValue(format(date as Date, "yyyy-MM-dd"))}
                        />
                      </div>
                    ) : (
                      <Inputfield
                        type="text"
                        value={stringValue}
                        placeholder={selectedExpression.label}
                        name={""}
                        inputWrapperStyle={{
                          height: "4.1em",
                          flex: 1,
                        }}
                        mt={false}
                        onChange={handleStringValueChange}
                      />
                    )}
                    <Button
                      onClick={handleConfirmation}
                      disabled={!selectedNestedOperator || stringValue?.length === 0}
                    >
                      Confirm
                    </Button>
                  </div>
                ) : (
                  <div className={styles.actionOptions}>
                    {filteredOptions.map((agg) => (
                      <ExpressionOption
                        key={agg.id || agg.value}
                        onSelectAggregate={onSelectAggregate}
                        newAggregateConfig={newAggregateConfig}
                        handleExpressionClicked={
                          selectedExpression ? handleExpressionFunctionClicked : handleExpressionClicked
                        }
                        agg={agg}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
            {filteredOperators.length > 0 && !(type === "filter" && selectedExpression) && (
              <>
                <Typography
                  className={styles.opTitle}
                  variant={"body3"}
                  bold
                  style={{ fontFamily: "SecondaryFont" }}
                  color={selectedExpression ? "var(--grey-scale-30)" : "var(--gold)"}
                >
                  OPERATORS
                </Typography>
                <div className={styles.operators}>
                  {filteredOperators.map((op) => (
                    <ExpressionOperator
                      key={op.label}
                      resultScrollableRef={resultScrollableRef}
                      op={op}
                      disabled={!!selectedExpression}
                      setExpression={setExpression}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        </NoResult>
      </div>
    </div>
  );
};
const NoResult: FC<PropsWithChildren<{ result: boolean }>> = ({ children, result }) => {
  return result ? (
    <>{children}</>
  ) : (
    <>
      <div className={styles.header}>
        <Typography variant={"body3"} bold style={{ fontFamily: "SecondaryFont" }} color={"var(--gold)"}>
          FIELDS
        </Typography>
      </div>
      <div style={{ padding: "1em 1.6em 0" }}>
        <Typography variant={"body2"} color={"var(--grey-scale-50)"}>
          No matches found
        </Typography>
      </div>
    </>
  );
};

interface IExpressionOptionProps {
  agg: Option;
  handleExpressionClicked: (option: Option) => void;
  onSelectAggregate: (agg: Aggregate) => void;
  newAggregateConfig?: boolean;
}
const ExpressionOption: FC<IExpressionOptionProps> = ({
  agg,
  newAggregateConfig,
  handleExpressionClicked,
  onSelectAggregate,
}) => {
  const handleClick = () => {
    handleExpressionClicked(agg);
  };
  const handleEditClicked = (e: any) => {
    e.stopPropagation();
    onSelectAggregate(agg.extra as Aggregate);
  };
  return (
    <div onClick={handleClick} className={styles.actionOption}>
      <Typography variant={"body2"}>{agg.label}</Typography>
      {newAggregateConfig && (
        <div onClick={handleEditClicked} className={styles.edit}>
          <Icon name={IconName.EDIT} size={"1.6em"} />
        </div>
      )}
    </div>
  );
};
interface IExpressionOperatorProps extends Shared {
  op: Option;
  disabled: boolean;
}
const ExpressionOperator: FC<IExpressionOperatorProps> = ({ setExpression, disabled, resultScrollableRef, op }) => {
  const handleOperationSelect = () => {
    if (disabled) return;
    const operators: ExpressionItem[] = [];
    const id = generateGUID();
    if (op.label === "parentheses") {
      operators.push(
        { id: id + "-left", type: "operator", label: IconName.PARENTHESES_LEFT, value: "(" },
        { id: id + "-right", type: "operator", label: IconName.PARENTHESES_RIGHT, value: ")" }
      );
    } else {
      operators.push({ id, type: "operator", label: op.label, value: op.value || "" });
    }
    setExpression((prevState) => [...prevState, ...operators]);
    setTimeout(() => {
      if (resultScrollableRef?.current) {
        resultScrollableRef.current.scrollLeft = resultScrollableRef.current.scrollWidth;
      }
    }, 50);
  };

  return (
    <div onClick={handleOperationSelect} className={`${styles.operator} ${disabled && styles.disabled}`}>
      <Icon square={op.label !== IconName.AND} size={"1.6em"} name={op.label as IconName} />
    </div>
  );
};
