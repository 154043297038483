import React, { FC } from "react";
import { Typography } from "shared/components";
import { Accounts } from "./Accounts";
import { useSoloStore } from "../../contexts/soloConfig";
import { ISDKComponentProps } from "../types";
import { ConfigManageAccounts } from "shared/types";
import styles from "./styles.module.css";
import { withOnboardingRequired } from "../guard";

const ManageAccountsPage: FC<ISDKComponentProps<ConfigManageAccounts>> = ({ isPreview, ...sdkCustomProps }) => {
  const { screen_name: sdkName } = useSoloStore((state) => state.sdk.sdkConfigurations.manage_accounts);
  const { screen_name } = (Object.keys(sdkCustomProps).length > 0 ? sdkCustomProps : { screen_name: sdkName }) || {};

  return (
    <>
      <div className={styles.topWrapper}>
        <Typography style={{ fontWeight: 400 }} variant="h1" color="var(--grey-scale-95)">
          {screen_name || "Accounts"}
        </Typography>
      </div>
      <Accounts />
    </>
  );
};

export const ManageAccounts = withOnboardingRequired(ManageAccountsPage);
