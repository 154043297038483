import axios from "..";
import { Aggregate, IEntity, IEntityNested, SchemasQuery, Table } from "../../types";

export const getAggregates = (): Promise<Aggregate[]> => axios.get("/aggregate");
export const createAggregate = (payload: Omit<Aggregate, "id">): Promise<void> => axios.post("/aggregate", payload);
export const getTables = (): Promise<{ Tables: Table[] }> => axios.get("/inspect");
export const getTable = (name: string): Promise<Table> => axios.get(`/inspect/${name}`);

const getNameFromEntity = (file: IEntity) => {
  return {
    ...file,
    inputName: file?.struct.meta?.inputName?.string || file.name,
  };
};

export const getSchemas = async (q?: SchemasQuery): Promise<IEntity[]> => {
  const query = new URLSearchParams();

  if (q) {
    if (q.names) {
      q.names.forEach((name) => query.append("names", name));
    }
  }

  const resp = await axios.get<null, IEntity[]>(`/schema?${query.toString()}`);

  return resp.map(getNameFromEntity);
};
export const getSchema = (id: string): Promise<IEntity> =>
  axios.get<null, IEntity>(`/schema/${id}`).then(getNameFromEntity);
export const getSchemaNested = (id: string): Promise<IEntityNested> =>
  axios.get<null, IEntityNested>(`/schema/${id}/nested`).then(({ parent, childs }) => ({
    parent: getNameFromEntity(parent),
    childs: childs.map(getNameFromEntity),
  }));
