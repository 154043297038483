import axios from "..";
import { Workflow, WorkflowRaw, WorkflowStatus } from "../../types";

function statusToEnum(status: number): WorkflowStatus {
  switch (status) {
    case 0:
      return WorkflowStatus.UNSPECIFIED;
    case 1:
      return WorkflowStatus.RUNNING;
    case 2:
      return WorkflowStatus.COMPLETED;
    case 3:
      return WorkflowStatus.FAILED;
    case 4:
      return WorkflowStatus.CANCELED;
    case 5:
      return WorkflowStatus.TERMINATED;
    case 6:
      return WorkflowStatus.CONTINUED_AS_NEW;
    case 7:
      return WorkflowStatus.TIMED_OUT;
    default:
  }

  return WorkflowStatus.UNSPECIFIED;
}

export const getWorkflows = async (q?: string): Promise<Workflow[]> => {
  const qs = new URLSearchParams();

  if (q) {
    qs.append("query", q);
  }

  const resp = await axios.get<null, WorkflowRaw[] | null>(`/workflow?${qs.toString()}`);

  if (!resp) {
    return [];
  }

  return resp.map((w) => ({
    ...w,
    status: statusToEnum(w.status),
    memo: Object.entries(w.memo.fields).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: JSON.parse(atob(value.data)),
      }),
      {}
    ),
    search_attributes: Object.entries(w.search_attributes.indexed_fields).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: JSON.parse(atob(value.data)),
      }),
      {}
    ),
  }));
};

export const triggerWorkflow = async (name: string, data?: any) => {
  return axios.post<any, string>(`/wfr/${name}`, data);
};
