import { Typography, Checkbox } from "shared/components";
import React, { FC, useState } from "react";
import { LinkAccounts } from "./LinkAccounts";
import { ISDKComponentProps } from "../types";
import { ConfigLinkAccounts } from "shared/types";
import styles from "./styles.module.css";

export const Onboarding: FC<ISDKComponentProps<ConfigLinkAccounts>> = ({ isPreview }) => {
  const [step, setStep] = useState(0);

  const handleGoNext = () => {
    if (step < 1) {
      setStep((prevState) => prevState + 1);
    }
  };
  const handleGoBack = () => {
    setStep((prevState) => prevState - 1);
  };

  return (
    <div
      style={{
        fontSize: isPreview ? "var(--preview-font-size)" : "var(--base-font-size)",
      }}
      className={styles.wrapper}
    >
      <div className={styles.top}>
        <Typography color={"var(--dark-purple)"} variant="h5" style={{ fontSize: "2.4em" }}>
          Connect Your Accounts
        </Typography>
        <Typography color={"var(--dark-purple)"} style={{ marginTop: "2em" }} variant="body2">
          You will be asked to sign into your accounts to authorize read-only access to your data. This will be used to
          build your P&L, balance sheet, and cash flow statement.
        </Typography>
        <div className={styles.divider} />
        <Checkbox
          checked={step > 0}
          active={step === 0}
          title={"Link Integrations"}
          borderColor={"var(--grey-scale-95)"}
          tickColor={"var(--grey-scale-95)"}
        />
        <Checkbox
          checked={step > 1}
          active={step === 1}
          title={"Summary"}
          borderColor={"var(--grey-scale-95)"}
          tickColor={"var(--grey-scale-95)"}
          hideTrailing
        />
      </div>
      <div className={styles.divider2} />
      <div style={{ width: "60%", display: "flex" }}>
        {step === 0 && <LinkAccounts handleGoBack={handleGoBack} isPreview={isPreview} handleGoNext={handleGoNext} />}
        {step === 1 && (
          <LinkAccounts summary handleGoBack={handleGoBack} isPreview={isPreview} handleGoNext={handleGoNext} />
        )}
      </div>
    </div>
  );
};
