import axios from "..";
import { Integration, IntegrationConfig, Select } from "../../types";

export const createLinkToken = (tenantId?: string): Promise<{ link_token: string }> =>
  axios.post("/plaid/link/create", { tenantId });
export const rutterLink = ({ client_secret, client_id }: IntegrationConfig) =>
  axios.post("/workflow/upsert", [
    {
      kind: "RutterApp",
      document: {
        client_id,
        client_secret,
        // TODO: Make from env
        environment_url: "https://sandbox.rutterapi.com",
        version: "2024-08-31",
      },
    },
  ]);
export const savePublicToken = (args: any) => axios.post("/plaid/account/save", args);
export const getInstitution = (
  id: string
): Promise<{
  institution: {
    institution_id: string;
    name: string;
  };
}> => axios.get(`/plaid/institution/${id}`);

type PlaidAccounts = {
  id: string;
  platform: string;
  tenantId: string;
  data: {
    lastSync: string;
    auth: {
      accounts: Array<{ name: string; official_name: string }>;
      item: {
        institution_id: string;
      };
    };
  };
};

export const listPlaidAccounts = (): Promise<Integration[]> =>
  axios
    .post<any, { rows?: { __document__: PlaidAccounts }[] }, Select>("/select-map", {
      from: {
        table: "Credentials",
      },
      columns: [
        {
          name: "__document__",
        },
      ],
    })
    .then(async (res) => {
      return Promise.all(
        (res.rows || []).map(async (row) => {
          const acc = row.__document__;
          const institution = acc?.data?.auth?.item?.institution_id
            ? await getInstitution(acc?.data?.auth?.item?.institution_id)
            : undefined;

          return {
            id: acc.id,
            updated_at: acc.data.lastSync,
            state: "",
            platform_id: institution?.institution?.name || "",
            platform: acc.platform,
            status: "synced",
            created_at: acc.data.lastSync,
            tenant_id: acc.tenantId,
            accounts: acc?.data?.auth?.accounts || [],
          };
        })
      );
    });

export const listIntegrations = (tenant_ids: string[]) =>
  axios.post<any, { rows?: Integration[] }, Select>("/select-map", {
    from: {
      table: "OauthToken",
    },
    // @ts-ignore
    where: { op: "in", left: { column: "tenantId" }, right: { value: tenant_ids } },
    columns: [
      {
        name: "id",
      },
      {
        name: "platform",
      },
      {
        name: "tenantId",
      },
    ],
  });
export const getRutterCredentials = () =>
  axios.post<any, { rows?: IntegrationConfig[] }, Select>("/select-map", {
    from: {
      table: "RutterApp",
    },
    columns: [
      {
        name: "client_secret",
      },
      {
        name: "client_id",
      },
    ],
  });
