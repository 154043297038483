import { AntDropDown, Avatar, IconName, Typography } from "shared/components";
import { Dispatch, FC, SetStateAction } from "react";
import { financialFormat } from "shared/utils";
import { Skeleton } from "antd";
import { Contact, DIRECTORY_TYPE, Option } from "shared/types";
import { ContactTotals } from "shared/store";
import { useSoloStore } from "../../../contexts/soloConfig";
import styles from "./styles.module.css";

interface IProps {
  relation?: Contact;
  loading: boolean;
  canEditContact?: boolean;
  detailsLoading: boolean;
  noFilterResult: boolean;
  contactTotals: ContactTotals;
  contact_type: DIRECTORY_TYPE;
  setActionName: Dispatch<SetStateAction<"edit" | "merge" | null>>;
}

export const RelationDetails: FC<IProps> = ({
  detailsLoading,
  relation,
  loading,
  contact_type,
  canEditContact,
  noFilterResult,
  contactTotals,
  setActionName,
}) => {
  const color = noFilterResult ? "var(--grey-scale-30)" : "var(--grey-scale-95)";
  const tenantId = useSoloStore((state) => state.tenant.selectedTenant?.id);

  const items: Option[] = [
    {
      label: "Edit Contact Details",
      onClick: () => setActionName("edit"),
    },
  ];

  const settingsMenu = <AntDropDown options={items} icon={IconName.DOTS_VERTICAL} />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        {loading ? (
          <>
            <Skeleton.Avatar style={{ borderRadius: "1.4em", height: "5.6em", width: "5.6em" }} active />
            <Skeleton.Input style={{ height: "4em", width: "22em", borderRadius: "0.5em" }} active />
          </>
        ) : (
          <>
            <Avatar
              size="small"
              src={relation?.avatar}
              viewOnly
              initials={{
                text: noFilterResult ? "Abigail Spencer" : relation?.name || "",
                color,
              }}
              disabled={noFilterResult}
            />

            <Typography variant="h2" color={color}>
              {noFilterResult ? "Abigail Spencer" : relation?.name}
            </Typography>
            {/*{canEditContact && !!relation && settingsMenu}*/}
          </>
        )}
      </div>
      {!!tenantId && (
        <div className={styles.statsWrapper}>
          <RenderStatItem
            main={contact_type === "Vendor" ? "% Expense" : "% Income"}
            secondary={noFilterResult ? "0.8%" : `${Math.round(contactTotals?.percentage || 0)}%`}
            align="start"
            loading={detailsLoading}
            color={color}
            disabled={noFilterResult}
          />
          <RenderStatItem
            main={contact_type === "Vendor" ? "Lifetime Cost" : "Lifetime Earnings"}
            secondary={financialFormat(noFilterResult ? 2699.8 : Math.round(contactTotals?.total || 0))}
            align="center"
            loading={detailsLoading}
            color={color}
            disabled={noFilterResult}
          />
          <RenderStatItem
            main={contact_type === "Vendor" ? "Avg. Spend" : "Avg. Income"}
            secondary={financialFormat(noFilterResult ? 64.95 : Math.round(contactTotals?.average || 0))}
            align="end"
            loading={detailsLoading}
            color={color}
            disabled={noFilterResult}
            total={noFilterResult ? "16 transactions" : `${contactTotals?.number_of_transactions} transactions`}
          />
        </div>
      )}
      {/*//TODO:: Products table*/}
    </div>
  );
};

interface StatItem {
  main: string;
  secondary: string;
  align: "start" | "center" | "end";
  loading: boolean;
  color: string;
  disabled?: boolean;
  total?: string;
}

const RenderStatItem: FC<StatItem> = ({ main, secondary, align, loading, color, disabled, total }) => (
  <div style={{ textAlign: align }} className={styles.statItemWrapper}>
    {loading ? (
      <>
        <Skeleton.Input block style={{ borderRadius: "0.5em", height: "1.8em", minWidth: "11.8em" }} active />
        <Skeleton.Input block style={{ height: "3.2em", borderRadius: "0.5em", minWidth: "11.8em" }} active />
      </>
    ) : (
      <>
        <Typography variant="body3" color={disabled ? "var(--grey-scale-30)" : "var(--grey-scale-50)"} oneLine>
          {main}
        </Typography>
        <Typography variant="h5" color={color} oneLine>
          {secondary}
        </Typography>
        {total && (
          <Typography variant="body3" color={disabled ? "var(--grey-scale-30)" : "var(--grey-scale-50)"} oneLine>
            {total}
          </Typography>
        )}
      </>
    )}
  </div>
);
