import { format } from "date-fns";
import { IEntity, parseDateMagic, SoloFileDataBody, SupportedColumnType } from "shared";

export const TO_FILTER_COLUMNS = ["__document__", "key"];
export const INTERNAL_COLUMNS = ["id", "tenantid", "parentid", "fileid"];

export type FileDataPivoted = {
  data: Record<string, string>[];
  columns: {
    name: string;
    type: SupportedColumnType;
  }[];
};

export const pivotData = (allSchemas: IEntity[], input?: SoloFileDataBody): FileDataPivoted => {
  const data: Record<string, string>[] = [];
  let columns: { name: string; type: SupportedColumnType }[] = [];

  if (!input) {
    return {
      data,
      columns,
    };
  }

  const columnsTypes: { [x: string]: SupportedColumnType } = Object.entries(
    allSchemas.find((s) => s?.name === input.name)?.struct.fields || {}
  ).reduce((acc, [name, fldData]) => {
    return { ...acc, [name]: fldData.type };
  }, {});

  let count = 0;

  for (const row of input.rows) {
    const obj: Record<string, string> = {
      key: count.toString(),
    };

    for (const { column, value } of row.row) {
      if (TO_FILTER_COLUMNS.includes(column.toLowerCase())) {
        continue;
      }

      const colType = columnsTypes[column];

      if (!columns.find((col) => col.name === column)) {
        columns.push({ name: column, type: colType });
      }

      obj[column] = value === "<UNKNOWN>" ? "" : value;

      // NOTE(taras)
      // This should be probably done in the BE
      //
      // Try to magic parse dates
      if (colType === "time") {
        try {
          obj[column] = format(parseDateMagic(value), "yyyy-MM-dd");
        } catch (e) {
          console.warn(`Could not parse date ${value} for column ${column}: (${e})`);

          // Set to empty string to avoid errors in the mapping
          obj[column] = "NaN";
        }
      }
    }

    // NOTE(taras)
    // Setting the id in the FE is a bit hacky, but it will make the
    // BE mapping keep the relationships correctly on updates
    if (!!columnsTypes["id"] && obj["id"] === undefined) {
      obj["id"] = crypto.randomUUID();
    }

    data.push(obj);
    count++;
  }

  return {
    data,
    columns,
  };
};

export const unpivotData = (name: string, data: FileDataPivoted): SoloFileDataBody => {
  return {
    name,
    rows: data.data.map((row) => ({
      row: Object.entries(row).map(([column, value]) => ({ column, value })),
    })),
  };
};
