import { create, StateCreator } from "zustand";
import { Store } from "./types";
import { integrationsSlice } from "./integrations";
import { tenantSlice } from "./tenant";
import { reportsSlice } from "./reports";
import { aggregatesSlice } from "./aggregates";
import { sdkSlice } from "./sdk";
import { settingsSlice } from "./settings";
import { fileSlice } from "./files";
import { fileTypesSlice } from "./fileTypes";
import { workflowsSlice } from "./workflows";

const createSlices: StateCreator<Store> = (...a) => ({
  sdk: { ...sdkSlice(...a) },
  settings: { ...settingsSlice(...a) },
  integrations: { ...integrationsSlice(...a) },
  tenant: { ...tenantSlice(...a) },
  fileTypes: { ...fileTypesSlice(...a) },
  reports: { ...reportsSlice(...a) },
  aggregates: { ...aggregatesSlice(...a) },
  files: { ...fileSlice(...a) },
  workflows: { ...workflowsSlice(...a) },
});

export const useStore = create<Store>()(createSlices);

export const useSdkStore = create<Store>()(createSlices);

export * from ".";
export * from "./tenant/types";
export * from "./integrations/types";
export * from "./directory/types";
export * from "./reports/types";
export * from "./aggregates/types";
export * from "./settings/types";
export * from "./fileTypes/types";
export * from "./files/types";
export * from "./sdk/types";
export { useDirectory } from "./directory";
