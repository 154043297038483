import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Inputfield } from "shared/components";
import { RelationsList } from "../RelationsList";
import { RelationDetails } from "../Detail";
import { ProgressState } from "shared/hooks";
import { Contact, DIRECTORY_TYPE } from "shared/types";
import { useTransactionsRelations } from "../../../hooks/useTransactionsRelations";
// import { useSoloStore } from "../../../contexts/soloConfig";
import { EditDetailDrawer } from "../Detail/EditDetailDrawer";
// import { MergeDrawer } from "../Detail/MergeDrawer";
import styles from "./styles.module.css";

interface IProps {
  contact_type: DIRECTORY_TYPE;
  setRelation: Dispatch<SetStateAction<Contact | undefined>>;
  isPreview?: boolean;
  relation?: Contact;
  canEditContact?: boolean;
}

export const Main: FC<IProps> = ({ contact_type, isPreview, setRelation, relation, canEditContact }) => {
  const [inputValue, setInputValue] = useState("");
  const [actionName, setActionName] = useState<"edit" | "merge" | null>(null);
  // const businessId = useSoloStore((state) => state.business.selected?.id);

  const { directoryContacts, progress, detailProgress, contactTotals, pagination, handleEndReached } =
    useTransactionsRelations({
      selectedRelation: relation,
      contact_type,
      query: inputValue ? inputValue : undefined,
    });
  const noFilterResult =
    progress === ProgressState.success && directoryContacts?.length === 0 && (inputValue?.length || 0) > 0;

  useEffect(() => {
    if (directoryContacts.length <= 20 && pagination.nextPage === 1) {
      setRelation({ ...directoryContacts[0], type: contact_type });
    }
  }, [directoryContacts, pagination]);

  useEffect(() => {
    return () => {
      setRelation(undefined);
    };
  }, []);

  const handleRelationSet = (rel: Contact) => {
    setRelation({ ...rel, type: contact_type });
  };
  const handleInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setInputValue(value);
  };

  const submitUpdate = async (state: any) => {
    if (relation) {
      // const { avatar } = state;
      // return updateDirectoryContact({
      //   businessId,
      //   contact: { ...relation, ...state, avatar: avatar ?? relation.avatar },
      //   contactId: relation.id,
      // }).then(() => {
      //   setRelation({ ...relation, ...state, type: contact_type });
      //   setActionName(null);
      // });
    }
    return Promise.resolve();
  };

  const submitMerge = async (state: { contactId: string; isDelete: boolean; targetContactId: string }) => {
    // return mergeContact({
    //   contactId: state.contactId,
    //   is_delete: state.isDelete,
    //   targetContactId: state.targetContactId,
    // }).then(() => resetTransactions());
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        <Inputfield type={"search"} name="none" value={inputValue} onChange={handleInputChange} />
        <RelationsList
          directoryContacts={directoryContacts}
          loading={progress === ProgressState.loading}
          loadMore={handleEndReached}
          onRowClick={handleRelationSet}
          noFilterResult={noFilterResult}
          relationId={relation?.id}
        />
      </div>
      <div className={styles.detailsWrapper}>
        <RelationDetails
          contactTotals={contactTotals}
          contact_type={contact_type}
          canEditContact={canEditContact}
          relation={relation}
          loading={progress === ProgressState.loading}
          detailsLoading={detailProgress === ProgressState.loading}
          noFilterResult={false}
          setActionName={setActionName}
        />
      </div>
      <EditDetailDrawer
        isPreview={isPreview}
        relation={relation}
        open={actionName === "edit"}
        close={() => setActionName(null)}
        submitUpdate={submitUpdate}
      />
      {/*<MergeDrawer*/}
      {/*  isPreview={isPreview}*/}
      {/*  relation={relation}*/}
      {/*  open={actionName === "merge"}*/}
      {/*  close={() => setActionName(null)}*/}
      {/*  submitMerge={submitMerge}*/}
      {/*  totalTransactions={totalTransactions}*/}
      {/*/>*/}
    </div>
  );
};
