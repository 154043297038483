import { FilterExpression } from "solo-expression-parser/dist/visitors/FilterExpression";
import { AggMathExpression } from "solo-expression-parser/dist/visitors/AggMathExpression";
import { GetProp, UploadProps } from "antd";
import { ReactNode } from "react";

export type FormulaOption = {
  id: string;
  name: string;
};
export type ExpressionItem = {
  type: "operator" | "identifier" | "fn" | "filter";
  id: string;
  label: string;
  value: string;
  extraOptions?: FormulaOption[];
};

export type IPagination = {
  nextPage: number;
  total: number;
};

export type DIRECTORY_TYPE = "Customer" | "Vendor" | "Product";

export interface Contact {
  id: string;
  avatar?: string;
  website?: string;
  name: string;
  email?: string;
  phone?: string;
  address?: string;
  type?: DIRECTORY_TYPE;
  city?: string;
  zip_code?: string;
  state?: string;
  country?: string;
}

export interface Tenant {
  id: string;
  name: string;
  image: string;
  updatedAt: string;
  createdAt: string;
  integrations: Integration[];
  onboarded?: boolean;
}

export type Option = {
  value?: string;
  extra?: any;
  label: string;
  search?: string;
  image?: string | ReactNode;
  type?: SupportedColumnType;
  exclude?: boolean;
  onClick?: () => void;
  id?: string;
};

export type RowProduct = {
  id: string;
  document: string;
};
export type RowCustomer = {
  id: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
};

export type DrillDown = {
  table: string;
  on: [
    {
      agg: string;
      drill: string;
    }
  ];
  select: string[];
};
export type AggregateFunction = {
  name: string;
  expr: FilterExpression;
  over?: string;
};
export type Window = {
  alias: string;
  frameStart: number;
  frameEnd: number;
};
export type Aggregate = {
  id: string;
  name: string;
  title: string;
  table: string;
  timeline: string;
  drillDown: DrillDown[];
  where?: FilterExpression;
  columns: AggregateFunction[];
  window?: Window[];
};
export type SchemasQuery = {
  names?: string[];
};
export type OrderBy = {
  column: string;
  order: "ASC" | "DESC" | "DEFAULT";
};
export type SelectColumn = {
  name: string;
};
export type Select = {
  from: { table: string };
  columns: SelectColumn[];
  where?: FilterExpression;
  orderBy?: OrderBy[];
  limit?: number;
  offset?: number;
};
export type SelectMapReturn<T> = {
  rows: T[] | null;
  columns: string[];
};
export type Column = {
  Name: string;
  Type: SupportedColumnType;
  NotNull: boolean;
};
export type Table = {
  nickname: string;
  Name: string;
  Columns: Column[];
  PrimaryKey?: {
    Name: string;
    Columns: string[];
  };
  Indexes: Array<{
    Name: string;
    Columns: string[];
    Unique: boolean;
  }>;
  Relations: Array<{
    Table: string;
    From: string[];
    To: string[];
  }>;
};
export type TableRelation = {
  left: string; //group(root)_kind(root)
  leftFields: string[]; // from
  right: string; //(group(relation) || group(root))_kind(relation)
  rightFields: string[]; // to
};
export type Schema = {
  group: string;
  kind: string;
  table: {
    relations: {
      group: string;
      kind: string;
      from: string[];
      to: string[];
    }[];
  };
};

export type NewReportRow = {
  name: string;
  title: string;
  formula?: AggMathExpression;
};
export type NewReport = {
  id: string;
  title: string;
  name: string;
  createdAt: string;
  rows: NewReportRow[];
};
export type ReportCell = {
  timeValue: string | "total";
  value: string;
};
export type ReportRow = {
  name: string;
  title: string;
  image?: string;
  cells: ReportCell[];
  parentLevel?: number;
  drill: {
    cells: ReportCell[];
    drillModels: { [x: string]: { column: { node_id: string } } };
  }[];
  package?: {
    tables: {
      [x: string]: {
        models: {
          [x: string]: {
            column: any;
          };
        };
      };
    };
  };
  drillDowns?: string[] | null;
  possibleDrillDowns: string[] | null;
};
export type ComputedReport = {
  id: string;
  bucket: string;
  timeValues: string;
  package: string;
  rows: ReportRow[];
};

export type Integration = {
  created_at: string;
  id: string;
  platform: string;
  platform_id: string;
  state: string;
  accounts?: Array<{ name: string; official_name: string }>;
  image?: string;
  updated_at: string;
  tenant_id: string;
  status: "synced" | "syncing";
};
export type IntegrationConfig = {
  client_secret: string;
  client_id: string;
};
export const ROLES_OPTIONS = ["admin", "officer"] as const;
export type ADMIN_ROLES = (typeof ROLES_OPTIONS)[number];
export type ROLES = ADMIN_ROLES | "tenant";
export type Token = {
  id: string;
  name: string;
  token: string;
  role: ROLES;
};

export type pnlOptions = "this_quarter" | "last_quarter" | "this_year" | "last_year";

export interface UserUIInfo {
  company_name: string;
  company_website: string;
  company_address: string;
  company_city: string;
  company_state: string;
  company_country: string;
}
export interface Role {
  id: string;
  name: string;
  authz: {
    tenantIDs: string[];
  };
}
export interface User {
  id: string;
  userId?: string;
  givenName?: string;
  familyName?: string;
  picture?: string;
  email?: string;
  phoneNumber?: string;
  ui?: UserUIInfo;
  appMetadata?: {
    roles: (ADMIN_ROLES | string)[];
    authz?: {
      id: string;
      name: string;
      tenantIDs: string[];
    };
  };
}

export type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[1][0];

export type SoloFile = {
  id: string;
  tenantId?: string | null;
  name: string;
  bucket: string;
  key: string;
  status?: "uploaded" | "data_extracted" | "parsing_failed" | "parsed" | "mapping_failed" | "mapped";
  fileType?: string | null;
  createdAt: string;
  updatedAt: string;
  metadata: {
    contentType: string;
    size: number;
    source?: string;
  };
};

export type SoloFilePatchParams = Partial<Pick<SoloFile, "name" | "fileType" | "tenantId">>;
export type SoloFileMapParams = Required<Pick<SoloFileData, "mapping">>;

export type SoloFilePreview = {
  file: SoloFile;
  url: string;
};

export type SoloFileRawData = {
  fileId: string;
  tenantId?: string | null;
  conversionId: string;
  conversionStatus: "pending" | "failed" | "ok";
  metadata?: {
    id: string;
    model: string;
    role: string;
    type: string;
    stop_reason?: string;
    stop_sequence?: string;
    content: {
      text: string;
      type: string;
    }[];
  };
  createdAt: string;
  updatedAt: string;
};

export type SoloFileDataBody = {
  name: string;
  rows: {
    row: {
      value: string;
      column: string;
    }[];
  }[];
};

export type SoloFileData = {
  fileId: string;
  tenantId?: string | null;
  data?: SoloFileDataBody[];
  mapping?: SoloFileDataBody[];
  createdAt: string;
  updatedAt: string;
};

export const SUPPORTED_COLUMN_TYPES = [
  "int",
  "uint",
  "float",
  "bool",
  "string",
  "[]string",
  "jsonb",
  "bytes",
  "decimal",
  "time",
  "duration",
  "uuid",
  "any",
  "unknown",
] as const;
export type SupportedColumnType = (typeof SUPPORTED_COLUMN_TYPES)[number];
export const SupportedColumnLabels: { [key in SupportedColumnType]?: string } = {
  uuid: "Unique ID",
  int: "Integer",
  uint: "Positive integer",
  float: "Number",
  decimal: "Decimal",
  bool: "Boolean",
  string: "String",
  time: "Date",
  any: "Nested",
};
export type IEntityStructMeta = {
  string?: string;
  number?: number;
  bool?: boolean;
  array?: IEntityStructMeta[];
  map?: { [x: string]: IEntityStructMeta };
};
export type IEntityMeta = {
  [x: string]: IEntityStructMeta;
};
export type IEntityBase = {
  kind: "Entity";
  name: string;
  cluster: string[];
  struct: {
    type: null;
    meta: IEntityMeta;
    fields: {
      [x: string]: {
        type?: SupportedColumnType;
        meta?: IEntityMeta;
      };
    };
  };
};

export type IEntity = IEntityBase & {
  inputName: string;
};
export type IEntityNested = {
  parent: IEntity;
  childs: IEntity[];
};

export enum WorkflowStatus {
  UNSPECIFIED = "UNSPECIFIED",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  CANCELED = "CANCELED",
  TERMINATED = "TERMINATED",
  CONTINUED_AS_NEW = "CONTINUED_AS_NEW",
  TIMED_OUT = "TIMED_OUT",
}

export type IWorkflow = {
  execution: {
    workflow_id: string;
    run_id: string;
  };
  type: {
    name: string;
  };
  start_time: {
    seconds: number;
    nanos: number;
  };
  close_time: {
    seconds: number;
    nanos: number;
  };
  history_length: number;
  execution_time: {
    seconds: number;
    nanos: number;
  };
  task_queue: string;
  state_transition_count: number;
  history_size_bytes: number;
  execution_duration: {
    nanos: number;
  };
  root_execution: {
    workflow_id: string;
    run_id: string;
  };
};

export type WorkflowRaw = IWorkflow & {
  status: number;
  memo: {
    fields: {
      [x: string]: {
        data: string;
      };
    };
  };
  search_attributes: {
    indexed_fields: {
      [x: string]: {
        data: string;
      };
    };
  };
};

export type Workflow = IWorkflow & {
  status: WorkflowStatus;
  history_length: number;
  execution_time: {
    seconds: number;
    nanos: number;
  };
  memo: {
    [x: string]: any;
  };
  search_attributes: {
    [x: string]: any;
  };
};

export type IAppIntegrationOption = {
  appUri: string;
  key: string;
  logoUri: string;
  name: string;
  defaultConnectorId: string;
  uuid: string;
  categories: string[];
  id: string;
};

export type IAppIntegrationOptionsGetParams = {
  limit?: number;
  offset?: number;
  search?: string;
};
export interface ICategory {
  id: string;
  name: string;
}
export interface ICategoryList {
  name: string;
  id: string;
  options: ICategory[];
  system?: boolean;
}

export type AuditTrail = {
  req_id: string;
  user: string;
  ip: string;
  action: string;
  description?: string;
  issuer?: string;
  __document__: {
    createdAt: string;
    metadata: {
      data: Record<string, any>;
    };
  };
};
