import { useCallback, useEffect } from "react";
import { useSoloStore } from "../contexts/soloConfig";
import { Contact, DIRECTORY_TYPE } from "shared/types";
import { ProgressState, useProgressState } from "shared/hooks";
import { useDebouncedEffect } from "shared/utils";
import { useDirectory } from "shared/store";
import { abortSignal } from "shared/axios";

export interface ITransactionsRelations {
  query?: string;
  contact_type?: DIRECTORY_TYPE;
  selectedRelation?: Contact;
  modalOpen?: boolean;
  readOnly?: boolean;
}
export const useTransactionsRelations = ({
  query,
  contact_type,
  selectedRelation,
  readOnly,
}: ITransactionsRelations) => {
  const { setLoading, progress, setSuccess, setFailure } = useProgressState();
  const {
    setLoading: setDetailLoading,
    progress: detailProgress,
    setSuccess: setDetailSuccess,
    setFailure: setDetailFailure,
  } = useProgressState();

  const { getDirectory, resetDirectory, contacts, getContactTotals, contactTotals, resetDirectoryTotals, pagination } =
    useDirectory();
  const tenantId = useSoloStore((state) => state.tenant.selectedTenant?.id);

  useDebouncedEffect(() => {
    if (readOnly || !tenantId || !contact_type) return;
    setLoading();
    getDirectory({
      tenantId,
      contact_type,
      search: query,
    })
      .then(setSuccess)
      .catch(() => {
        resetDirectory();
      });
  }, [query, tenantId, contact_type, readOnly]);

  useEffect(() => {
    if (selectedRelation?.id && tenantId && contact_type) {
      setDetailLoading();
      abortSignal([`/directory/${selectedRelation.id}/totals`]);
      getContactTotals({ tenantId, contactId: selectedRelation.id, contactType: contact_type })
        .then(setDetailSuccess)
        .catch((e) => {
          setDetailFailure(e);
          resetDirectoryTotals();
        });
    } else {
      resetDirectoryTotals();
    }
  }, [selectedRelation?.id, tenantId]);

  const handleEndReached = useCallback(() => {
    const nextPage = pagination.nextPage;
    if (nextPage !== 0 && progress !== ProgressState.loading) {
      return getDirectory({
        tenantId,
        contact_type,
        page: nextPage,
        loadMore: true,
        search: query,
      })
        .then(setSuccess)
        .catch(setFailure);
    }
    return Promise.resolve();
  }, [pagination.nextPage, progress, tenantId, contact_type, query]);

  return {
    handleEndReached,
    detailProgress,
    progress,
    directoryContacts: contacts,
    contactTotals,
    pagination,
  };
};
