import React, { createContext, ReactNode, PropsWithChildren } from "react";
import { GridOnScrollProps, VariableSizeGrid as Grid, VariableSizeGridProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

export interface IContextValues {
  ItemRenderer?: ReactNode;
  stickyIndices: number[];
}

const StickyListContext = createContext<IContextValues>({ stickyIndices: [] });
StickyListContext.displayName = "StickyListContext";

function getCellIndicies(child: any) {
  return { row: child.props.rowIndex, column: child.props.columnIndex };
}

function getShownIndicies(children: React.ReactNode) {
  let minRow = Infinity;
  let maxRow = -Infinity;
  let minColumn = Infinity;
  let maxColumn = -Infinity;

  React.Children.forEach(children, (child) => {
    const { row, column } = getCellIndicies(child);
    minRow = Math.min(minRow, row);
    maxRow = Math.max(maxRow, row);
    minColumn = Math.min(minColumn, column);
    maxColumn = Math.max(maxColumn, column);
  });

  return {
    from: {
      row: minRow,
      column: minColumn,
    },
    to: {
      row: maxRow,
      column: maxColumn,
    },
  };
}

function useInnerElementType(
  Cell: any,
  columnWidth: (index: number) => number,
  rowHeight: (index: number) => number,
  itemData: any
) {
  return React.useMemo(
    () =>
      React.forwardRef<HTMLDivElement, PropsWithChildren>((props, ref) => {
        function sumRowsHeights(index: number) {
          let sum = 0;

          while (index > 1) {
            sum += rowHeight(index - 1);
            index -= 1;
          }

          return sum;
        }

        function sumColumnWidths(index: number) {
          let sum = 0;

          while (index > 1) {
            sum += columnWidth(index - 1);
            index -= 1;
          }

          return sum;
        }

        const shownIndecies = getShownIndicies(props.children);

        const children = React.Children.map(props.children, (child) => {
          const { column, row } = getCellIndicies(child);

          // do not show non-sticky cell
          if (column === 0 || row === 0) {
            return null;
          }

          return child;
        });

        (children || []).push(
          React.createElement(Cell, {
            data: itemData,
            key: "0:0",
            rowIndex: 0,
            columnIndex: 0,
            style: {
              display: "inline-flex",
              width: columnWidth(0),
              height: rowHeight(0),
              position: "sticky",
              top: 0,
              left: 0,
              zIndex: 4,
            },
          })
        );

        const shownColumnsCount = shownIndecies.to.column - shownIndecies.from.column;

        for (let i = 1; i <= shownColumnsCount; i += 1) {
          const columnIndex = i + shownIndecies.from.column;
          const rowIndex = 0;
          const width = columnWidth(columnIndex);
          const height = rowHeight(rowIndex);

          const marginLeft = i === 1 ? sumColumnWidths(columnIndex) : undefined;

          (children || []).push(
            React.createElement(Cell, {
              data: itemData,
              key: `${rowIndex}:${columnIndex}`,
              rowIndex,
              columnIndex,
              style: {
                marginLeft,
                display: "inline-flex",
                width,
                height,
                position: "sticky",
                top: 0,
                zIndex: 3,
              },
            })
          );
        }

        const shownRowsCount = shownIndecies.to.row - shownIndecies.from.row;

        for (let i = 1; i <= shownRowsCount; i += 1) {
          const columnIndex = 0;
          const rowIndex = i + shownIndecies.from.row;
          const width = columnWidth(columnIndex);
          const height = rowHeight(rowIndex);

          const marginTop = i === 1 ? sumRowsHeights(rowIndex) : undefined;

          (children || []).push(
            React.createElement(Cell, {
              data: itemData,
              key: `${rowIndex}:${columnIndex}`,
              rowIndex,
              columnIndex,
              style: {
                marginTop,
                width,
                height,
                position: "sticky",
                left: 0,
                zIndex: 2,
              },
            })
          );
        }

        return (
          <div ref={ref} {...props}>
            {children}
          </div>
        );
      }),
    [Cell, columnWidth, rowHeight, itemData]
  );
}

export const StickyGrid = React.forwardRef<Grid, Omit<VariableSizeGridProps, "width" | "height">>((props, ref) => {
  const innerElementType = useInnerElementType(props.children, props.columnWidth, props.rowHeight, props.itemData);
  return (
    <div style={{ flex: "1 1 auto" }}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <Grid {...props} ref={ref} width={width} height={height} innerElementType={innerElementType} />
        )}
      </AutoSizer>
    </div>
  );
});

export type GridType = Grid;
export type GridOnScroll = GridOnScrollProps;
