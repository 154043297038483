import React, { useCallback, useState } from "react";
import { Typography } from "../Typography";
import styles from "./styles.module.css";

export interface ICustomTab {
  tabName: string;
  component: any;
  disabled?: boolean;
  exclude?: boolean;
  counter?: number;
}
interface IProps {
  tabs: ICustomTab[];
  onChange?: (idx: number) => void;
  initialIndex?: number;
  bubbles?: boolean;
  className?: string;
}
export const CustomTabs: React.FC<IProps> = ({ tabs, className, initialIndex, bubbles, onChange }) => {
  const [activeIndex, setActiveIndex] = useState<number>(initialIndex || 0);
  const filteredTabs = tabs.filter((tab) => !tab.exclude);
  const handleIndex = useCallback((newIndex: number) => {
    setActiveIndex(newIndex);
  }, []);

  return (
    <>
      <div className={className}>
        <div className={`${styles.container} ${bubbles && styles.bubblesContainer}`}>
          <div className={`${styles.tabsWrapper} ${bubbles && styles.bubblesWrapper}`}>
            {filteredTabs.map((tab, index) => (
              <Tab
                key={tab.tabName}
                tab={tab}
                tabsCount={tabs.length - 1}
                index={index}
                bubbles={bubbles}
                activeIndex={activeIndex}
                setActiveIndex={handleIndex}
                onChange={onChange}
              />
            ))}
          </div>
        </div>
      </div>
      {filteredTabs[activeIndex]?.component}
    </>
  );
};

interface IChildProps {
  tab: ICustomTab;
  index: number;
  activeIndex: number;
  tabsCount: number;
  setActiveIndex: (val: number) => void;
  onChange?: IProps["onChange"];
  bubbles?: boolean;
}

const Tab: React.FC<IChildProps> = ({ tab, tabsCount, bubbles, index, activeIndex, setActiveIndex, onChange }) => {
  const isActive = activeIndex === index;

  const handleOnClick = useCallback(() => {
    if (!tab.disabled) {
      onChange && onChange(index);
      setActiveIndex(index);
    }
  }, [index, onChange]);

  return (
    <div style={{ display: "flex" }}>
      <div
        onClick={handleOnClick}
        style={
          bubbles
            ? {
                background: isActive ? "var(--white-scale-100)" : "transparent",
                border: isActive ? "1px solid var(--grey-scale-95)" : "none",
              }
            : {
                color: isActive ? "var(--gun-powder)" : "#7E7F7E",
                borderBottom: isActive ? "1px solid var(--grey-scale-95)" : "1px solid transparent",
                cursor: tab.disabled ? "not-allowed" : "pointer",
              }
        }
        className={`${styles.tab} ${bubbles && styles.bubblesTab}  ${bubbles && index === 0 && styles.bubblesLeft} ${
          bubbles && index === tabsCount && styles.bubblesRight
        }`}
      >
        <Typography
          color={isActive ? "var(--grey-scale-95)" : "var(--grey-scale-50)"}
          bold={isActive}
          variant={bubbles ? "body3" : "body2"}
          oneLine
        >
          {tab.tabName}
        </Typography>
        {tab.counter ? (
          <div className={styles.counter}>
            <Typography color={isActive ? "var(--grey-scale-95)" : "var(--grey-scale-50)"} variant="body3" bold>
              {tab.counter}
            </Typography>
          </div>
        ) : null}
      </div>
      {!bubbles && <div style={{ width: "2.4em" }} />}
    </div>
  );
};
