import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { Icon } from "../Icon";
import { AvatarProps as Props } from "./types";
import { Typography } from "../Typography";
import { getInitials, readFileAsBase64 } from "../../utils";
import { ClipLoader } from "react-spinners";
import styles from "./styles.module.css";
import { useAvatarSize } from "./useAvatarSize";
import { IconName } from "../Icon/types";

export const Avatar: FC<Props> = ({
  src,
  isWorking,
  viewOnly,
  size = "large",
  backgroundColor,
  onChange,
  border,
  disabled,
  placeholderIcon,
  iconColor,
  radius,
  counter,
  initials,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<string | undefined>();
  const avatarStyles = useAvatarSize(size);

  useEffect(() => {
    setImage(src);
  }, [src]);

  const handleFileUpload = () => inputRef.current?.click();

  const handleChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files) {
      onChange && onChange(await readFileAsBase64(target.files[0]));
      const objectUrl = URL.createObjectURL(target.files[0]);
      setImage(objectUrl);
    }
  };

  return initials && !src && viewOnly ? (
    <div style={avatarStyles} className={`${styles.initialsContainer} ${disabled && styles.disabled}`}>
      <Typography variant={"body3"} color={initials.color}>
        {getInitials(initials.text)}
      </Typography>
    </div>
  ) : (
    <div
      style={{ background: backgroundColor, ...avatarStyles }}
      className={`${styles.avatar} ${border && styles.bordered} ${disabled && styles.disabled}`}
    >
      {image && (
        <img
          src={image}
          alt="avatar"
          style={{
            objectFit: "contain",
            ...avatarStyles,
            borderRadius: radius || avatarStyles.borderRadius,
          }}
        />
      )}
      {counter && (
        <div className={styles.conunterContainer}>
          <Typography color="var(--grey-scale-95)" variant="body3" bold>
            {counter}
          </Typography>
        </div>
      )}
      {!src && (
        <Icon
          name={placeholderIcon ?? IconName.USER}
          size={size === "large" ? "15em" : size === "medium" ? "5em" : "2.4em"}
          color={iconColor}
        />
      )}
      {!viewOnly && (
        <div className={styles.upload}>
          <input ref={inputRef} type="file" onChange={handleChange} style={{ display: "none" }} />
          {isWorking && <ClipLoader size={"2em"} color="var(--white-scale-100)" />}
          {!isWorking && (
            <Icon
              size={"2.4em"}
              onClick={handleFileUpload}
              name={IconName.PLUS}
              color="var(--white-scale-100)"
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      )}
    </div>
  );
};
