import axios from "..";
import { Contact, IPagination, RowCustomer, RowProduct, Select } from "../../types";
import { ContactTotals, GetContactTotalsPayload, GetDirectoryPayload } from "../../store";
import { parseExpression } from "solo-expression-parser";

export const getDirectory = ({
  tenantId,
  page = 0,
  contact_type,
  search,
}: GetDirectoryPayload): Promise<{ data: Contact[]; pagination: IPagination }> => {
  let data: Select = {
    columns:
      contact_type === "Product"
        ? [
            {
              name: "id",
            },
            {
              name: "document",
            },
          ]
        : [
            {
              name: "id",
            },
            {
              name: "email",
            },
            {
              name: "phone",
            },
            {
              name: "last_name",
            },
            {
              name: "first_name",
            },
          ],
    from: {
      table: contact_type === "Product" ? "shopify_v0alpha1_product" : "shopify_customer",
    },
    limit: 20,
    offset: page * 20,
  };

  const titleSearchString = search ? `title LIKE ${"%" + search + "%"}` : "";
  const tenantIdSearchString = tenantId ? `tenant_id = "${tenantId}"` : "";
  const whereString = `${titleSearchString ? titleSearchString + "AND" : ""}${tenantIdSearchString}`;
  const parsedExpr = parseExpression(whereString);
  data = { ...data, where: parsedExpr };

  if (contact_type === "Product") {
    return axios.post<any, { rows: RowProduct[] }>("/select-map", data).then((res) => {
      const mappedResponse = (res.rows || []).map((row) => {
        const {
          document: { title, featuredImage, id },
        } = JSON.parse(row.document);
        return {
          id: id,
          contact_type: "Product",
          avatar: featuredImage?.url,
          name: title,
        } as unknown as Contact;
      });
      return { ...res, data: mappedResponse, pagination: { nextPage: page + 1, total: 0 } };
    });
  }

  return axios.post<any, { rows: RowCustomer[] }>("/select-map", data).then((res) => {
    const mappedResponse = (res.rows || []).map(
      (row) =>
        ({
          id: row.id,
          contact_type: "Customer",
          name: (row?.first_name || "") + (row?.last_name || ""),
          email: row?.email,
          phone: row?.phone,
        } as unknown as Contact)
    );
    return { ...res, data: mappedResponse, pagination: { nextPage: page + 1, total: 0 } };
  });
};

export const getContactTotals = ({
  tenantId,
  contactId,
  contactType,
}: GetContactTotalsPayload): Promise<ContactTotals> => {
  return axios
    .post<
      any,
      {
        name: string;
        rows: [
          {
            columns: {
              [x: string]: {
                val: string;
              };
            };
          }
        ];
      }
    >(`/aggregate/compute`, {
      name: "dir_shopify_sales",
      drillDown: [contactType === "Product" ? "shopify_v0alpha1_product" : "shopify_customer"],
      timeBucket: "month",
      and: [
        {
          op: "=",
          left: { column: contactType === "Product" ? "product_id" : "customer_id" },
          right: { value: contactId },
        },
        {
          op: "=",
          left: { column: "tenantId" },
          right: { value: tenantId },
        },
      ],
    })
    .then((res) => {
      const totalAggColumns = res?.rows[0]?.columns;
      return {
        total: Number(totalAggColumns?.sum_total_amount || 0),
        percentage: 0,
        average: Number(totalAggColumns?.avg_total_amount || 0),
        number_of_transactions: Number(totalAggColumns?.sales_count || 0),
      };
    });
};
