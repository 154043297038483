import { Store } from "../types";
import * as API from "../../axios/reports";
import { StateCreator } from "zustand";
import { NewReport } from "../../types";

export const reportsSlice: StateCreator<Store, [], [], Store["reports"]> = (set, get) => ({
  reports: [],
  listReports: () =>
    API.listReports().then((data) => {
      set((state) => ({
        reports: { ...state.reports, reports: data },
      }));
      return data;
    }),
  createReport: (payload: NewReport) =>
    API.createReport(payload).then(() => {
      set((state) => {
        const exists = state.reports.reports.find((rep) => rep.id === payload.id);
        if (exists) {
          return {
            reports: {
              ...state.reports,
              reports: state.reports.reports.map((rep) => {
                if (rep.id === payload.id) {
                  return { ...rep, ...payload };
                }
                return rep;
              }),
            },
          };
        }
        return {
          reports: { ...state.reports, reports: [...state.reports.reports, payload] },
        };
      });
    }),
  computeReport: (payload) =>
    API.computeReport(payload).then((data) => {
      set((state) => {
        return {
          reports: { ...state.reports, selectedReport: data },
        };
      });
      return data;
    }),
  resetSelectedReport: () =>
    set((state) => {
      return {
        reports: { ...state.reports, selectedReport: undefined },
      };
    }),
});
