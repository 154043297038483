import { FC, useState, useEffect, CSSProperties } from "react";
import { IconName } from "./types";
import { ReactSVG } from "react-svg";
import styles from "./styles.module.css";

export interface IProps {
  name: IconName;
  size?: string;
  color?: string;
  square?: boolean;
  className?: string;
  onClick?: (e: unknown) => void;
  style?: CSSProperties;
}
export const Icon: FC<IProps> = ({
  name,
  className,
  style,
  onClick,
  color = "var(--grey-scale-95)",
  size = "2.4em",
  square = true,
}) => {
  const [icon, setIcon] = useState<string>("");
  useEffect(() => {
    name &&
      import(`../../assets/icons/${name}.svg`)
        .then((Icon) => {
          setIcon(Icon.default);
        })
        .catch((error) => console.error("Error while loading the icon: ", error));
  }, [name]);
  const variables: any = {
    "--icon-color": color,
    "--icon-size": size,
  };

  return (
    name && (
      <div style={variables} className={`${styles.wrapper} ${className}`}>
        <ReactSVG
          onClick={onClick}
          src={icon}
          color={color}
          style={style}
          className={square ? styles.iconSqr : styles.icon}
        />
      </div>
    )
  );
};
