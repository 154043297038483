import React, { FC, Fragment, useRef, useState } from "react";
import { filterOption } from "../../utils";
import { Select, SelectProps } from "antd";
import { Icon } from "../Icon";
import { Typography } from "../Typography";
import { IconName } from "../Icon/types";
import styles from "./styles.module.css";
import { Option } from "../../types";

interface IProps extends SelectProps {
  handleSearch?: (e: string) => void;
  data: Option[];
  allowClear?: boolean;
  error?: string;
  label?: string;
  handleChange: (q: Option | Option[]) => void;
  borderType?: "bottom" | "full" | "none";
  showIndicator?: boolean;
  isPreview?: boolean;
  multiModeNoBox?: boolean;
  width?: string;
  active?: boolean;
  value: string | Option | string[] | null;
}

export const CustomSelect: FC<IProps> = ({
  handleSearch,
  borderType,
  handleChange,
  data,
  allowClear,
  style,
  showIndicator = true,
  onDropdownVisibleChange,
  disabled,
  width,
  multiModeNoBox,
  className,
  title,
  active,
  isPreview,
  mode,
  value,
  error,
  label,
  ...rest
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const valueObj = typeof value === "string" ? data.find((item) => item.value === value) : value;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div style={{ width: multiModeNoBox ? undefined : "100%", position: "relative" }} ref={containerRef}>
      {label && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "2em",
          }}
        >
          <label className={`${styles.label} ${error ? styles.error : ""}`}>{label}</label>
        </div>
      )}
      <Select
        onSearch={handleSearch}
        filterOption={filterOption as any}
        onClick={(e) => e.preventDefault()}
        options={data}
        dropdownRender={(menu) => (
          <div className={styles.dropdownWrapper}>
            {title && (
              <div className={styles.title}>
                <Typography style={{ fontFamily: "SecondaryFont" }} color={"var(--gold)"} variant={"body3"}>
                  {title}
                </Typography>
              </div>
            )}
            {menu}
          </div>
        )}
        showSearch
        style={{
          width: "100%",
          height: "4.8em",
          fontSize: isPreview ? "var(--preview-font-size)" : "var(--base-font-size)",
          ...style,
        }}
        popupClassName={
          mode === "multiple" ? (multiModeNoBox ? styles.multiSelectNoBox : styles.multiSelect) : styles.customMenu
        }
        dropdownStyle={{
          fontSize: isPreview ? "var(--preview-font-size)" : "var(--base-font-size)",
          padding: 0,
          zIndex: 10000000,
          width: width || containerRef.current?.clientWidth,
        }}
        onDropdownVisibleChange={(e) => {
          setDropdownVisible(e);
          onDropdownVisibleChange && onDropdownVisibleChange(e);
        }}
        className={`${className || ""} ${
          borderType === "bottom" ? styles.borderBottom : borderType === "full" ? styles.bordered : ""
        } ${error && styles.borderError} ${active && styles.borderActive} ${styles.customSelect}`}
        disabled={disabled}
        suffixIcon={
          showIndicator ? (
            <Icon
              style={multiModeNoBox ? {} : { marginRight: "0.5em" }}
              name={dropdownVisible || !multiModeNoBox ? IconName.CHEVRON_DOWN : IconName.PLUS}
              size={"1.6em"}
              color={multiModeNoBox ? "var(--white-scale-100)" : "var(--grey-scale-95)"}
            />
          ) : (
            <></>
          )
        }
        allowClear={allowClear && { clearIcon: <Icon name={IconName.X} size={"2em"} color="var(--grey-scale-95)" /> }}
        onChange={(_, option: any) => {
          if (option.value === "rename_item") {
            const regexPattern = /"(.*?)"/;
            const stringLabel = option?.label as string;
            const searchArray = stringLabel?.match(regexPattern);
            handleChange({ value: option.value, label: stringLabel, search: searchArray?.[1] });
          } else {
            handleChange(option);
          }
        }}
        optionRender={(option) => {
          return (
            <div
              className={`${styles.option} ${option?.data?.disabled && styles.disabled}`}
              style={{ borderTop: option?.data?.borderTop ? "1px solid var(--grey-scale-20)" : "" }}
            >
              {option?.data?.icon && <Icon size={"1.8em"} name={option?.data?.icon} />}
              {option?.data?.image && <p style={{ fontSize: "1.4em" }}>{option?.data?.image}</p>}
              <Typography oneLine variant="body2">
                {option.label}
              </Typography>
            </div>
          );
        }}
        mode={mode}
        value={valueObj}
        tagRender={multiModeNoBox ? () => <Fragment /> : undefined}
        {...rest}
      />
      {error && (
        <div
          className={`${styles.messages}`}
          style={
            error
              ? { display: "flex", alignItems: "center", color: "var(--additional-red)" }
              : {
                  display: "none",
                }
          }
        >
          <Icon name={IconName.CIRCLE_X} color="var(--additional-red)" />
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};
