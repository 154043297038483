import { FC, useEffect, useRef, useState } from "react";
import { Icon } from "../Icon";
import { InputProps as Props } from "./types";
import { IconName } from "../Icon/types";
import styles from "./styles.module.css";
import { useInputStyles } from "./useInputStyles";

export const Inputfield: FC<Props> = ({
  type = "text",
  label,
  children,
  inputWrapperStyle,
  notEditable,
  wrapperClassname,
  disabled,
  error,
  showMessage = true,
  autoComplete,
  paneWidth = "set",
  rowV,
  mt = true,
  borderBottom,
  borderLess,
  style,
  borderColor,
  ...rest
}) => {
  const [visible, setVisible] = useState({ password: false, tooltip: false });
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const ref = useRef<any>(null);

  const inputStyles = useInputStyles({ borderBottom, borderLess, error });

  useEffect(() => {
    if (rowV) {
      const node = ref?.current as unknown as HTMLInputElement;
      node && node.focus();
    }
  }, [rest.value, rowV]);

  function renderField() {
    switch (type) {
      case "text":
      case "number":
        return (
          <>
            <div style={{ height: "4.8em", position: "relative", margin: mt ? "0.8em 0 0" : "", ...inputWrapperStyle }}>
              <input
                ref={ref}
                type={type}
                autoComplete={autoComplete}
                style={{
                  ...inputStyles,
                  ...style,
                }}
                className={`${styles.textField} ${disabled && styles.disabled}`}
                disabled={notEditable || disabled}
                {...rest}
              >
                {children}
              </input>
            </div>
            {showMessage && (
              <div
                className={`${styles.messages}`}
                style={
                  error
                    ? { display: "flex", alignItems: "center", color: "var(--additional-red)" }
                    : {
                        display: "none",
                      }
                }
              >
                <Icon name={IconName.CIRCLE_X} color="var(--additional-red)" />
                <p>{error}</p>
              </div>
            )}
          </>
        );
      case "search":
        return (
          <>
            <div
              style={{
                height: "4.8em",
                borderRadius: "1.2em",
                paddingLeft: "4em",
                margin: mt ? "0.8em 0 0" : "",
                border: focused
                  ? "solid 1px var(--grey-scale-95)"
                  : `solid 1px ${borderColor || "var(--grey-scale-15)"}`,
                ...inputWrapperStyle,
              }}
              className={styles.searchWrapper}
            >
              <Icon
                size={"2em"}
                color={focused ? "var(--grey-scale-95)" : "var(--grey-scale-50)"}
                name={IconName.SEARCH}
                className={styles.searchIcon}
              />
              <input
                ref={ref}
                onFocus={onFocus}
                onBlur={onBlur}
                autoComplete={autoComplete}
                type={"text"}
                placeholder="Search..."
                className={styles.serachField}
                {...rest}
              >
                {children}
              </input>
            </div>
          </>
        );

      case "password":
        return (
          <>
            <div
              className={styles.passwordInput}
              style={{ height: "4.8em", margin: mt ? "0.8em 0 0" : "", ...inputWrapperStyle }}
            >
              <input
                autoComplete={autoComplete}
                type={!visible.password ? type : "text"}
                style={{
                  ...inputStyles,
                  ...style,
                }}
                className={styles.textField}
                {...rest}
              >
                {children}
              </input>
              <Icon
                name={!visible.password ? IconName.EYE_OFF : IconName.EYE}
                onClick={() => setVisible({ ...visible, password: !visible.password })}
                className={styles.eyeIcon}
              />
            </div>
          </>
        );
    }
    return;
  }

  return (
    <div className={`${styles.inputBox} ${wrapperClassname}`}>
      {label && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "2em",
          }}
        >
          <label className={`${styles.label} ${error ? styles.error : ""}`}>{label}</label>
        </div>
      )}
      {renderField()}
    </div>
  );
};
