import * as API from "../../axios/directory";
import { Contact, IPagination } from "../../types";
import { ContactTotals, GetContactTotalsPayload, GetDirectoryPayload } from "./types";
import { useState } from "react";

export const useDirectory = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    nextPage: 1,
    total: 0,
  });
  const [contactTotals, setContactTotals] = useState<ContactTotals>({
    number_of_transactions: 0,
    total: 0,
    average: 0,
    percentage: 0,
  });

  const getDirectory = async (payload: GetDirectoryPayload) =>
    await API.getDirectory(payload).then(({ data, pagination }) => {
      setContacts(payload.loadMore ? [...contacts, ...data] : data);
      setPagination(pagination);
      return { data, pagination };
    });

  const resetDirectory = () => {
    setContacts([]);
    setPagination({
      nextPage: 1,
      total: 0,
    });
  };

  const resetDirectoryTotals = () => {
    setContactTotals({
      number_of_transactions: 0,
      total: 0,
      average: 0,
      percentage: 0,
    });
  };

  const getContactTotals = async (payload: GetContactTotalsPayload) =>
    await API.getContactTotals(payload).then((data) => {
      setContactTotals(data);
    });

  return {
    contacts,
    pagination,
    contactTotals,
    getDirectory,
    resetDirectory,
    getContactTotals,
    resetDirectoryTotals,
  };
};
