import { useQuery } from "@tanstack/react-query";
import { useStore } from "../store";

export const useSoloSchemas = () => {
  const getter = useStore((store) => store.aggregates.getSchemas);

  const { data, ...query } = useQuery({
    queryKey: ["schemas"],
    queryFn: async () => {
      const originalData = await getter();
      const data = originalData.filter((schema) => !!schema.struct.meta?.["parent"]?.bool);
      return {
        originalData,
        data,
      };
    },
  });

  return { ...query, ...data };
};
