import { useQuery } from "@tanstack/react-query";
import { useSoloStore } from "../contexts/soloConfig";

export const useSoloFileData = (id: string) => {
  const getter = useSoloStore((store) => store.files.getFileData);

  return useQuery({
    queryKey: ["file", id, "data"],
    queryFn: async () => getter(id),
  });
};
