import React, { ComponentType, FC } from "react";
import { useSoloStore } from "../contexts/soloConfig";
import styles from "./Directory/style.module.css";
import { BarLoader } from "react-spinners";
import { Onboarding } from "./Onboarding";

export const withOnboardingRequired = <P extends object>(
  Component: ComponentType<P>,
  options: { isPreview?: boolean; bypassOnboarding?: boolean } = {}
): FC<P> => {
  return function withOnboardingRequired(props: P): JSX.Element {
    const selectedTenant =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useSoloStore((state) => state.tenant.selectedTenant);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const initialized = useSoloStore((state) => state.sdk.initialized);

    if (!initialized)
      return (
        <div className={styles.pageWrapper}>
          <BarLoader width={100} height={3} color="var(--grey-scale-95)" />
        </div>
      );

    if ((options.bypassOnboarding === undefined || !options.bypassOnboarding) && !selectedTenant?.onboarded)
      return <Onboarding />;

    return (
      <div
        style={{
          fontSize: options.isPreview ? "var(--preview-font-size)" : "var(--base-font-size)",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            paddingLeft: "var(--screen-padding-horizontal)",
            paddingRight: "var(--screen-padding-horizontal)",
            paddingTop: "var(--screen-padding-vertical)",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            background: "var(--white-scale-100)",
            position: options.isPreview ? "relative" : "unset",
          }}
        >
          <Component {...props} />
        </div>
      </div>
    );
  };
};
