import { Button, CustomTabs, IconName, Typography } from "shared/components";
import React, { FC } from "react";
import styles from "./styles.module.css";
import { withOnboardingRequired } from "../guard";
import { useNavigate, useParams } from "react-router-dom";
import { useSoloFileData } from "../../hooks/useSoloFileData";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { Tooltip } from "antd";
import { useSoloSchemaNested } from "../../hooks/useSoloSchemaNested";
import { useSoloWorkflows, useSoloFileUpdate } from "shared/hooks";
import { FileDataTable } from "./FileDataTable";
import { pivotData, unpivotData } from "./helpers";
import { useSoloFileMap } from "../../hooks/useSoloFileMap";
import { useQueryClient } from "@tanstack/react-query";
import { WorkflowStatus } from "shared";
import { ClipLoader } from "react-spinners";
import { capitalize } from "lodash-es";
import { useSoloFilePreview } from "shared/hooks/useSoloFilePreview";

const FilesPage: FC = () => {
  const navigate = useNavigate();
  const qc = useQueryClient();

  const { tenantId, fileId } = useParams<{ tenantId: string; fileId: string }>();
  const [collapsed, setCollapsed] = React.useState(false);

  const { data: previewData } = useSoloFilePreview(fileId!);
  const { data: fileData, refetch } = useSoloFileData(fileId!);
  const { data: schemaNested } = useSoloSchemaNested(previewData?.file?.fileType || undefined);
  const {
    mutate: patchFile,
    isSuccess: isPatchFileSuccess,
    isPending: isPatchFilePending,
    reset,
  } = useSoloFileUpdate({
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ["workflows"],
      });
      qc.invalidateQueries({
        queryKey: ["files", fileId],
      });
    },
  });

  const { data: workflowsExtracting } = useSoloWorkflows(
    `CustomKeywordField STARTS_WITH "file-processing-extract-${tenantId}-${fileId}"`
  );

  const { data: workflowsMapping } = useSoloWorkflows(
    `CustomKeywordField STARTS_WITH "file-transform-${tenantId}-${fileId}"`
  );

  const { mutate: mapFile, isPending: isMapping } = useSoloFileMap({
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ["workflows"],
      });
      qc.invalidateQueries({
        queryKey: ["files", fileId],
      });
    },
  });

  const workflowExt = workflowsExtracting?.find((w) => w.memo["args"]?.["fileId"] === fileId);
  const workflowMap = workflowsMapping?.find((w) => w.memo["args"]?.["fileId"] === fileId);

  const schema = schemaNested?.parent;
  const childSchemas = schemaNested?.childs || [];
  const allSchemas = schema ? [schema, ...childSchemas] : childSchemas;

  const fileDataRaw = fileData?.mapping || fileData?.data;

  const [data, setData] = React.useState<Record<string, ReturnType<typeof pivotData>>>({});

  const pivotedData = React.useMemo(() => {
    return allSchemas.reduce(
      (acc, schema) => ({
        ...acc,
        [schema.name]: pivotData(
          allSchemas,
          fileDataRaw?.find((d) => d.name === schema.name)
        ),
      }),
      {}
    );
  }, [allSchemas, fileData]);

  React.useEffect(() => {
    setData(pivotedData);
  }, [fileData]);

  React.useEffect(() => {
    refetch();
  }, [workflowExt?.status, refetch]);

  React.useEffect(() => {
    if (isPatchFileSuccess) {
      reset();
    }
  }, [isPatchFileSuccess, refetch]);

  if (!fileId || !previewData || !fileData || !schemaNested || !workflowsExtracting) {
    return <div>Loading...</div>;
  }

  const mappedData = Object.entries(data).map(([name, pivot]) => unpivotData(name, pivot));
  const mappingPending =
    workflowMap?.status === WorkflowStatus.RUNNING || isMapping || workflowExt?.status === WorkflowStatus.RUNNING;

  return (
    <>
      <div className={styles.title}>
        <div className={styles.titleHeading}>
          <Button
            variant="alternative"
            icon={IconName.ARROW_LEFT}
            onClick={() => navigate(`/tenant/${tenantId}/data-sources`)}
          />
          <Typography style={{ fontWeight: 400 }} variant="h1" color="var(--grey-scale-95)">
            {previewData?.file.name}
          </Typography>
        </div>
        <div className={styles.titleButtons}>
          <Button variant="alternative" icon={IconName.PLUS} disabled>
            Add attribute
          </Button>
        </div>
      </div>
      <div className={collapsed ? styles.contentFlex : styles.contentTwoColumns}>
        {!collapsed && (
          <div className={styles.childContentPreview}>
            <DocViewer
              documents={[
                {
                  uri: previewData.url,
                },
              ]}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                },
                pdfZoom: {
                  defaultZoom: 1.1, // 1 as default,
                  zoomJump: 0.2, // 0.1 as default,
                },
                pdfVerticalScrollByDefault: true,
              }}
              style={{
                backgroundColor: "var(--grey-scale-10)",
                overflow: "scroll",
              }}
              pluginRenderers={DocViewerRenderers}
              prefetchMethod="GET"
            />
          </div>
        )}
        <div className={styles.childContentData}>
          <div className={styles.flexRow}>
            <div className={styles.flexRow}>
              <Tooltip title={collapsed ? "Expand preview" : "Collapse preview"}>
                <Button
                  variant="alternative"
                  style={{ width: "32px" }}
                  icon={collapsed ? IconName.CHEVRON_RIGHT : IconName.CHEVRON_LEFT}
                  onClick={() => setCollapsed((prev) => !prev)}
                />
              </Tooltip>
              <Typography variant="h2" color="var(--grey-scale-95)">
                {schema?.struct.meta?.["inputName"]?.string || schema?.name}
              </Typography>
            </div>
            {fileId && (
              <Button
                variant="primary"
                style={{ width: "180px" }}
                onClick={() =>
                  mapFile({
                    id: fileId,
                    data: {
                      mapping: mappedData,
                    },
                  })
                }
                disabled={mappingPending}
                loading={mappingPending}
              >
                {mappingPending ? "Mapping data..." : "Save mapping"}
              </Button>
            )}
          </div>
          <div className={styles.flex}>
            <div className={styles.header}>
              <Typography variant="h5" color="var(--grey-scale-95)">
                Data Mapping
              </Typography>
              {workflowExt && (
                <div className={styles.flexRow}>
                  <div className={styles.flexRow} style={{ justifyContent: "flex-start" }}>
                    <Typography variant="body2" color="var(--grey-scale-50)">
                      Automatic mapping <b>{capitalize(workflowExt.status)}</b>
                    </Typography>
                    {workflowExt.status === WorkflowStatus.RUNNING && (
                      <ClipLoader size={16} color={"var(--grey-scale-50)"} />
                    )}
                  </div>
                  {!mappingPending && (
                    <Button
                      variant="alternative"
                      onClick={() => {
                        patchFile({
                          id: fileId,
                          data: {
                            fileType: previewData.file.fileType,
                          },
                        });
                      }}
                      loading={isPatchFilePending}
                      disabled={isPatchFilePending}
                    >
                      Refresh mapping
                    </Button>
                  )}
                </div>
              )}
            </div>
            <CustomTabs
              tabs={[
                {
                  tabName: schema?.struct.meta["inputName"]?.string || schema?.name || "",
                  component: schema && (
                    <FileDataTable
                      data={data[schema.name]}
                      schema={schema}
                      onDataChange={(newData) => setData((prev) => ({ ...prev, [schema.name]: newData }))}
                    />
                  ),
                },
                ...childSchemas.map((child) => ({
                  tabName: child.struct.meta["inputName"]?.string || child.name,
                  component: (
                    <FileDataTable
                      key={child.name}
                      schema={child}
                      data={data[child.name]}
                      onDataChange={(newData) => setData((prev) => ({ ...prev, [child.name]: newData }))}
                    />
                  ),
                })),
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

// TODO: seems like the wrapper not only guards but also set styles...
// so I "bypassed" the guard with a param for now
export const Files = withOnboardingRequired(FilesPage, { bypassOnboarding: true });
