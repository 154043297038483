import React, { memo, useState } from "react";
import { Icon, IconName, Typography } from "../..";
import { FileType } from "../../../types";
import styles from "./styles.module.css";
import { readFileAsBase64 } from "../../../utils";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Upload } from "antd";
import { ClipLoader } from "react-spinners";

const { Dragger } = Upload;

export const FilePreview = memo(function FilePreview({
  onFileChange,
  loading,
}: {
  loading?: boolean;
  onFileChange: (file: FileType) => void;
}) {
  const [file, setFile] = useState("");

  const beforeUpload = async (newFile: FileType) => {
    const fileStr = await readFileAsBase64(newFile);
    setFile(fileStr);
    onFileChange(newFile);
    return false;
  };

  return (
    <div className={styles.dragWrapper}>
      {file ? (
        <div className={styles.viewer}>
          {loading && (
            <div className={styles.loader}>
              <Typography variant={"body2"}>Predicting File Type .....</Typography>
              <ClipLoader size={20} />
            </div>
          )}
          <DocViewer
            documents={[
              {
                uri: file,
              },
            ]}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
              },
              pdfZoom: {
                defaultZoom: 1.1, // 1 as default,
                zoomJump: 0.2, // 0.1 as default,
              },
              pdfVerticalScrollByDefault: true,
            }}
            style={{
              backgroundColor: "var(--grey-scale-10)",
              overflow: "scroll",
            }}
            pluginRenderers={DocViewerRenderers}
          />
        </div>
      ) : (
        <Dragger
          beforeUpload={beforeUpload}
          name={"file"}
          showUploadList={false}
          accept={
            ".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
          }
          disabled={loading}
          style={{
            background: "var(--grey-scale-05)",
            borderColor: "var(--grey-scale-30)",
          }}
          className={styles.dragger}
        >
          <Icon name={IconName.UPLOAD} size={"4em"} color={"var(--grey-scale-50)"} />
          <div style={{ margin: "2.4em 0" }}>
            <Typography variant={"body2"}>
              Drag and Drop Example File or <span style={{ color: "var(--gold)" }}>Choose File</span>
            </Typography>
          </div>
          <Typography variant={"body3"} color={"var(--grey-scale-50)"} style={{ fontFamily: "SecondaryFont" }}>
            OPTIONAL
          </Typography>
        </Dragger>
      )}
    </div>
  );
});
