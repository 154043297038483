import { useSoloStore } from "../contexts/soloConfig";
import { useCallback, useEffect, useMemo, useState } from "react";
import { differenceInMonths, format, getMonth, setMonth, startOfMonth } from "date-fns";
import { useProgressState } from "shared/hooks";
import { NewReport, ReportRow } from "shared/types";

const DRILL_PLACEHOLDER = "Group By";
const TIME_BUCKET = "month";
export const useComputeReport = (
  selectedDateOption: {
    granularity: number;
    refDate: string;
    label: string;
    startDate: Date;
    endDate: Date;
  },
  selectedReport: NewReport
) => {
  const [rowDrillIndexer, setRowDrillIndexer] = useState<{ [x: number]: number }>({});

  const { setLoading, setSuccess, setFailure, progress } = useProgressState();
  const reportResults = useSoloStore((state) => state.reports.selectedReport);
  const tenantId = useSoloStore((state) => state.tenant.selectedTenant?.id);
  const computeReport = useSoloStore((state) => state.reports.computeReport);
  const resetSelectedReport = useSoloStore((state) => state.reports.resetSelectedReport);
  const stringStartDate = format(selectedDateOption.startDate, "yyyy-MM-dd");
  const stringEndDate = format(selectedDateOption.endDate, "yyyy-MM-dd");

  const computedReportRows = useMemo(() => {
    return fillReportRows(
      reportResults?.rows.reduce((acc, row) => {
        const drillOptions = row.possibleDrillDowns
          ? [DRILL_PLACEHOLDER, ...row.possibleDrillDowns.sort((a, b) => a.localeCompare(b))]
          : [];
        const defaultDrill = row.drillDowns || [DRILL_PLACEHOLDER];
        const tablesPackage = row.package?.tables[defaultDrill[0]];
        const drillRows: ReportRow[] = row.drill
          ? row.drill.map((drill) => {
              const drillValue = JSON.parse(
                tablesPackage?.models[drill.drillModels[defaultDrill[0]].column.node_id]?.column?.document || "{}"
              )?.document;

              const titleFromDrill = drillValue?.first_name
                ? `${drillValue.first_name} ${drillValue.last_name}`
                : drillValue?.title
                ? drillValue.title
                : defaultDrill[0];
              const imageFromDrill = drillValue?.featuredImage?.url ? drillValue.featuredImage?.url : undefined;
              return {
                name: defaultDrill[0],
                title: defaultDrill[0] !== DRILL_PLACEHOLDER ? titleFromDrill : defaultDrill[0],
                cells: drill.cells,
                image: imageFromDrill,
                parentLevel: 2,
                drill: [],
                possibleDrillDowns: [],
              };
            })
          : [];
        const drillRowTotals = getDrillRowsTotals(drillRows);
        return [
          ...acc,
          { ...row, cells: row.cells || drillRowTotals, drillDowns: defaultDrill, possibleDrillDowns: drillOptions },
          ...drillRows,
        ];
      }, [] as ReportRow[]) || [],
      stringStartDate,
      stringEndDate
    );
  }, [reportResults?.rows, stringStartDate, stringEndDate]);

  const filteredDrillIndexer = useMemo(
    () => Object.entries(rowDrillIndexer).filter(([_, value]) => value > 0),
    [rowDrillIndexer]
  );
  const rowParams = useMemo(() => {
    return filteredDrillIndexer.length > 0
      ? filteredDrillIndexer.map(([key, value]) => {
          const row = computedReportRows[Number(key)];
          return { name: row.name, drillDown: [(row.possibleDrillDowns || [])[value]] };
        })
      : undefined;
  }, [filteredDrillIndexer]);

  const monthCount = differenceInMonths(selectedDateOption.endDate, selectedDateOption.startDate) + 1;
  const rowCount = (computedReportRows.length || 0) + 1;

  const handleComputeReport = useCallback(() => {
    computeReport({
      name: selectedReport.name,
      rowParams: [{ name: "*" }, ...(rowParams || [])].map((rowParam) => ({
        ...rowParam,
        and: [{ op: "=", left: { column: "tenantId" }, right: { value: tenantId } }],
      })),
      start: stringStartDate,
      end: stringEndDate,
      timeBucket: TIME_BUCKET,
    })
      .then(setSuccess)
      .catch(setFailure);
  }, [selectedReport.name, JSON.stringify(rowParams), stringStartDate, stringEndDate]);

  useEffect(() => {
    setLoading();
    handleComputeReport();
  }, [selectedReport.name, JSON.stringify(rowParams), stringStartDate, stringEndDate]);

  useEffect(() => {
    return () => {
      setRowDrillIndexer({});
      resetSelectedReport();
    };
  }, []);

  useEffect(() => {
    if (computedReportRows.length > 0) {
      setRowDrillIndexer(
        computedReportRows.reduce((acc, row, index) => {
          return {
            ...acc,
            [index]:
              (row.possibleDrillDowns || []).length > 0
                ? (row.possibleDrillDowns || []).findIndex((val) => val === (row.drillDowns || [])[0])
                : -1,
          };
        }, {})
      );
    }
  }, [computedReportRows]);

  const onDrillChanged = useCallback((rowIndex: number, drillIndex: number) => {
    setRowDrillIndexer((prevState) => ({ ...prevState, [rowIndex]: drillIndex }));
  }, []);

  return { onDrillChanged, monthCount, progress, computedReportRows, rowCount, rowDrillIndexer };
};

const fillReportRows = (reportRows: ReportRow[], stringStartDate: string, stringEndDate: string) => {
  return reportRows.map((row) => {
    const monthsCount = differenceInMonths(new Date(stringEndDate), new Date(stringStartDate));
    const cellsByMonth = row.cells.map((cell) => getMonth(new Date(cell.timeValue)));
    const missing: number[] = [];
    for (let i = 0; i <= monthsCount; i++) {
      if (cellsByMonth.indexOf(i) === -1) {
        missing.push(i);
      }
    }
    const monthCells = [
      ...row.cells,
      ...missing.map((month) => ({
        value: "0",
        timeValue: startOfMonth(setMonth(new Date(stringStartDate), month + 1)).toISOString(),
      })),
    ].sort((a, b) => new Date(a.timeValue).getTime() - new Date(b.timeValue).getTime());
    const totalCell = row.cells.reduce((acc, cell) => acc + Number(cell.value), 0);

    return {
      ...row,
      cells: [...monthCells, { value: totalCell.toString(), timeValue: "total" }],
    };
  });
};

const getDrillRowsTotals = (drillRows: ReportRow[]) => {
  let drillRowTotalsMap: { [x: string]: number } = {};
  drillRows.forEach((drillRow) => {
    drillRow.cells.forEach((drillRow2) => {
      drillRowTotalsMap = {
        ...drillRowTotalsMap,
        [drillRow2.timeValue]: (drillRowTotalsMap[drillRow2.timeValue] || 0) + Number(drillRow2.value),
      };
    });
  });
  return Object.entries(drillRowTotalsMap).map(([key, value]) => ({ timeValue: key, value }));
};
