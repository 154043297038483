import React, { Dispatch, FC, PropsWithChildren, SetStateAction, useState } from "react";
import { Button, Icon, IconName, Typography, Checkbox } from "shared/components";
import styles from "./styles.module.css";
import { Column } from "shared/types";

interface IProps {
  onClose: () => void;
  columnOptions: Column[];
  selectedColumns: Column[];
  setSelectedColumns: Dispatch<SetStateAction<Column[]>>;
}
export const Controllers: FC<IProps> = ({ onClose, setSelectedColumns, columnOptions, selectedColumns }) => {
  const [expandedTitle, toggleExpandedTitle] = useState<string>("Columns");

  return (
    <div className={styles.controllersWrapper}>
      <div className={styles.controllers}>
        <div className={styles.listHeader}>
          <div className={styles.expand}>
            <Button
              variant={"alternative"}
              onClick={onClose}
              iconSize={"1.6em"}
              className={styles.expandBtn}
              icon={IconName.CHEVRON_LEFT}
            />
          </div>
        </div>
        <Expandable title={"Columns"} expandedTitle={expandedTitle} toggleExpandedTitle={toggleExpandedTitle}>
          <>
            {columnOptions.map((col) => (
              <ColumnOption
                key={col.Name}
                column={col}
                setSelectedColumns={setSelectedColumns}
                selectedColumns={selectedColumns}
              />
            ))}
          </>
        </Expandable>
      </div>
    </div>
  );
};

const ColumnOption: FC<{
  column: Column;
  selectedColumns: Column[];
  setSelectedColumns: Dispatch<SetStateAction<Column[]>>;
}> = ({ column, selectedColumns, setSelectedColumns }) => {
  const selected = !!selectedColumns.find((col) => col.Name === column.Name);
  const handleSelectOption = () => {
    setSelectedColumns((prevState) => {
      if (selected) {
        return prevState.filter((col) => col.Name !== column.Name);
      }
      return [...prevState, column].sort((a, b) => a.Name.localeCompare(b.Name));
    });
  };

  return (
    <div
      className={`custom-checkbox custom-checkbox-${selected && "active"} ${styles.checkbox} ${
        selected && styles.active
      }`}
    >
      <Checkbox
        onClick={handleSelectOption}
        checked={selected}
        tickColor={"var(--grey-scale-95)"}
        borderColor={"var(--grey-scale-95)"}
        hideTrailing
      />
      <Typography variant="body2">{column.Name}</Typography>
    </div>
  );
};
const Expandable: FC<
  PropsWithChildren<{ title: string; expandedTitle: string; toggleExpandedTitle: Dispatch<SetStateAction<string>> }>
> = ({ children, title, expandedTitle, toggleExpandedTitle }) => {
  const expanded = expandedTitle === title;
  const handleToggle = () => {
    toggleExpandedTitle((prevState) => {
      if (prevState === title) {
        return "";
      }
      return title;
    });
  };

  return (
    <>
      <div className={styles.row} style={expanded ? { flex: 1 } : {}}>
        <div onClick={handleToggle} className={styles.inner}>
          <Typography variant={"body3"} bold style={{ fontFamily: "SecondaryFont" }} color={"var(--gold)"}>
            {title.toUpperCase()}
          </Typography>
          <Icon
            size={"2.4em"}
            style={{ transform: expanded ? "rotate(-180deg)" : "rotate(0deg)", transition: "all 0.2s" }}
            name={IconName.CHEVRON_DOWN}
          />
        </div>
        {expanded && <div className={styles.rowChild}>{children}</div>}
      </div>
    </>
  );
};
