import { Store } from "../types";
import * as SettingsAPI from "../../axios/settings";
import { StateCreator } from "zustand";

export const settingsSlice: StateCreator<Store, [], [], Store["settings"]> = (set, get) => ({
  user: { id: "" },
  members: [],
  tokens: [],
  listTokens: async () =>
    SettingsAPI.listTokens().then((res) => {
      set((state) => ({ settings: { ...state.settings, tokens: res } }));
      return res;
    }),
  getUserProfile: () =>
    SettingsAPI.getUserProfile().then(async (user) => {
      const roles = await SettingsAPI.getUserRoles();
      let userWithRoles = { ...get().settings.user, ...user.user, ...user };
      const roleName = `tenant_${user.userId?.replace("|", "_")}`;
      const role = roles.find((role) => role.name === roleName);
      if (role) {
        userWithRoles = {
          ...userWithRoles,
          appMetadata: {
            ...(userWithRoles.appMetadata || { roles: [] }),
            authz: { id: role.id, name: roleName, tenantIDs: role.authz.tenantIDs },
          },
        };
      }
      set((state) => ({ settings: { ...state.settings, user: userWithRoles } }));
      return user;
    }),
  updateUserProfile: (payload) =>
    SettingsAPI.updateUserProfile(payload).then(() => {
      set((state) => ({ settings: { ...state.settings, user: { ...state.settings.user, ...payload } } }));
    }),
  getTeamMembers: () =>
    SettingsAPI.getTeamMembers().then(async (members) => {
      const roles = await SettingsAPI.getUserRoles();
      const membersWithRoles = members.map((mem) => {
        const roleName = `tenant_${mem.id.replace("|", "_")}`;
        const role = roles.find((role) => role.name === roleName);
        if (role) {
          return {
            ...mem,
            appMetadata: {
              ...(mem.appMetadata || { roles: [] }),
              authz: { id: role.id, name: roleName, tenantIDs: role.authz.tenantIDs },
            },
          };
        }
        return mem;
      });
      set((state) => ({ settings: { ...state.settings, members: membersWithRoles } }));
      return members;
    }),
  updateUserTenants: async (payload) => {
    const member = get().settings.members.find((mem) => mem.id === payload.id);
    if (!member?.appMetadata || !payload.appMetadata?.authz) return;
    const newRole = {
      name: payload.appMetadata.authz.name,
      id: payload.appMetadata.authz.id,
      authz: { tenantIDs: payload.appMetadata.authz.tenantIDs },
    };
    if (member.appMetadata.roles[0] !== "admin" && payload.appMetadata.authz) {
      await SettingsAPI.updateUserTenants(newRole);
    }
    set((state) => ({
      settings: {
        ...state.settings,
        members: state.settings.members.map((member) => {
          if (member.id === payload.id) {
            return {
              ...member,
              appMetadata: {
                roles: Array.from(
                  new Set([...(member.appMetadata?.roles || []), payload.appMetadata?.authz?.name || ""])
                ),
                authz: { id: newRole.id, name: newRole.name, tenantIDs: newRole.authz.tenantIDs },
              },
            };
          }
          return member;
        }),
      },
    }));
  },
  updateTeamMember: (payload) => {
    SettingsAPI.updateTeamMember(payload).then(async () => {
      set((state) => ({
        settings: {
          ...state.settings,
          members: state.settings.members.map((member) => {
            if (member.id === payload.id) {
              return {
                ...member,
                ...payload,
              };
            }
            return member;
          }),
        },
      }));
    });
    return Promise.resolve();
  },
  removeTeamMember: (userId) =>
    SettingsAPI.removeTeamMember(userId).then(async () => {
      set((state) => ({
        settings: {
          ...state.settings,
          members: state.settings.members.filter((member) => member.id !== userId),
        },
      }));
    }),
});
