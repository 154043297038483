import * as chrono from "chrono-node";

export function parseDateMagic(dateStr: string): Date {
  const parsed = chrono.parseDate(dateStr);

  if (!parsed) {
    throw new Error(`Could not parse date: ${dateStr}`);
  }

  return parsed;
}
