import React, { FC, useCallback, useRef, useState } from "react";
import { Button, GridOnScroll, GridType, IconName, StickyGrid, Typography } from "shared/components";
import { NewReport, pnlOptions } from "shared/types";
import styles from "./styles.module.css";
import { Cell } from "../Cell";
import { ProgressState } from "shared/hooks";
import { eachMonthOfInterval, format } from "date-fns";
import { getSelectedYearOption } from "shared/utils";
import { Skeleton } from "antd";
import { useComputeReport } from "../../../hooks/useComputeReport";

interface IProps {
  selectedReport: NewReport;
}
export const Report: FC<IProps> = ({ selectedReport }) => {
  const [selectedOption, setSelectedOption] = useState<pnlOptions>("last_year");
  const [showLeftScroll, toggleLeftScroll] = useState<boolean>();
  const [showRightScroll, toggleRightScroll] = useState<boolean>();
  const gridRef = useRef<GridType>(null);
  const gridOuterRef = useRef<HTMLDivElement>(null);

  const selectedDateOption = getSelectedYearOption(selectedOption);
  const { onDrillChanged, monthCount, rowDrillIndexer, rowCount, computedReportRows, progress } = useComputeReport(
    selectedDateOption,
    selectedReport
  );

  const handleScrollLeft = () => {
    gridRef.current?.scrollToItem({ rowIndex: 0, columnIndex: 0 });
  };
  const handleScrollRight = () => {
    gridRef.current?.scrollToItem({ rowIndex: 0, columnIndex: monthCount + 1 });
  };
  const handleScroll = useCallback((event: GridOnScroll) => {
    if (event.scrollLeft > 50) {
      toggleLeftScroll(true);
    } else {
      toggleLeftScroll(false);
    }
    if (
      gridOuterRef.current &&
      gridOuterRef.current.clientWidth + event.scrollLeft >= gridOuterRef.current.scrollWidth - 50
    ) {
      toggleRightScroll(false);
    } else {
      toggleRightScroll(true);
    }
  }, []);

  return (
    <div className={styles.container}>
      {showLeftScroll && progress === ProgressState.success && (
        <Button
          className={styles.chevLeft}
          variant={"grey"}
          iconSize={"1.6em"}
          onClick={handleScrollLeft}
          icon={IconName.CHEVRON_LEFT}
        />
      )}
      {showRightScroll && progress === ProgressState.success && (
        <Button
          className={styles.chevRight}
          variant={"grey"}
          iconSize={"1.6em"}
          onClick={handleScrollRight}
          icon={IconName.CHEVRON_RIGHT}
        />
      )}
      {progress === ProgressState.success ? (
        <StickyGrid
          itemData={{
            computedReportRows,
            startDate: selectedDateOption.startDate,
            monthCount,
            rowCount,
            selectedDateOptionLabel: selectedDateOption.label,
            onDrillChanged,
            rowDrillIndexer,
            setSelectedOption,
          }}
          ref={gridRef}
          outerRef={gridOuterRef}
          onScroll={handleScroll}
          rowCount={rowCount}
          columnCount={monthCount + 2}
          rowHeight={() => 40}
          columnWidth={(index) => (index === 0 ? 300 : 132)}
        >
          {Cell}
        </StickyGrid>
      ) : progress === ProgressState.failure ? (
        <div>
          <Typography variant={"body2"}>Empty Report</Typography>
        </div>
      ) : (
        <div className={styles.skeleton}>
          {new Array(12).fill(0).map((_, index) => (
            <RenderSkeleton key={index} selectedDateOption={selectedDateOption} />
          ))}
        </div>
      )}
    </div>
  );
};

const RenderSkeleton: FC<{ selectedDateOption: any }> = ({ selectedDateOption }) => {
  const months = eachMonthOfInterval({
    start: selectedDateOption.startDate,
    end: selectedDateOption.endDate,
  })
    .map((date) => format(date, "MMMM"))
    .concat("Total");
  return (
    <div className={styles.skeletonWrapper}>
      {months.map((month) => (
        <div className={styles.month} style={{ marginRight: "1.6em" }} key={month}>
          <Skeleton.Input block style={{ height: "2em", minWidth: "11.8em" }} active />
        </div>
      ))}
    </div>
  );
};
