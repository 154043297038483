// noinspection ES6PreferShortImport
import { IconName } from "../components/Icon/types";
import {
  endOfQuarter,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subQuarters,
  subYears,
} from "date-fns";
import { Option, pnlOptions } from "../types";
import { Aggregate, ExpressionItem } from "../types";
import { generateGUID } from "./helpers";

const nowDate = Date.now();
const thisQuarterStartDate = startOfQuarter(nowDate);
const thisQuarterEndDate = endOfQuarter(nowDate);
const lastQuarterStartDate = startOfQuarter(subQuarters(nowDate, 1));
const lastQuarterEndDate = endOfQuarter(subQuarters(nowDate, 1));
const thisYearStartDate = startOfYear(nowDate);
const thisYearEndDate = endOfYear(thisYearStartDate);
const lastYearStartDate = subYears(thisYearStartDate, 1);
const lastYearEndDate = subYears(thisYearEndDate, 1);

export const FILTER_OPERATORS = [
  {
    value: "||",
    label: IconName.OR,
  },
  {
    value: "&&",
    label: IconName.AND,
  },
  {
    value: "()",
    label: IconName.PARENTHESES,
  },
];
export const FUNCTION_OPERATORS = [
  {
    value: "+",
    label: IconName.PLUS,
  },
  {
    value: "-",
    label: IconName.MINUS,
  },
  {
    value: "*",
    label: IconName.MULTIPLY,
  },
  {
    value: "/",
    label: IconName.DIVIDE,
  },
  {
    value: "()",
    label: IconName.PARENTHESES,
  },
];
export const AGGREGATE_FUNCTIONS = [
  { value: "min", label: "MIN" },
  { value: "max", label: "MAX" },
  { value: "count", label: "COUNT" },
  { value: "sum", label: "SUM" },
  { value: "avg", label: "AVG" },
];
export const FILTER_EXPRESSION_OPERATORS = [
  { value: "=", label: "=" },
  { value: "!=", label: "!=" },
  { value: ">", label: ">" },
  { value: "<", label: "<" },
  { value: ">=", label: ">=" },
  { value: "<=", label: "<=" },
];
export const FILTER_EXPRESSION_OPERATORS_FRONTEND = [
  { value: "==", label: "=" },
  { value: "!=", label: "!=" },
  { value: ">", label: ">" },
  { value: "<", label: "<" },
  { value: ">=", label: ">=" },
  { value: "<=", label: "<=" },
];
export const PNLDatePreSets = {
  this_quarter: {
    granularity: 3,
    refDate: format(thisQuarterStartDate, "yyyy-MM-dd"),
    label: "This Quarter",
    startDate: thisQuarterStartDate,
    endDate: thisQuarterEndDate,
  },
  last_quarter: {
    granularity: 3,
    refDate: format(lastQuarterStartDate, "yyyy-MM-dd"),
    startDate: lastQuarterStartDate,
    endDate: lastQuarterEndDate,
    label: "Last Quarter",
  },
  this_year: {
    granularity: 12,
    refDate: format(thisYearStartDate, "yyyy-MM-dd"),
    label: "This Year",
    startDate: thisYearStartDate,
    endDate: thisYearEndDate,
  },
  last_year: {
    granularity: 12,
    refDate: format(lastYearStartDate, "yyyy-MM-dd"),
    label: "Last Year",
    startDate: lastYearStartDate,
    endDate: lastYearEndDate,
  },
};

export const getSelectedYearOption = (selectedOption: pnlOptions) => {
  return (
    PNLDatePreSets[selectedOption] || {
      granularity: 12,
      refDate: format(startOfMonth(new Date(Number(selectedOption), 0, 1)), "yyyy-MM-dd"),
      label: selectedOption,
      startDate: startOfYear(new Date(Number(selectedOption), 0, 1)),
      endDate: endOfYear(new Date(Number(selectedOption), 0, 1)),
    }
  );
};

export const stringifyExpression = (items: ExpressionItem[]) =>
  items.reduce((acc, item) => {
    return acc + " " + (item.type === "operator" ? item.value : `${item.value}`);
  }, "");

export const buildExpression = (string: string, aggregates?: Aggregate[]) => {
  const result: ExpressionItem[] = [];
  string.split(" ").forEach((character) => {
    const allOperators = [
      ...FILTER_OPERATORS,
      ...FUNCTION_OPERATORS,
      ...AGGREGATE_FUNCTIONS,
      ...FILTER_EXPRESSION_OPERATORS,
    ];
    const isOperator = !![...FILTER_OPERATORS, ...FUNCTION_OPERATORS].find((op) => op.value === character);
    const isFunction = !!AGGREGATE_FUNCTIONS.find((op) => op.value === character);
    const isFilter = !!FILTER_EXPRESSION_OPERATORS.find((op) => op.value === character);
    const oldId = (aggregates || []).find(
      (agg) => agg.name === (allOperators.find((op) => op.value === character)?.value || character).toLowerCase()
    )?.id;
    result.push({
      id: oldId || generateGUID(),
      label: (allOperators.find((op) => op.value === character)?.label || character).toLowerCase(),
      value: (allOperators.find((op) => op.value === character)?.value || character).toLowerCase(),
      type: isOperator ? "operator" : isFunction ? "fn" : isFilter ? "filter" : "identifier",
    });
  });
  return result;
};

export const buildFilterString = ({
  option,
  operatorOption,
  value,
}: {
  option: Option;
  operatorOption?: Option;
  value?: string;
}) => {
  if (option.type === "time") {
    const timeFunctions = [];
    if (operatorOption?.label?.includes("=")) {
      timeFunctions.push([
        `${operatorOption?.label?.includes("!") ? "!" : ""}isEqual(new Date("${value}"), new Date(${option?.value}))`,
      ]);
    }
    if (operatorOption?.label?.includes(">")) {
      timeFunctions.push([`isBefore(new Date("${value}"), new Date(${option?.value}))`]);
    }
    if (operatorOption?.label?.includes("<")) {
      timeFunctions.push([`isBefore(new Date(${option?.value}), new Date("${value}"))`]);
    }
    return `${timeFunctions.join(" || ")}`;
  }
  if (operatorOption?.value?.includes("==")) {
    return `${option?.value || ""}.includes("${value}")`;
  }
  return `${option?.value || ""} ${operatorOption?.value || ""} "${value}"`;
};
