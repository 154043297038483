import React, { FC, useState } from "react";
import "@cyntler/react-doc-viewer/dist/index.css";
import { Table } from "shared/components/Table";
import { Button, Icon, IconName, Typography } from "shared/components";
import { INTERNAL_COLUMNS, pivotData } from "../helpers";
import { EditableCell, EditableRow } from "shared/components/Table/TableComponents";
import { Popconfirm } from "antd";
import { IEntity } from "shared";

interface IProps<T = ReturnType<typeof pivotData>> {
  schema: IEntity;
  data: T;
  onDataChange: (_: T) => void;
}

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

export const FileDataTable: FC<IProps> = ({ schema, data, onDataChange }) => {
  const [selectedRows, setSelectedRows] = useState<Record<string, string>[]>([]);

  const handleSave = (row: Record<string, string>) => {
    if (!data) return;
    const newData = [...data?.data];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    onDataChange({ ...data, data: newData });
  };
  const handleAdd = () => {
    let d: typeof data = data;

    // TODO: Fix this
    if (!d || !d?.data.length) {
      d = {
        data: [],
        columns: [],
      };
    }

    const maxKey = Math.max(...d.data.map((da) => Number(da.key)));

    const newRecord = Object.keys(d?.data[0]).reduce((acc, key) => ({ ...acc, [key]: key }), {});
    const newData = {
      ...newRecord,
      key: (maxKey + 1).toString(),
    };
    onDataChange({ ...d, data: [newData, ...d.data] });
  };

  const handleDelete = (keys: React.Key[]) => {
    onDataChange({ ...data, data: data.data.filter((item) => !keys.includes(item.key)) });
  };

  const handleDeleteMultiple = () => {
    const selectedKeys = selectedRows.map((row) => row.key);
    handleDelete(selectedKeys);
    setSelectedRows([]);
  };

  const colName = (col: string) => {
    return schema.struct.fields[col]?.meta?.inputName?.string || col;
  };

  const columns = data?.columns || [];

  if (columns.length === 0) {
    for (const s in schema.struct.fields) {
      columns.push({
        name: s,
        // TODO: Fix typing
        type: schema.struct.fields[s].type as any,
      });
    }
  }

  return (
    <>
      {selectedRows.length > 0 ? (
        <Popconfirm title="Sure to remove?" onConfirm={handleDeleteMultiple}>
          <Button icon={IconName.CIRCLE_MINUS} variant="alternative" style={{ marginLeft: "auto" }}>
            Remove
          </Button>
        </Popconfirm>
      ) : (
        <Button icon={IconName.PLUS} onClick={handleAdd} variant="primary" style={{ marginLeft: "auto" }}>
          Add New Row
        </Button>
      )}
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        setSelectedRows={setSelectedRows}
        data={data?.data}
        columns={[
          ...(data?.columns
            .filter((c) => !INTERNAL_COLUMNS.includes(c.name.toLowerCase()))
            .map((c) => ({
              title: colName(c.name),
              onCell: (record: Record<string, string>) => ({
                record,
                editable: true,
                dataIndex: c.name,
                title: c.name,
                handleSave,
              }),
              dataIndex: c.name,
              render: (value: string) => <Typography variant={"body2"}>{value}</Typography>,
            })) || []),
          {
            title: "",
            width: 40,
            render: (_, record) =>
              data.data.length >= 1 ? (
                <Popconfirm title="Sure to remove?" onConfirm={() => handleDelete([record.key])}>
                  <Icon name={IconName.TRASH} size={"1.6em"} />
                </Popconfirm>
              ) : null,
          },
        ]}
      />
    </>
  );
};
