import { FC } from "react";
import { TypographyProps } from "./types";
import { useTypographyStyles } from "./useTypographyStyles";

export const Typography: FC<TypographyProps> = ({ variant, style, color, oneLine, bold, ...rest }) => {
  const typographyStyles = useTypographyStyles({
    variant,
    color,
    oneLine,
    bold,
  });

  switch (variant) {
    case "h1":
      return <h1 style={{ ...typographyStyles, ...style }} {...rest} />;
    case "h2":
      return <h2 style={{ ...typographyStyles, ...style }} {...rest} />;
    case "h5":
      return <h5 style={{ ...typographyStyles, ...style }} {...rest} />;
    case "body1":
      return <p style={{ ...typographyStyles, ...style }} {...rest} />;
    case "body2":
      return <p style={{ ...typographyStyles, ...style }} {...rest} />;
    case "body3":
      return <p style={{ ...typographyStyles, ...style }} {...rest} />;
    default: {
      return null;
    }
  }
};
