import React, { FC, useEffect, useMemo, useState } from "react";
import { withOnboardingRequired } from "../guard";
import { Button, CustomTabs, Expression, IconName, Typography } from "shared/components";
import styles from "./styles.module.css";
import { useSoloStore } from "../../contexts/soloConfig";
import {
  SupportedColumnType,
  FILTER_EXPRESSION_OPERATORS,
  financialFormat,
  ProgressState,
  Table as ITable,
} from "shared";
import { format } from "date-fns";
import { Tooltip } from "antd";
import { useExplorerData } from "../../hooks/useExplorerData";
import { Controllers } from "./Controllers";
import { Table } from "shared/components/Table";
import { TableProps } from "antd/lib";
import { CategoryListLibrary } from "./CategoryListLibrary";

const ExplorerPage: FC = () => {
  const [selectedTable, setSelectedTable] = useState<ITable>();
  const [controllersOpen, toggleControllers] = useState<boolean>(false);
  const [modalOpen, toggleOpen] = useState(false);
  const {
    selectedColumns,
    setSelectedColumns,
    handleOrderBy,
    reset,
    columnsTypes,
    progress,
    data,
    onEndReached,
    setFilterExpr,
    filterExpr,
    tableRef,
    cellWidth,
  } = useExplorerData(selectedTable);

  const getTables = useSoloStore((state) => state.aggregates.getTables);
  const tables = useSoloStore((state) => state.aggregates.tables);

  useEffect(() => {
    getTables().then((res) => {
      setSelectedTable(res[0]);
      setSelectedColumns((res[0]?.Columns || []).sort((a, b) => a.Name.localeCompare(b.Name)));
    });
  }, []);

  const handleTabChanged = (index: number) => {
    if (tables[index].Name === selectedTable?.Name) return;
    reset();
    setSelectedTable(tables[index]);
    setSelectedColumns((tables[index]?.Columns || []).sort((a, b) => a.Name.localeCompare(b.Name)));
  };
  const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const isEndReached = target.scrollHeight - target.scrollTop - target.clientHeight < 5;
    if (isEndReached && progress !== ProgressState.loading) {
      onEndReached();
    }
  };
  const handleToggleController = () => {
    toggleControllers((prevState) => !prevState);
  };

  const handleTableChange: TableProps<any>["onChange"] = (_, __, sorter) => {
    const sortOrder = Array.isArray(sorter) ? undefined : sorter.order;
    const sortField = Array.isArray(sorter) ? undefined : sorter.field;
    const mappedOrder = sortOrder === "ascend" ? "ASC" : sortOrder === "descend" ? "DESC" : "DEFAULT";
    handleOrderBy({ column: sortField as string, order: mappedOrder });
  };
  const openModal = () => {
    toggleOpen(true);
  };
  const closeModal = () => {
    toggleOpen(false);
  };

  return (
    <>
      <CategoryListLibrary modalOpen={modalOpen} closeModal={closeModal} />
      <div className={styles.title}>
        <Typography style={{ fontWeight: 400 }} variant="h1" color="var(--grey-scale-95)">
          Explorer
        </Typography>
        <Expression
          wrapperClassname={styles.search}
          expressionOptions={selectedColumns
            .sort((a, b) => a.Name.localeCompare(b.Name))
            .map((col) => ({
              value: col.Name,
              label: col.Name,
              type: col.Type,
            }))}
          nestedOperatorOptions={FILTER_EXPRESSION_OPERATORS}
          setExpression={setFilterExpr}
          expression={filterExpr}
          header={"Column"}
          inputPlaceholder={"Search"}
          searchBar
          type={"filter"}
        />
        <Button onClick={openModal} variant={"alternative"} iconSize={"2em"} icon={IconName.ENRICHMENT_LIST}>
          Category List Library
        </Button>
      </div>
      <CustomTabs
        className={styles.tabs}
        onChange={handleTabChanged}
        tabs={tables.map((tbl) => ({ tabName: tbl.nickname, component: null }))}
      />
      <div className={styles.wrapper}>
        {controllersOpen && (
          <Controllers
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={(selectedTable?.Columns || [])
              .filter((col) => col.Name !== "bis_id")
              .sort((a, b) => a.Name.localeCompare(b.Name))}
            onClose={handleToggleController}
          />
        )}
        <div ref={tableRef} className={styles.container}>
          <Table
            data={data}
            onChange={handleTableChange}
            onScroll={handleScroll}
            columns={[
              {
                width: "4.6em",
                hidden: controllersOpen,
                title: () => (
                  <Button
                    variant={"alternative"}
                    onClick={handleToggleController}
                    iconSize={"1.6em"}
                    className={styles.expandBtn}
                    icon={IconName.CHEVRON_RIGHT}
                  />
                ),
              },
              ...selectedColumns.map((col) => ({
                title: col.Name,
                ellipsis: true,
                width: `${cellWidth}em`,
                dataIndex: col.Name,
                sorter: true,
                render: (value: any) => (
                  <Cell cellWidth={cellWidth} value={value} name={col.Name} type={columnsTypes[col.Name]} />
                ),
              })),
            ]}
          />
        </div>
      </div>
    </>
  );
};

export const Explorer = withOnboardingRequired(ExplorerPage);

const Cell: FC<{ cellWidth: number; value: any; type: SupportedColumnType; name: string }> = ({
  cellWidth,
  name,
  type,
  value,
}) => {
  const [imgError, setImgError] = useState(false);
  const mappedValue = useMemo(() => {
    if (type === "time") {
      return format(new Date(value), "MMM d, yyyy");
    }
    if (type === "decimal") {
      return financialFormat(Number(value));
    }
    if (type === "jsonb" || type === "[]string") {
      try {
        return JSON.stringify(value);
      } catch (e) {
        console.error(`Could not parse ${name} with value ${value}: ${e}`);
        return "?";
      }
    }
    return value;
  }, [value, type]);
  const color = useMemo(() => {
    if (type === "time") {
      return "var(--grey-scale-50)";
    }
  }, [type]);
  const image = name.includes("url") && !!value;

  return (
    <div style={{ width: `${cellWidth}em` }}>
      <Tooltip placement={"topLeft"} title={mappedValue}>
        {image && !imgError ? (
          <img
            alt={name}
            src={value}
            className={styles.image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              setImgError(true);
            }}
          />
        ) : (
          <Typography
            style={{
              textOverflow: "ellipsis",
              overflowX: "hidden",
              whiteSpace: "nowrap",
            }}
            variant={"body2"}
            color={color}
          >
            {mappedValue}
          </Typography>
        )}
      </Tooltip>
    </div>
  );
};
