import { Financials } from "./src/components/Financials";
import { DataSources } from "./src/components/DataSources";
import { Explorer } from "./src/components/Explorer";
import { Directory } from "./src/components/Directory";
import { ManageAccounts } from "./src/components/ManageAccounts";
import { Onboarding } from "./src/components/Onboarding";
import { Files } from "./src/components/Files";
import { ConfigProvider } from "./src/contexts/soloConfig";

const SoloComponents = {
  Financials,
  DataSources,
  Directory,
  ManageAccounts,
  Onboarding,
  ConfigProvider,
  Explorer,
  Files,
};
export default SoloComponents;
