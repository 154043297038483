import Plaid from "../assets/plaid-short.svg";
import Shopify from "../assets/shopify.svg";
import Rutter from "../assets/rutter-short.svg";

export const LayoutStyles = {
  SCREEN_PADDING_VERTICAL: "3.2em",
  SCREEN_PADDING_HORIZONTAL: "8em",
  PAGE_TITLE_MARGIN_BOTTOM: "3.2em",
  BASE_FONT_SIZE: "1em",
  PREVIEW_FONT_SIZE: "0.6em",
};

export const getIntegrationLogo = (name: string) => {
  switch (name) {
    case "plaid":
      return Plaid;
    case "rutter":
      return Rutter;
    default:
      return Shopify;
  }
};

export const colorVariableToHex = (color: string, rootId: string) => {
  const elem = document.getElementById(rootId);
  if (!elem) return "";
  const style = getComputedStyle(elem);
  const colorVariable = color.replace("var(", "").replace(")", "");
  return style.getPropertyValue(colorVariable);
};

export const swapElements = (array: Array<any>, pos1: number, pos2: number) => {
  const newArray = [...array];
  const temp = newArray[pos1];
  newArray[pos1] = newArray[pos2];
  newArray[pos2] = temp;
  return newArray;
};
