import { Button, CustomTabs, DocumentsTable, Icon, IconName, Typography } from "shared/components";
import React, { FC } from "react";
import styles from "./styles.module.css";
import { withOnboardingRequired } from "../guard";
import { Modal, UploadProps } from "antd";
import { useSoloStore } from "../../contexts/soloConfig";
import Dragger from "antd/es/upload/Dragger";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { SoloFile } from "shared";
import { Accounts } from "../ManageAccounts/Accounts";
import { useStore } from "shared/store";

const DataSourcesPage: FC = () => {
  const { tenantId } = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);

  // FML
  const getTenantInfo = useStore((state) => state.tenant.getTenantInfo);
  const getTenants = useStore((state) => state.tenant.getTenants);

  const qc = useQueryClient();
  const navigate = useNavigate();

  const uploadFile = useSoloStore((state) => state.files.uploadFile);
  const onDocumentClicked = (record: SoloFile) =>
    navigate(`/tenant/${record.tenantId || tenantId}/data-sources/files/${record.id}`);

  const props: UploadProps = {
    name: "file",
    accept:
      ".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf",
    multiple: true,
    disabled: !tenantId,
    customRequest: async (f) => {
      f.onProgress?.({ percent: 0 });

      f.onProgress?.({ percent: 50 });
      const res = await uploadFile(f.file as File, tenantId!);

      f.onProgress?.({ percent: 85 });
      await new Promise((resolve) => setTimeout(resolve, 250));

      f.onProgress?.({ percent: 100 });
      await new Promise((resolve) => setTimeout(resolve, 100));

      f.onSuccess?.(res);
    },
    onChange(info) {
      const { status } = info.file;

      qc.invalidateQueries({
        queryKey: ["workflows"],
      });

      if (status !== "uploading") {
      }

      if (status === "done") {
        qc.invalidateQueries({
          queryKey: ["files"],
        });

        // "Refresh" the pages that depend on the store data
        getTenants();
        getTenantInfo(tenantId);

        setModalOpen(false);
      } else if (status === "error") {
      }
    },
  };

  return (
    <>
      <div className={styles.title}>
        <Typography style={{ fontWeight: 400 }} variant="h1" color="var(--grey-scale-95)">
          Data Sources
        </Typography>
        <div className={styles.titleButtons}>
          <Button variant="alternative" icon={IconName.PLUS} disabled>
            File Type
          </Button>
          <Button variant="primary" onClick={() => setModalOpen(true)} icon={IconName.PLUS}>
            Upload file
          </Button>
        </div>
      </div>
      <CustomTabs
        tabs={[
          {
            tabName: "Documents",
            component: <DocumentsTable tenantId={tenantId} onDocumentClicked={onDocumentClicked} />,
          },
          {
            tabName: "Integrations",
            component: <Accounts />,
          },
        ]}
        className={styles.tabs}
      />
      <Modal
        zIndex={999999}
        onCancel={() => setModalOpen(false)}
        destroyOnClose
        centered
        closeIcon={true}
        footer={false}
        open={modalOpen}
        styles={{
          mask: { backgroundColor: "#00000033" },
          body: { paddingBottom: "1em" },
        }}
      >
        <div className={styles.modalContainer}>
          <Typography variant="body2" color="var(--grey-scale-95)" style={{ padding: "1em", textAlign: "center" }}>
            Upload a new file
          </Typography>
          <Dragger {...props}>
            <Icon name={IconName.UPLOAD} size={"2em"} color={"var(--grey-scale-50)"} style={{ marginBottom: "1em" }} />
            <Typography variant="body3" color="var(--grey-scale-50)">
              Click or drag a file to this area to upload
            </Typography>
          </Dragger>
          <div className={styles.modalDescription}></div>
        </div>
      </Modal>
    </>
  );
};

// TODO: seems like the wrapper not only guards but also set styles...
// so I "bypassed" the guard with a param for now
export const DataSources = withOnboardingRequired(DataSourcesPage, { bypassOnboarding: true });
