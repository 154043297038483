import React, { FC, useState } from "react";
import { Skeleton } from "antd";
import { Button, Icon, IconName, Typography } from "shared/components";
import { format } from "date-fns";
import styles from "../Accounts/styles.module.css";
import styles1 from "./styles.module.css";
import { Integration, useIntegrationApp } from "@integration-app/react";

interface IProps {
  loading?: boolean;
  integration?: Integration;
  isPreview?: boolean;
}
export const IntegrationCard: FC<IProps> = ({ integration, loading, isPreview }) => {
  const [imgError, setImgError] = useState(false);
  const integrationApp = useIntegrationApp();

  const handleConnect = () => {
    if (!integration) return;
    integrationApp
      .integration(integration.key)
      .openNewConnection({ showPoweredBy: false, allowMultipleConnections: false });
  };
  const unlinkIntegration = () => {
    if (!integration) return;
    integrationApp.integration(integration.key).disconnect();
  };

  return (
    <>
      <div className={styles.styledIntegrationCard}>
        {loading || !integration ? (
          <Skeleton.Avatar
            shape={"square"}
            size={isPreview ? 24 : 48}
            style={{
              borderRadius: "1.2em",
            }}
            active
          />
        ) : (
          <div className={`${styles.rowImage} ${imgError && styles.defaultImg}`}>
            {imgError ? (
              <Icon name={IconName.INTEGRATION_BLOCK} />
            ) : (
              <img
                alt={"logo"}
                src={integration.logoUri}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  setImgError(true);
                }}
              />
            )}
          </div>
        )}
        <Typography className={styles1.integrationName} variant={"body2"} bold color={"var(--grey-scale-95)"}>
          {loading ? (
            <Skeleton.Input
              size={"small"}
              style={{
                borderRadius: "0.6em",
              }}
              active
            />
          ) : (
            integration?.name
          )}
        </Typography>
        <div className={styles1.isIntegration}>
          {loading ? (
            <Skeleton.Input
              size={"small"}
              style={{
                borderRadius: "0.4em",
              }}
              active
            />
          ) : integration?.connection?.disconnected === false ? (
            <div className={styles.footer}>
              {integration?.connection?.updatedAt && (
                <Typography variant={"body3"} color={"var(--grey-scale-50)"}>
                  Linked on {format(new Date(integration.connection?.updatedAt), "MMM d, yyyy")}
                </Typography>
              )}
              <Button
                onClick={unlinkIntegration}
                icon={IconName.TRASH}
                iconPlacement={"left"}
                className={styles.linked}
                variant={"alternative"}
              >
                Unlink
              </Button>
            </div>
          ) : (
            <Button onClick={handleConnect} variant={"grey"} color="var(--light-teal)" className={styles.button}>
              Link
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
