import { SideBarMain } from "./components/sidebarMain";
import { ROOT_HTML_ID } from "./constants";
import { FC, lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ConfigProvider } from "antd";
import Solo from "solo-react";
import { useGlobalStyles } from "shared/hooks";
import styles from "./styles.module.css";
import Loading from "./components/shared/Loading";
import { Error } from "./components/shared/Error";
import logo from "shared/assets/logo.svg";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { setAxiosAccessToken } from "shared/axios";
import { useGetTenantId } from "./hooks/useGetTenantId";
import { useStore } from "shared/store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const NotFound = lazy(() => import("./views/NotFound"));
const Ingestion = lazy(() => import("./views/Ingestion"));
const Settings = lazy(() => import("./views/Settings"));
const UserDetails = lazy(() => import("./views/Settings/MyTeam/UserDetails"));
const Reporting = lazy(() => import("./views/Reporting"));
const ViewReport = lazy(() => import("./views/ViewReport"));
const Documents = lazy(() => import("./views/Documents"));
const Companies = lazy(() => import("./views/Companies"));
const CustomWebsite = lazy(() => import("./views/CustomWebsite"));

export const Root = () => {
  const getTenants = useStore((state) => state.tenant.getTenants);
  const getUserProfile = useStore((state) => state.settings.getUserProfile);
  useGlobalStyles(ROOT_HTML_ID);
  const [accessToken, setAccessToken] = useState<string>();
  const { tenantId, isSDKPreview } = useGetTenantId();
  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently, error } = useAuth0();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        getAccessTokenSilently().then(async (accessToken) => {
          setAxiosAccessToken(accessToken);
          await getUserProfile();
          await getTenants();
          setAccessToken(accessToken);
        });
      } else {
        loginWithRedirect();
      }
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            optionSelectedBg: "var(--white-scale-90)",
            optionSelectedColor: "var(--grey-scale-30)",
            optionActiveBg: "var(--grey-scale-05)",
            optionPadding: "1em 1.6em",
          },
        },
        token: {
          colorPrimary: "var(--grey-scale-95)",
          colorText: "var(--grey-scale-95)",
          fontFamily: "MainFont",
          fontWeightStrong: 400,
          borderRadius: 4,
        },
      }}
      getTargetContainer={() => {
        const domElement = document.getElementById(ROOT_HTML_ID);
        if (domElement) {
          return domElement;
        }
        return window;
      }}
      getPopupContainer={() => {
        const domElement = document.getElementById(ROOT_HTML_ID);
        if (domElement) {
          return domElement;
        }
        return document.body;
      }}
    >
      <Solo.ConfigProvider
        accessToken={accessToken || ""}
        tenantId={isSDKPreview ? "00000000-0000-0000-0000-000000000000" : tenantId}
      >
        <DndProvider backend={HTML5Backend}>
          <div style={{ width: "100%", display: "flex" }} id={ROOT_HTML_ID}>
            {error && <Error message={error.message} />}
            {!!accessToken && <AppRoutes />}
          </div>
        </DndProvider>
      </Solo.ConfigProvider>
    </ConfigProvider>
  );
};

const ProtectedFinancials = withAuthenticationRequired(Solo.Financials);
const ProtectedExplorer = withAuthenticationRequired(Solo.Explorer);
const ProtectedDirectory = withAuthenticationRequired(Solo.Directory);

const AppRoutes: FC = () => {
  const { pathname } = useLocation();
  return (
    <div className={styles.app}>
      <div className={styles.header}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.main}>
        {pathname !== "/site" && (
          <nav id="nav">
            <SideBarMain />
          </nav>
        )}
        <div className={styles.routes}>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path={"/"} element={<Settings />} />
              <Route path={"/:tabIndex/:userId"} element={<UserDetails />} />
              <Route path={"/ingestion"} element={<Ingestion />} />
              <Route path={"/reporting"} element={<Reporting />} />
              <Route path={"/reporting/:reportId"} element={<ViewReport />} />
              <Route path={"/tenant"} element={<Companies />} />
              <Route path={"/documents"} element={<Documents />} />
              <Route path={"/site"} element={<CustomWebsite />} />
              <Route path={"/tenant/:tenantId/data-sources"} element={<Solo.DataSources />} />
              <Route path={"/tenant/:tenantId/data-sources/files/:fileId"} element={<Solo.Files />} />
              <Route path={"/tenant/:tenantId/reports"} element={<ProtectedFinancials />} />
              <Route path={"/tenant/:tenantId/explorer"} element={<ProtectedExplorer />} />
              <Route path={`/tenant/:tenantId/directory`} element={<ProtectedDirectory />} />
              <Route path={"*"} element={<NotFound />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
};
