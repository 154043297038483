import { Avatar, Icon, IconName, Typography } from "shared/components";
import { FC, Fragment, useEffect, useState } from "react";
import { Skeleton } from "antd";
import { Contact } from "shared/types";
import styles from "./styles.module.css";

interface IProps {
  loadMore: () => Promise<void>;
  loading: boolean;
  onRowClick: (relation: Contact) => void;
  noFilterResult: boolean;
  directoryContacts: Contact[];
  relationId?: string;
}

export const RelationsList: FC<IProps> = ({
  loadMore,
  loading,
  onRowClick,
  directoryContacts,
  noFilterResult,
  relationId,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const changeIndex = (i: number) => {
    setSelectedIndex(i);
    onRowClick(directoryContacts[i]);
  };

  const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const isEndReached = target.scrollHeight - target.scrollTop - target.clientHeight < 5;
    if (isEndReached && !loading && !noFilterResult) {
      await loadMore();
    }
  };

  const renderDivider = (relation: Contact, i: number) =>
    i !== 0 &&
    relation?.name[0] !== directoryContacts[i - 1].name[0] && (
      <div
        className={styles.tableRow}
        style={{
          background: "var(--grey-scale-05)",
        }}
      >
        <Typography variant="body2" bold color="var(--grey-scale-50)">
          {relation?.name[0]?.toUpperCase()}
        </Typography>
      </div>
    );

  const renderSkeleton = () => (
    <div className={styles.tableRow}>
      <Skeleton.Avatar style={{ borderRadius: "0.6em", height: "2.4em", width: "2.4em" }} active />
      <Skeleton.Input
        style={{ height: "1.6em", minWidth: "100%", borderRadius: "0.6em" }}
        className="skeletonInput"
        active
      />
    </div>
  );

  useEffect(() => {
    if (!directoryContacts?.find((rel) => rel.id === relationId)) {
      changeIndex(0);
    }
  }, [relationId, directoryContacts]);

  return (
    <div className={styles.wrapper} onScroll={handleScroll}>
      {loading ? (
        new Array(20).fill(0).map((_, i) => <Fragment key={`table-row-${i}`}>{renderSkeleton()}</Fragment>)
      ) : noFilterResult ? (
        <div className={styles.noResult}>
          <Icon name={IconName.CONTACT_CIRCLE} size={"8em"} />
          <Typography variant="h5" color="var(--grey-scale-95)">
            No Results Found
          </Typography>
          <Typography variant="body2" color="var(--grey-scale-95)">
            Try changing the search filters for better results.
          </Typography>
        </div>
      ) : (
        <>
          {directoryContacts.map((relation, i) => (
            <Fragment key={`table-row-${relation.id}`}>
              {renderDivider(relation, i)}
              <div
                className={styles.tableRow}
                style={{ cursor: "pointer", background: selectedIndex === i ? "var(--grey-scale-05)" : "" }}
                onClick={() => changeIndex(i)}
              >
                <Avatar
                  size="tiny"
                  viewOnly
                  src={relation?.avatar}
                  initials={{
                    color: "var(--grey-scale-95)",
                    text: relation.name,
                  }}
                />
                <Typography variant="body2" color="var(--grey-scale-95)" oneLine>
                  {relation.name}
                </Typography>
              </div>
            </Fragment>
          ))}
          {loading && renderSkeleton()}
        </>
      )}
    </div>
  );
};
