import { useMutation, MutateOptions } from "@tanstack/react-query";
import { SoloFilePatchParams } from "..";
import { useStore } from "../store";

export const useSoloFileUpdate = (opts?: MutateOptions<any, any, any>) => {
  const patch = useStore((store) => store.files.patchFile);

  return useMutation({
    mutationFn: async (params: { id: string; data: SoloFilePatchParams }) => patch(params.id, params.data),
    ...(opts || {}),
  });
};
