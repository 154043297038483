import React, { ChangeEvent, Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { Button, Inputfield, Typography } from "shared/components";
import { INestedStep, useNestedSteps } from "shared/hooks";
import { Checkbox } from "./Checkbox";
import styles from "./style.module.css";
import { SelectListType } from "./SelectListType";
import { CategoriesTable } from "../CategoriesTable";
import { ICategory } from "shared/types";
import { Backtesting } from "./Backtesting";

interface IProps {
  handleCancelAddNew: () => void;
}
export const NewCategoryList: FC<IProps> = ({ handleCancelAddNew }) => {
  const [name, setName] = useState("");
  const [fileCategories, setFileCategories] = useState<ICategory[]>([]);

  const steps: INestedStep[] = useMemo(
    () => [
      {
        title: "Select List Type",
        components: [
          { component: <CategoryListName key={0} name={name} setName={setName} />, disabled: name.length === 0 },
          {
            component: <SelectListType setFileCategories={setFileCategories} key={1} />,
            disabled: fileCategories.length === 0,
          },
        ],
      },
      {
        title: "Define Categories",
        components: [
          {
            component: (
              <CategoriesTable key={0} editable setCategories={setFileCategories} categories={fileCategories} />
            ),
            disabled: fileCategories.length === 0,
          },
        ],
      },
      { title: "Backtesting", optional: true, components: [{ component: <Backtesting /> }, { component: "5" }] },
      { title: "Adjust Categories", optional: true, linked: true, components: [{ component: "7" }] },
      { title: "Complete", components: [{ component: "7" }] },
    ],
    [name, fileCategories]
  );
  const { handleGoNext, handleGoBack, isFirstStep, activeStep, canSkip } = useNestedSteps(steps);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.left}>
          <Typography
            variant={"body3"}
            style={{ fontFamily: "SecondaryFont", marginBottom: "2.4em" }}
            color={"var(--gold)"}
          >
            STEPS
          </Typography>
          {steps.map((step, index) => (
            <Checkbox
              key={step.title}
              active={index === activeStep.main}
              checked={index < activeStep.main}
              title={`${step.title} ${step.optional ? "(Optional)" : ""}`}
              stepCount={index + 1}
              hideTrailing={index === steps.length - 1}
            />
          ))}
        </div>
        <div className={styles.right}>{steps[activeStep.main].components[activeStep.nested].component}</div>
      </div>
      <div className={styles.footer}>
        <Button onClick={isFirstStep ? handleCancelAddNew : handleGoBack} variant={"alternative"}>
          {isFirstStep ? "Cancel" : "Back"}
        </Button>
        {canSkip ? (
          <Button onClick={(e) => handleGoNext(e, 2)}>Skip</Button>
        ) : (
          <Button disabled={steps[activeStep.main].components[activeStep.nested].disabled} onClick={handleGoNext}>
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

const CategoryListName: FC<{ name: string; setName: Dispatch<SetStateAction<string>> }> = ({ setName, name }) => {
  const handleTextChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setName(value);
  };

  return (
    <div className={styles.step}>
      <Inputfield
        type="text"
        value={name}
        onChange={handleTextChange}
        name={""}
        mt={false}
        inputWrapperStyle={{ height: "3.2em" }}
        wrapperClassname={styles.input}
        label={"CATEGORY LIST NAME"}
        borderBottom
        placeholder="Category List 1"
      />
    </div>
  );
};
