import axios from "axios";
import { API_URL } from "../constants";

let activeHttpRequests: { httpAbortCtrl: AbortController; url: string; method?: string }[] = [];

export function abortSignal(targets: string[] = []) {
  activeHttpRequests.forEach((controller) => {
    if (controller?.method?.toLowerCase() === "get" && targets.some((target) => controller.url === target))
      controller?.httpAbortCtrl?.abort();
  });
  activeHttpRequests = [];
}

const axiosInstance = axios.create({
  baseURL: API_URL,
});

function createAxiosResponseInterceptor() {
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      if (error.response.status !== 401) {
        const errorMessage =
          error.response?.data?.errors?.join(";") || error.response?.data?.message || "Failed to fetch";
        const customError: any = new Error(errorMessage);
        customError["code"] = error.status;
        return Promise.reject(customError);
      }
      axiosInstance.interceptors.response.eject(interceptor);
    }
  );
}
createAxiosResponseInterceptor();

axiosInstance.interceptors.request.use((request) => {
  const httpAbortCtrl = new AbortController();
  request.signal = httpAbortCtrl.signal;
  return request;
});

export const setAxiosAccessToken = (accessToken: string) => {
  axiosInstance.defaults.headers.common["X-Solo-Token"] = accessToken;
};

export default axiosInstance;

export * from "./integrations-app";
