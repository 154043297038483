import axios from "..";
import { Tenant } from "../../types";

export const getTenants = (): Promise<Tenant[] | null> => {
  return axios.get("/tenant");
};

export const createTenant = (data: Tenant) => {
  return axios.post("/tenant", data);
};

export const getTenant = (id?: string): Promise<Tenant | undefined> =>
  axios.get<null, Tenant[]>(`/tenant`).then((tenants) => {
    if (id) {
      return tenants.find((ten) => ten.id === id) as Tenant;
    }

    return tenants?.[0];
  });
