import { useEffect, useMemo, useRef, useState } from "react";
import { useProgressState } from "shared/hooks";
import axios from "shared/axios";
import { Column, SupportedColumnType, ExpressionItem, OrderBy, Select, Table } from "shared/types";
import { parseExpression } from "solo-expression-parser";
import { stringifyExpression } from "shared/utils";
import { FilterExpression } from "solo-expression-parser/dist/visitors/FilterExpression";
import { isEqual } from "lodash-es";
import { useSoloStore } from "../contexts/soloConfig";

const LIMIT = 20;
export const useExplorerData = (selectedTable?: Table) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [orderBy, setOrderBy] = useState<OrderBy[]>([]);
  const [page, setPage] = useState(0);
  const [endReached, toggleEndReached] = useState(false);
  const [filterExpr, setFilterExpr] = useState<ExpressionItem[]>([]);
  const [parsedFilter, setParsedFilter] = useState<FilterExpression>();
  const { setLoading, setSuccess, setFailure, progress } = useProgressState();
  const tenantId = useSoloStore((state) => state.tenant.selectedTenant?.id);

  const [selectedColumns, setSelectedColumns] = useState<Column[]>([]);
  const columnsTypes: { [x: string]: SupportedColumnType } = useMemo(
    () => selectedColumns.reduce((acc, col) => ({ ...acc, [col.Name]: col.Type }), {}),
    [selectedColumns]
  );

  const cellWidth = useMemo(() => {
    let width = 14.5;
    // const expandWidth = expandRef.current?.clientWidth || 0;
    const tableWidth = ((tableRef.current?.clientWidth || 11) - 1) / 10;
    if (selectedColumns.length * width < tableWidth) {
      width = tableWidth / selectedColumns.length;
    }
    return width;
  }, [tableRef.current, selectedColumns]);

  useEffect(() => {
    const stringFilter = stringifyExpression(filterExpr);
    if (stringFilter.includes("undefined")) return;
    setParsedFilter((prevState) => {
      const newState = stringFilter.length > 0 ? parseExpression(stringFilter) : undefined;
      if (!isEqual(newState, prevState)) {
        setData([]);
        toggleEndReached(false);
      }
      return newState;
    });
  }, [JSON.stringify(filterExpr)]);
  useEffect(() => {
    if (!selectedTable || endReached) return;
    setLoading();
    axios
      .post<any, { rows?: [] }, Select>("/select-map", {
        from: {
          table: selectedTable.Name,
        },
        orderBy,
        where: parsedFilter
          ? {
              op: "and",
              left: {
                op: "=",
                left: { column: "tenantId" },
                right: { value: tenantId || "" },
              },
              right: parsedFilter,
            }
          : { op: "=", left: { column: "tenantId" }, right: { value: tenantId || "" } },
        columns: selectedColumns.filter((col) => col.Name !== "bis_id").map((col) => ({ name: col.Name })),
        limit: LIMIT,
        offset: LIMIT * page,
      })
      .then((res) => {
        setData((prevState) => [
          ...prevState,
          ...(res.rows || []).map((row: any) => {
            if (row.__document__) {
              return { ...row, __document__: JSON.stringify(row.__document__) };
            }
            return row;
          }),
        ]);
        toggleEndReached((res.rows || []).length < LIMIT);
        setSuccess();
      })
      .catch(setFailure);
  }, [selectedTable, orderBy, page, parsedFilter]);

  const onEndReached = () => {
    setLoading();
    setPage((prevState) => prevState + 1);
  };
  const reset = () => {
    setPage(0);
    toggleEndReached(false);
    setOrderBy([]);
    setFilterExpr([]);
    setData([]);
  };
  const handleOrderBy = (orderBy: OrderBy) => {
    setData([]);
    toggleEndReached(false);
    setOrderBy((prevState) => {
      if (!!prevState.find((item) => item.column === orderBy.column)) {
        if (orderBy.order === "DEFAULT") {
          return prevState.filter((item) => item.column !== orderBy.column);
        } else {
          return prevState.map((item) => {
            if (item.column === orderBy.column) {
              return orderBy;
            }
            return item;
          });
        }
      }
      return [...prevState, orderBy];
    });
  };

  return {
    data,
    progress,
    selectedColumns: selectedColumns.filter((col) => col.Name !== "bis_id"),
    columnsTypes,
    onEndReached,
    reset,
    setSelectedColumns,
    handleOrderBy,
    filterExpr,
    setFilterExpr,
    cellWidth,
    tableRef,
  };
};
