import { useQuery } from "@tanstack/react-query";
import { useStore } from "../store";

export const useSoloFiles = (tenantId?: string) => {
  const getter = useStore((store) => store.files.getFiles);

  return useQuery({
    queryKey: ["files", tenantId],
    queryFn: async () => getter({ tenantId }),
  });
};
