import React, { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { Focusable, Typography } from "..";
import { Aggregate, Column, ExpressionItem, Option, Table, TableRelation } from "../../types";
import styles from "./styles.module.css";
import { useOuterClickDetector } from "../../hooks";
import { ExpressionResult } from "./ExpressionResult";
import { ExpressionBuilder } from "./ExpressionBuilder";
import { CreateAggregate } from "./CreateAggregate";
import { buildFilterString, generateGUID } from "../../utils";

interface ISharedProps {
  inputPlaceholder?: string;
  searchBar?: boolean;
  fullWidth?: boolean;
  expression: ExpressionItem[];
  setExpression: Dispatch<SetStateAction<ExpressionItem[]>>;
  expressionOptions: Option[];
  stringResultEnabled?: boolean;
  newAggregateConfig?: {
    tables: Table[];
    tableRelations: TableRelation[];
    columns: { [x: string]: Column[] };
    createAggregate: (agg: Aggregate) => Promise<void>;
  };
  nestedOperatorOptions?: Option[];
  header: "Aggregate" | "Column";
  type?: "filter" | "function";
}
interface IInnerProps extends ISharedProps {
  blur: () => void;
  focus: () => void;
  focused: boolean;
}
const ExpressionInner: FC<IInnerProps> = ({
  blur,
  newAggregateConfig,
  focus,
  expression,
  focused,
  setExpression,
  type = "function",
  stringResultEnabled,
  expressionOptions,
  nestedOperatorOptions,
  header,
  inputPlaceholder,
  fullWidth,
  searchBar,
}) => {
  const [openLayout, setOpenLayout] = useState<"dropdown" | "drawer">();
  const [newAggregate, setNewAggregate] = useState(false);
  const [selectedAggregate, setSelectedAggregate] = useState<Aggregate>();
  const [formulaSearch, setFormulaSearch] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const resultScrollableRef = useRef<HTMLDivElement>(null);
  const disabled = expressionOptions.length === 0 && !stringResultEnabled;

  useOuterClickDetector(ref, () => {
    if (!!openLayout || !focused) return;
    blur();
  });

  const openNewAggregate = async () => {
    setOpenLayout("drawer");
    setNewAggregate(true);
  };
  const handleExprSelect = (
    extraConfig: { option: Option; operatorOption?: Option; value?: string },
    aggregateId?: string
  ) => {
    const { operatorOption, option, value } = extraConfig || {};
    let exprId = generateGUID();
    setExpression((prevState) => {
      const label =
        type === "filter"
          ? `${option?.label || ""} ${operatorOption?.label || ""} "${value}"`
          : operatorOption?.label
          ? `${operatorOption?.value || ""}(${option?.label || ""})`
          : option?.label || "";
      const valueString =
        type === "filter"
          ? buildFilterString(extraConfig)
          : operatorOption?.label
          ? `${operatorOption?.value || ""}(${option?.label || ""})`
          : option?.value || "";
      if (aggregateId && prevState.find((formulaItem) => formulaItem.id === aggregateId)) {
        exprId = aggregateId;
        return prevState.map((formulaItem) => {
          if (formulaItem.id === aggregateId) {
            return {
              ...formulaItem,
              type: type === "filter" ? "filter" : operatorOption?.label ? "fn" : "identifier",
              label,
              value: valueString,
            };
          }
          return formulaItem;
        });
      }
      return [
        ...prevState,
        {
          id: exprId,
          type: type === "filter" ? "filter" : operatorOption?.label ? "fn" : "identifier",
          label,
          value: valueString,
        },
      ];
    });
    setTimeout(() => {
      if (resultScrollableRef?.current) {
        resultScrollableRef.current.scrollLeft = resultScrollableRef.current.scrollWidth;
      }
    }, 50);
    return exprId;
  };
  const onSaveNewAggregate = async (newAgg: Option, selectedAgg?: Aggregate) => {
    if (newAgg) {
      handleExprSelect({ option: newAgg }, selectedAgg?.id);
    }
  };
  const onCloseNewAggregate = () => {
    setTimeout(() => {
      setOpenLayout(undefined);
    }, 50);
    setNewAggregate(false);
  };
  const handleRemoveExpression = (removedId: string) => {
    setExpression((prevState) =>
      prevState.filter((item) => {
        const id = item.id.replace("-left", "").replace("-right", "");
        return !removedId.startsWith(id);
      })
    );
  };
  const handleContainerClicked = () => {
    if (disabled) return;
    focus();
  };
  const onSelectAggregate = (aggregate: Aggregate) => {
    setSelectedAggregate(aggregate);
    openNewAggregate();
  };

  return (
    <>
      <div onClick={handleContainerClicked} ref={ref} className={`${styles.container} ${disabled && styles.disabled}`}>
        <div className={styles.input}>
          <ExpressionResult
            formulaSearch={formulaSearch}
            resultScrollableRef={resultScrollableRef}
            setFormulaSearch={setFormulaSearch}
            stringResultEnabled={stringResultEnabled}
            focused={focused}
            disabled={disabled}
            inputPlaceholder={inputPlaceholder}
            searchBar={searchBar}
            handleRemoveExpression={handleRemoveExpression}
            expression={expression}
            header={header}
            setExpression={setExpression}
            setOpenLayout={setOpenLayout}
            builderOpen={openLayout === "dropdown" || !focused}
          />
          {!stringResultEnabled && !searchBar && (
            <ExpressionResult
              placeholder
              formulaSearch={formulaSearch}
              builderOpen={openLayout === "dropdown" || !focused}
              disabled={disabled}
              resultScrollableRef={resultScrollableRef}
              setFormulaSearch={setFormulaSearch}
              stringResultEnabled={stringResultEnabled}
              inputPlaceholder={inputPlaceholder}
              searchBar={searchBar}
              focused={focused}
              handleRemoveExpression={handleRemoveExpression}
              expression={expression}
              header={header}
              setExpression={setExpression}
              setOpenLayout={setOpenLayout}
            />
          )}
        </div>
        <ExpressionBuilder
          hide={openLayout === "dropdown" || !focused}
          openNewAggregate={openNewAggregate}
          handleExprSelect={handleExprSelect}
          header={header}
          searchBar={searchBar && !fullWidth}
          setOpenLayout={setOpenLayout}
          stringResultEnabled={stringResultEnabled}
          type={type}
          newAggregateConfig={!!newAggregateConfig}
          onSelectAggregate={onSelectAggregate}
          handleRemoveExpression={handleRemoveExpression}
          nestedOperatorOptions={nestedOperatorOptions}
          expressionSearch={formulaSearch.toLowerCase()}
          resultScrollableRef={resultScrollableRef}
          resultRef={ref}
          expressionOptions={expressionOptions}
          setExpression={setExpression}
        />
        <ExpressionStringResult hide={focused || !stringResultEnabled} expression={expression} />
      </div>
      {newAggregateConfig && (
        <CreateAggregate
          isOpen={newAggregate}
          selectedAggregate={selectedAggregate}
          newAggregateConfig={newAggregateConfig}
          onClose={onCloseNewAggregate}
          onSave={onSaveNewAggregate}
        />
      )}
    </>
  );
};

const ExpressionStringResult: FC<{
  expression: ExpressionItem[];
  hide: boolean;
}> = ({ expression, hide }) => {
  return (
    <div className={`${styles.wrapper} ${hide && styles.hide}`}>
      {expression.map((formulaItem, index) => (
        <div key={formulaItem.id + `${index}`} className={styles.suggestion}>
          <Typography variant={"body2"} color={"var(--grey-scale-50)"}>
            {formulaItem.type === "operator" ? formulaItem.value : formulaItem.label}
          </Typography>
        </div>
      ))}
    </div>
  );
};

interface IProps extends ISharedProps {
  wrapperClassname?: string;
  onBlur?: () => void;
}
export const Expression: FC<IProps> = ({ wrapperClassname, onBlur, ...props }) => {
  return (
    <Focusable customClassname={wrapperClassname}>
      {({ blur, focus, focused }) => (
        <ExpressionInner
          blur={() => {
            blur();
            onBlur && onBlur();
          }}
          focused={focused}
          focus={focus}
          {...props}
        />
      )}
    </Focusable>
  );
};
