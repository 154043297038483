import React, { FC } from "react";
import styles from "./styles.module.css";
import { Icon } from "../Icon";
import { IconName } from "../Icon/types";
import { Typography } from "../Typography";

interface IProps {
  title?: string;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  checked?: boolean;
  hideTrailing?: boolean;
  active?: boolean;
  textColor?: string;
  borderColor?: string;
  classname?: string;
  fillColor?: string;
  tickColor?: string;
}

export const Checkbox: FC<IProps> = ({
  checked,
  onClick,
  hideTrailing,
  active,
  title,
  textColor,
  fillColor,
  borderColor,
  tickColor,
  classname,
}) => {
  return (
    <div className={`${styles.container} ${classname}`}>
      <div className={styles.checkboxBody}>
        <div
          className={styles.box}
          onClick={onClick}
          style={{
            background: checked ? fillColor : "var(--white-scale-100)",
            borderColor:
              checked || active ? borderColor || fillColor || "var(--grey-scale-95)" : "var(--grey-scale-30)",
          }}
        >
          {checked && <Icon name={IconName.CHECK} size={"1.4em"} color={tickColor} />}
        </div>
        {title && (
          <Typography
            variant="body2"
            color={checked || active ? textColor || "var(--grey-scale-95)" : "var(--grey-scale-50)"}
          >
            {title}
          </Typography>
        )}
      </div>
      {!hideTrailing && (
        <div
          className={styles.divider1}
          style={{ background: checked ? borderColor || fillColor || "var(--grey-scale-95)" : "var(--grey-scale-30)" }}
        />
      )}
    </div>
  );
};
