import { FC } from "react";
import { IntegrationCard } from "../IntegrationCard";
import styles from "./styles.module.css";
import { NoIntegrations } from "./NoIntegrations";
import { useIntegrations } from "@integration-app/react";
import { PlaidCard } from "../PlaidCard";

export const Accounts: FC = () => {
  const { integrations, loading: integrationsLoading } = useIntegrations();

  return (
    <>
      {integrations.length === 0 ? (
        <div className={styles.noIntegrations}>
          <NoIntegrations />
        </div>
      ) : (
        <>
          <div className={styles.wrapper}>
            <div className={styles.card}>
              <PlaidCard />
              {integrationsLoading
                ? new Array(4).fill(0).map((_, index) => <IntegrationCard key={index} loading />)
                : integrations.map((integration) => <IntegrationCard key={integration.id} integration={integration} />)}
            </div>
          </div>
        </>
      )}
    </>
  );
};
