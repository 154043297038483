import React, {
  ChangeEvent,
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button as AntButton, Dropdown } from "antd";
import styles from "./styles.module.css";
import { Inputfield } from "../Inputfield";
import { Icon } from "../Icon";
import { IconName } from "../Icon/types";
import { Option } from "../../types";
import { Typography } from "../Typography";

interface IProps {
  placeholder?: string;
  iconSize?: string;
  iconPosition?: "left" | "right";
  disabled?: boolean;
  placement?: "bottomLeft" | "bottomRight";
  borderType?: "bottom" | "full" | "none";
  variant?: "primary" | "action" | "tag";
  searchText?: string;
  options?: Option[];
  body?: ((options: { close: () => void }) => React.ReactElement) | React.ReactNode;
  onOpenChange?: (state: boolean) => void;
  handleSelected?: (item: Option) => void;
  selected?: Option;
  icon?: IconName;
  width?: string;
  className?: string;
  zIndex?: number;
  customMenuElement?: ((options: { close: () => void }) => React.ReactElement) | React.ReactNode;
}
export const AntDropDown: FC<PropsWithChildren<IProps>> = ({
  options = [],
  handleSelected,
  variant = "primary",
  placement = "bottomLeft",
  selected,
  placeholder,
  onOpenChange,
  iconSize,
  iconPosition,
  searchText,
  borderType,
  disabled,
  icon,
  zIndex,
  className,
  body,
  width,
  customMenuElement,
  children,
}) => {
  const [menuOpen, toggleMenu] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!menuOpen) {
      setSearch("");
    }
  }, [menuOpen]);

  const mappedOptions = useMemo(() => {
    return options
      .map((item, index) => ({
        key: index,
        exclude: item.exclude,
        icon: item.image,
        label: item.label,
        onClick: (e: any) => {
          e.domEvent.preventDefault();
          e.domEvent.stopPropagation();
          handleSelected && handleSelected(item);
          item.onClick && item.onClick();
          toggleMenu(false);
        },
      }))
      .filter((option) => option?.exclude || option.label.toLowerCase().includes(search.toLowerCase()));
  }, [options, search]);

  const handleOpenChange = (open: boolean) => {
    onOpenChange && onOpenChange(open);
    toggleMenu(open);
  };
  const handleCloseMenu = () => toggleMenu(false);

  const iconComponent = (
    <Icon
      size={iconSize || "1.2em"}
      style={
        icon ? {} : menuOpen ? { transform: "rotate(-180deg)", transition: "all 0.2s" } : { transition: "all 0.2s" }
      }
      className={styles.icon}
      name={icon || IconName.CHEVRON_DOWN}
    />
  );

  return (
    <>
      <Dropdown
        rootClassName={"ant-custom-dropdown"}
        overlayStyle={{
          border: "1px solid var(--grey-scale-30)",
          borderRadius: "0.4em",
          fontSize: "1em",
          zIndex: zIndex || 100000,
          width,
        }}
        trigger={["click"]}
        onOpenChange={handleOpenChange}
        open={menuOpen}
        disabled={disabled}
        destroyPopupOnHide
        menu={{
          items: mappedOptions,
        }}
        dropdownRender={(menu) =>
          (typeof body === "function" ? body({ close: handleCloseMenu }) : body) || (
            <DropDownInner
              customMenuElement={customMenuElement}
              handleCloseMenu={handleCloseMenu}
              menu={menu}
              search={search}
              setSearch={setSearch}
              searchText={searchText}
            />
          )
        }
        placement={placement}
      >
        {children || (
          <AntButton
            onClick={(e) => {
              e.stopPropagation();
              toggleMenu(true);
            }}
            type="text"
            style={{
              zIndex: zIndex || 100000,
            }}
            className={`${styles[variant]} ${selected !== undefined && styles.active} ${className} ${
              borderType === "full" ? styles.border : borderType === "bottom" ? styles.borderBottom : ""
            } ${menuOpen && styles.hover}`}
          >
            {iconPosition === "left" && iconComponent}
            {selected && selected.image && (
              <Typography variant={"body2"} color={"var(--grey-scale-50)"}>
                {selected.image}
              </Typography>
            )}
            {(selected || placeholder) && (
              <Typography variant={"body2"} color={"var(--grey-scale-50)"}>
                {selected !== undefined ? selected.label : placeholder}
              </Typography>
            )}
            {(!iconPosition || iconPosition === "right") && iconComponent}
          </AntButton>
        )}
      </Dropdown>
    </>
  );
};

interface IDropDownInnerProps {
  setSearch: Dispatch<SetStateAction<string>>;
  searchText?: string;
  search: string;
  menu: ReactNode;
  handleCloseMenu: () => void;
  customMenuElement?: ((options: { close: () => void }) => React.ReactElement) | React.ReactNode;
}
const DropDownInner: FC<IDropDownInnerProps> = ({
  menu,
  handleCloseMenu,
  customMenuElement,
  setSearch,
  searchText,
  search,
}) => {
  const handleSearch = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  }, []);

  return (
    <div className={styles.container}>
      {customMenuElement && typeof customMenuElement === "function"
        ? customMenuElement({ close: handleCloseMenu })
        : customMenuElement}
      {searchText && (
        <Inputfield
          type="text"
          borderBottom
          value={search}
          name={""}
          placeholder={`Search ${searchText}...`}
          onChange={handleSearch}
          wrapperClassname={styles.search}
          mt={false}
        />
      )}
      {React.cloneElement(menu as unknown as React.ReactElement, {
        className: styles.item,
      })}
    </div>
  );
};
