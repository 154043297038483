import { Button, CustomTabs, Icon, IconName, Modal, Typography } from "shared/components";
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import styles from "./style.module.css";
import { CategoryListLibraryData } from "./data";
import { ICategoryList } from "shared/types";
import { CategoriesTable } from "../CategoriesTable";
import { NewCategoryList } from "../NewCategoryList";

interface IProps {
  modalOpen: boolean;
  closeModal: () => void;
}
export const CategoryListLibrary: FC<IProps> = ({ modalOpen, closeModal }) => {
  const [addNew, setAddNew] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (!modalOpen) {
      setAddNew(false);
      setActiveTab(0);
    }
  }, [modalOpen]);

  const handleAddNew = () => {
    setAddNew(true);
  };
  const handleCancelAddNew = () => {
    setAddNew(false);
  };

  return (
    <Modal
      title={addNew ? "New Category List" : "Category List Library"}
      width={"70%"}
      closeModal={closeModal}
      modalOpen={modalOpen}
      hideActions={true}
      buttonProps={{
        text: "Continue",
      }}
    >
      {addNew ? (
        <NewCategoryList handleCancelAddNew={handleCancelAddNew} />
      ) : (
        <CategoryListLibraryInner activeTab={activeTab} setActiveTab={setActiveTab} handleAddNew={handleAddNew} />
      )}
    </Modal>
  );
};

interface ICategoryListLibraryInnerProps {
  handleAddNew: () => void;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
}

const CategoryListLibraryInner: FC<ICategoryListLibraryInnerProps> = ({ handleAddNew, activeTab, setActiveTab }) => {
  const [selectedList, setSelectedList] = useState<ICategoryList>();
  const categoryLists = CategoryListLibraryData.filter((ct) => (ct.system || false) === (activeTab === 0));

  useEffect(() => {
    setSelectedList(categoryLists[0]);
  }, [activeTab]);

  return (
    <>
      <div className={styles.body}>
        <div className={styles.left}>
          <CustomTabs
            bubbles
            onChange={setActiveTab}
            initialIndex={activeTab}
            className={styles.navBlock}
            tabs={[
              { tabName: "SOLO Lists", component: null },
              { tabName: "Custom Lists", component: null },
            ]}
          />
          <div className={styles.lists}>
            {categoryLists.map((catL) => (
              <CategoryListItem
                key={catL.id}
                categoryList={catL}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
              />
            ))}
            {activeTab === 1 && (
              <div className={styles.itemAdd} onClick={handleAddNew}>
                <div className={styles.iconWrapper}>
                  <Icon color={"var(--white-scale-100)"} name={IconName.PLUS} size={"1.6em"} />
                </div>
                <Typography variant={"body3"} style={{ fontFamily: "SecondaryFont" }} color={"var(--gold)"}>
                  NEW CATEGORY LIST
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className={styles.right}>
          {selectedList && <CategoriesTable categories={selectedList.options} system={selectedList.system} />}
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.left} />
        <div className={styles.rightFooter}>
          <Button>Use Template</Button>
        </div>
      </div>
    </>
  );
};

interface ICategoryListItemProps {
  categoryList: ICategoryList;
  selectedList?: ICategoryList;
  setSelectedList: Dispatch<SetStateAction<ICategoryList | undefined>>;
}

const CategoryListItem: FC<ICategoryListItemProps> = ({ categoryList, selectedList, setSelectedList }) => {
  const isActive = selectedList?.id === categoryList.id;
  const handleClick = () => {
    setSelectedList(categoryList);
  };

  return (
    <div className={`${styles.item} ${isActive && styles.activeItem}`} onClick={handleClick}>
      {isActive && <div className={styles.notch} />}
      <Typography variant={"body2"}>{categoryList.name}</Typography>
    </div>
  );
};
