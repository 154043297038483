import { DependencyList, EffectCallback, useCallback, useEffect, useRef } from "react";
import { debounce } from "lodash-es";
import { Option } from "../types";

export const getInitials = (name?: string) => {
  const hasMoreThanAscii = [...(name || "")].some((char) => char.charCodeAt(0) > 127);
  const joinBy = hasMoreThanAscii ? " " : "";
  return (name || "")
    .split(" ")
    .map((a) => a[0])
    .slice(0, 2)
    .join(joinBy)
    .toUpperCase();
};

export const financialFormat = (amount: number) => {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount || 0).replace("$", "");
};

export const convertDataURLToFile = (url: string): Promise<File[]> => {
  const isDataUrl = url.slice(0, 4) === "data";
  const urlTrail = isDataUrl ? "" : "?_=" + new Date().getTime();
  return fetch(url + urlTrail)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      return [new File([blob], "File name", { type: blob.type || "image" })];
    });
};

export function useDebouncedEffect(effect: EffectCallback, deps: DependencyList = [], wait = 300) {
  const cleanUp = useRef<void | (() => void)>();
  const effectRef = useRef<EffectCallback>();
  const updatedEffect = useCallback(effect, deps);
  effectRef.current = updatedEffect;
  const lazyEffect = useCallback(
    debounce(() => {
      cleanUp.current = effectRef.current?.();
    }, wait),
    []
  );
  useEffect(lazyEffect, deps);
  useEffect(() => {
    return () => {
      if (cleanUp.current instanceof Function) {
        cleanUp.current();
      }
    };
  }, []);
}
export const readFileAsBase64 = (file: File | Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (ev: any) => resolve(ev.target.result);
    reader.readAsDataURL(file);
  });
};

export const generateGUID = () => {
  let d = new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const filterOption = (input: string, option: Option | undefined): boolean =>
  option?.value?.includes("reset") ||
  option?.value?.includes("add") ||
  option?.value?.includes("rename") ||
  (typeof option?.label === "object"
    ? (option?.value?.toLowerCase() ?? "")?.includes(input?.toLowerCase())
    : (option?.label?.toLowerCase() ?? "")?.includes(input?.toLowerCase()));

export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));
