import React, { FC, useState } from "react";
import { Icon, IconName, Typography } from "shared/components";
import styles from "./styles.module.css";
import { GetProp, Upload, UploadProps } from "antd";
import * as XLSX from "xlsx";
import { generateGUID } from "shared/utils";

const { Dragger } = Upload;
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

interface IProps {}

export const Backtesting: FC<IProps> = () => {
  const [error, setError] = useState("");

  const beforeUpload = async (file: FileType) => {
    const workbook = XLSX.read(await file.arrayBuffer());
    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    const result = XLSX.utils.sheet_to_json<string[]>(firstSheet, { header: 1 });
    let err = "";
    const fileCategories = result.map((cat) => {
      if (cat.length > 1) {
        err = "File contains more than one column";
      }
      return { name: cat[0].toString(), id: generateGUID() };
    });
    console.log("fileCategories", fileCategories);
    if (err.length === 0) {
      // setFileCategories(fileCategories);
    }
    setError(err);
    return false;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Typography variant={"body1"}>
          Do you want to upload a sample file with line items you have previously categorized for us to backtest?
        </Typography>
        <Dragger
          beforeUpload={beforeUpload}
          name={"file"}
          showUploadList={false}
          accept={
            ".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
          style={{
            background: "var(--grey-scale-05)",
            borderColor: "var(--grey-scale-30)",
            padding: "5em 0",
          }}
          className={styles.dragger}
        >
          <Icon name={IconName.FILE_UPLOAD} size={"4em"} color={"var(--grey-scale-50)"} />
          <div style={{ margin: "2.4em 0" }}>
            <Typography variant={"body2"}>
              Drop Category List here or{" "}
              <span style={{ color: "var(--gold)", textDecoration: "underline" }}>Choose File</span>
            </Typography>
          </div>
          <Typography variant={"body3"} color={"var(--grey-scale-50)"} style={{ fontFamily: "SecondaryFont" }}>
            .CSV .XLS .XLSX
          </Typography>
          {error.length > 0 && (
            <Typography variant={"body3"} color={"var(--red)"} style={{ marginTop: 5 }}>
              {error}
            </Typography>
          )}
        </Dragger>
      </div>
    </div>
  );
};
