import { CustomTabs, Typography } from "shared/components";
import { FC, useState } from "react";
import { Main } from "./Main";
import { useSoloStore } from "../../contexts/soloConfig";
import { Contact, ConfigDirectory } from "shared/types";
import { ISDKComponentProps } from "../types";
import { withOnboardingRequired } from "../guard";

const DirectoryPage: FC<ISDKComponentProps<ConfigDirectory>> = ({ isPreview, ...sdkCustomProps }) => {
  const [relation, setRelation] = useState<Contact | undefined>(undefined);

  const { screen_name: sdkName, ...sdkActions } = useSoloStore((state) => state.sdk.sdkConfigurations.directory);

  const { actions, screen_name } =
    (Object.keys(sdkCustomProps).length > 0 ? sdkCustomProps : { screen_name: sdkName, actions: sdkActions }) || {};

  const tenantId = useSoloStore((state) => state.tenant.selectedTenant?.id);

  const TABS = [
    {
      tabName: "Products",
      exclude: !actions?.products || !tenantId,
      component: (
        <Main
          canEditContact={actions?.edit_contacts}
          isPreview={isPreview}
          contact_type={"Product"}
          setRelation={setRelation}
          relation={relation}
        />
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          marginBottom: "var(--page-title-margin-bottom)",
        }}
      >
        <Typography style={{ fontWeight: 400 }} variant="h1" color="var(--grey-scale-95)">
          {screen_name || "Directory"}
        </Typography>
      </div>
      <CustomTabs tabs={TABS} />
    </>
  );
};

export const Directory = withOnboardingRequired(DirectoryPage);
