import { Modal as AntModal } from "antd";
import React, { FC, PropsWithChildren, ReactNode } from "react";
import { Button, IconName, Typography } from "..";
import styles from "./styles.module.css";

interface IProps {
  modalOpen: boolean;
  closeModal: () => void;
  title: string | ReactNode;
  hideActions?: boolean;
  width?: number | string;
  buttonProps?: {
    form?: string;
    type?: "button" | "submit" | "reset";
    text?: string;
    action?: () => void;
    disabled?: boolean;
  };
  cancelButtonProps?: {
    action?: () => void;
  };
}

export const Modal: FC<PropsWithChildren<IProps>> = ({
  modalOpen,
  children,
  width,
  hideActions,
  cancelButtonProps,
  closeModal,
  title,
  buttonProps,
}) => {
  const handleSave = () => {
    buttonProps?.action && buttonProps.action();
    closeModal();
  };

  return (
    <AntModal
      onCancel={closeModal}
      centered
      width={width || 400}
      closeIcon={false}
      footer={false}
      open={modalOpen}
      destroyOnClose
      styles={{
        mask: { backgroundColor: "#00000033", fontSize: "inherit" },
        body: { fontSize: "inherit" },
        content: { padding: "0", fontSize: "inherit" },
      }}
      rootClassName={styles.modal}
      style={{ fontSize: "inherit" }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          {typeof title === "string" ? (
            <Typography variant="h5" color="var(--grey-scale-95)" style={{ textAlign: "center" }}>
              {title}
            </Typography>
          ) : (
            title
          )}
          <Button
            onClick={closeModal}
            icon={IconName.X}
            variant={"alternative"}
            style={{ width: "2.4em", height: "2.4em", padding: 0 }}
          />
        </div>
        <div className={styles.body}>{children}</div>
        {!hideActions && (
          <div className={styles.actions}>
            <Button className={styles.button} variant={"alternative"} onClick={cancelButtonProps?.action || closeModal}>
              Cancel
            </Button>
            <Button
              className={styles.button}
              variant={"primary"}
              onClick={buttonProps ? undefined : handleSave}
              {...buttonProps}
            >
              {buttonProps?.text || "Save"}
            </Button>
          </div>
        )}
      </div>
    </AntModal>
  );
};
