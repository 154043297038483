import { CSSProperties } from "react";
import { InputProps } from "./types";

export const useInputStyles = ({
  borderBottom,
  borderLess,
  error,
}: {
  borderBottom: InputProps["borderBottom"];
  borderLess: InputProps["borderLess"];
  error: InputProps["error"];
}): CSSProperties => {
  return {
    width: "100%",
    height: "100%",
    borderRadius: "0.4em",
    padding: "0 1em",
    fontSize: "1.4em",
    fontFamily: "inherit",
    position: "relative",
    border: (!borderBottom && "solid 1px var(--grey-scale-20) ") || "none",
    ...(borderBottom || borderLess
      ? {
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: borderBottom ? "1px solid var(--grey-scale-20) " : "none",
          borderRadius: 0,
          paddingLeft: 0,
        }
      : {}),
    ...(error && !borderLess
      ? { border: "solid 1px var(--additional-red)", backgroundColor: "var(--white-scale-100)" }
      : {}),
  };
};
