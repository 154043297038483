import { Store } from "../types";
import * as IntegrationsAPI from "../../axios/integrations";
import { StateCreator } from "zustand";
import { Integration } from "../../types";
import { generateGUID } from "../../utils";

export const integrationsSlice: StateCreator<Store, [], [], Store["integrations"]> = (set, get) => ({
  integrations: [],
  countries: [],
  listIntegrations: async (payload) =>
    IntegrationsAPI.listIntegrations(payload)
      .then(async ({ rows }) => {
        const plaidRows = await IntegrationsAPI.listPlaidAccounts();
        const rutterRows = await IntegrationsAPI.getRutterCredentials();
        const rutter: Integration[] = (rutterRows.rows || []).map((row) => ({
          id: generateGUID(),
          platform_id: "Rutter",
          platform: "rutter",
          updated_at: new Date().toISOString(),
          created_at: new Date().toISOString(),
          state: "",
          status: "synced",
          tenant_id: "",
        }));
        set((state) => ({
          integrations: { ...state.integrations, integrations: [...(rows || []), ...plaidRows, ...rutter] },
        }));
        return [...(rows || []), ...plaidRows, ...rutter];
      })
      .catch(async () => {
        const plaidRows = await IntegrationsAPI.listPlaidAccounts().catch(() => []);
        const rutterRows = await IntegrationsAPI.getRutterCredentials();
        const rutter: Integration[] = (rutterRows.rows || []).map((row) => ({
          id: generateGUID(),
          platform_id: "Rutter",
          platform: "rutter",
          updated_at: new Date().toISOString(),
          created_at: new Date().toISOString(),
          state: "",
          status: "synced",
          tenant_id: "all",
        }));
        set((state) => ({ integrations: { ...state.integrations, integrations: [...plaidRows, ...rutter] } }));
        return [...plaidRows, ...rutter];
      }),
  createLinkToken: (tenantId?: string) => IntegrationsAPI.createLinkToken(tenantId),
  getRutterCredentials: () =>
    IntegrationsAPI.getRutterCredentials().then((res) => {
      set((state) => ({ integrations: { ...state.integrations, rutter: res.rows?.[0] } }));
      return res;
    }),
  rutterLink: (payload) =>
    IntegrationsAPI.rutterLink(payload).then(() => {
      set((state) => ({ integrations: { ...state.integrations, rutter: payload } }));
    }),
  savePublicToken: (payload) => IntegrationsAPI.savePublicToken(payload),
});
