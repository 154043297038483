import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Icon, IconName, Typography } from "shared/components";
import styles from "./styles.module.css";
import { GetProp, Upload, UploadProps } from "antd";
import * as XLSX from "xlsx";
import { CategoryListLibraryData } from "../../CategoryListLibrary/data";
import { ICategory, ICategoryList } from "shared/types";
import { CategoriesTable } from "../../CategoriesTable";
import { generateGUID } from "shared/utils";

const { Dragger } = Upload;
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

interface IProps {
  setFileCategories: Dispatch<SetStateAction<ICategory[]>>;
}

export const SelectListType: FC<IProps> = ({ setFileCategories }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<ICategoryList>();
  const { error, beforeUpload } = useFile(setFileCategories, () => setSelectedTemplate(undefined));
  const categoryLists = CategoryListLibraryData.filter((ct) => ct.system === true);

  return (
    <div className={styles.container}>
      <Typography variant={"body1"}>Upload your own categories list or use a template.</Typography>
      <Dragger
        beforeUpload={beforeUpload}
        name={"file"}
        showUploadList={false}
        accept={
          ".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        }
        style={{
          background: "var(--grey-scale-05)",
          borderColor: "var(--grey-scale-30)",
          padding: "5em 0",
        }}
        className={styles.dragger}
      >
        <Icon name={IconName.FILE_UPLOAD} size={"4em"} color={"var(--grey-scale-50)"} />
        <div style={{ margin: "2.4em 0" }}>
          <Typography variant={"body2"}>
            Drop Category List here or{" "}
            <span style={{ color: "var(--gold)", textDecoration: "underline" }}>Choose File</span>
          </Typography>
        </div>
        <Typography variant={"body3"} color={"var(--grey-scale-50)"} style={{ fontFamily: "SecondaryFont" }}>
          .CSV .XLS .XLSX
        </Typography>
        {error.length > 0 && (
          <Typography variant={"body3"} color={"var(--red)"} style={{ marginTop: 5 }}>
            {error}
          </Typography>
        )}
      </Dragger>
      <Typography variant={"body3"} style={{ fontFamily: "SecondaryFont" }} color={"var(--gold)"}>
        SOLO TEMPLATES
      </Typography>
      <div className={styles.templates}>
        {categoryLists.map((list) => (
          <Template
            key={list.id}
            template={list}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            setFileCategories={setFileCategories}
          />
        ))}
      </div>
    </div>
  );
};

interface ITemplateProps extends IProps {
  template: ICategoryList;
  selectedTemplate?: ICategoryList;
  setSelectedTemplate: Dispatch<SetStateAction<ICategoryList | undefined>>;
}
const Template: FC<ITemplateProps> = ({ template, setSelectedTemplate, setFileCategories, selectedTemplate }) => {
  const handleClicked = () => {
    setSelectedTemplate(template);
    setFileCategories(template.options);
  };

  return (
    <div
      onClick={handleClicked}
      className={`${styles.template} ${selectedTemplate?.id === template.id && styles.active}`}
    >
      <div className={styles.table}>
        <div style={{ fontSize: 5, height: "22em", width: "100%" }}>
          <CategoriesTable nested categories={template.options} system={template.system} />
        </div>
      </div>
      <div className={styles.footer}>
        <Typography variant={"body2"}>{template.name}</Typography>
      </div>
    </div>
  );
};

const useFile = (setFileCategories: (data: ICategory[]) => void, cb: () => void) => {
  const [error, setError] = useState("");

  const beforeUpload = async (file: FileType) => {
    const workbook = XLSX.read(await file.arrayBuffer());
    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    const result = XLSX.utils.sheet_to_json<string[]>(firstSheet, { header: 1 });
    let err = "";
    const fileCategories = result.map((cat) => {
      if (cat.length > 1) {
        err = "File contains more than one column";
      }
      return { name: cat[0].toString(), id: generateGUID() };
    });

    if (err.length === 0) {
      setFileCategories(fileCategories);
      cb();
    }
    setError(err);
    return false;
  };

  return { beforeUpload, error };
};
