import { ExtraButtonProps } from "./types";
import { CSSProperties } from "react";

export const useButtonStyles = ({
  fullWidth,
  color,
  icon,
  variant,
  loading,
  square,
  disabled,
}: ExtraButtonProps): CSSProperties => {
  let defaultStyles: CSSProperties = {
    width: fullWidth ? "100%" : "max-content",
    cursor: loading || disabled ? "not-allowed" : "pointer",
    color: "var(--white-scale-100)",
    gap: "0.57em",
    padding: icon ? (square ? "0.7em" : "0.7em 1.14em 0.7em 0.7em") : "0.85em 1.14em",
  };

  switch (variant) {
    case "primary":
      defaultStyles = {
        ...defaultStyles,
        backgroundColor: color || "var(--grey-scale-95)",
      };
      break;
    case "secondary":
      defaultStyles = {
        ...defaultStyles,
        backgroundColor: color || "var(--additional-purple)",
      };
      break;
    case "alternative":
      defaultStyles = {
        ...defaultStyles,
        backgroundColor: color || "var(--grey-scale-20)",
        color: "var(--grey-scale-95)",
      };
      break;
    case "tag":
      defaultStyles = {
        width: fullWidth ? "100%" : "max-content",
        cursor: loading || disabled ? "not-allowed" : "pointer",
        gap: "0.4em",
        flexDirection: "row-reverse",
        fontFamily: "SecondaryFont",
        padding: icon ? "0.4em 0.8em" : "0.85em 1.14em",
        backgroundColor: color || "var(--purple-scale-10)",
        color: "var(--grey-scale-95)",
      };
      break;
    case "grey":
      defaultStyles = {
        ...defaultStyles,
        backgroundColor: color || "var(--grey-scale-20)",
        color: "var(--grey-scale-60)",
      };
      break;
    case "text":
      defaultStyles = {
        ...defaultStyles,
        backgroundColor: color || "transparent",
        color: "var(--grey-scale-95)",
      };
      break;
    default:
      break;
  }
  return {
    ...defaultStyles,
    ...(disabled
      ? {
          backgroundColor: "var(--grey-scale-10)",
          color: "var(--grey-scale-30)",
          cursor: "not-allowed",
          border: "none",
        }
      : {}),
  };
};
