import { useQuery } from "@tanstack/react-query";
import { useSoloStore } from "../contexts/soloConfig";

export const useSoloSchemaNested = (id?: string) => {
  const getter = useSoloStore((store) => store.aggregates.getSchemaNested);

  return useQuery({
    queryKey: ["schemas", id, "nested"],
    queryFn: async () => getter(id!),
    enabled: !!id,
  });
};
