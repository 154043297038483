import { ICategoryList } from "shared/types";
import { generateGUID } from "shared/utils";

export const CategoryListLibraryData: ICategoryList[] = [
  {
    id: generateGUID(),
    system: true,
    name: "Profit & Loss",
    options: [
      {
        id: generateGUID(),
        name: "Cash and Cash Equivalents",
      },
      {
        id: generateGUID(),
        name: "Allowance for Bad Debts",
      },
      {
        id: generateGUID(),
        name: "Operating Lease Assets - CP",
      },
      {
        id: generateGUID(),
        name: "Costs in Excess of Billings",
      },
      {
        id: generateGUID(),
        name: "Other Fixed Asset",
      },
    ],
  },
  {
    id: generateGUID(),
    system: true,
    name: "Balance Sheet",
    options: [
      {
        id: generateGUID(),
        name: "Accounts Receivable",
      },
      {
        id: generateGUID(),
        name: "Allowance for Debts",
      },
      {
        id: generateGUID(),
        name: "Operating Lease Assets - LT",
      },
      {
        id: generateGUID(),
        name: "(Accumulated Amortization)",
      },
      {
        id: generateGUID(),
        name: "Fixed Asset",
      },
    ],
  },
  {
    id: generateGUID(),
    system: true,
    name: "Cash Flow Statement",
    options: [
      {
        id: generateGUID(),
        name: "Long Term Investments",
      },
      {
        id: generateGUID(),
        name: "Inventory",
      },
      {
        id: generateGUID(),
        name: "Costs in Excess of Billings",
      },
      {
        id: generateGUID(),
        name: "Land & Building",
      },
    ],
  },
  {
    id: generateGUID(),
    name: "Custom Balance Sheet",
    options: [
      {
        id: generateGUID(),
        name: "Long Term Investments",
      },
      {
        id: generateGUID(),
        name: "Inventory",
      },
      {
        id: generateGUID(),
        name: "Costs in Excess of Billings",
      },
      {
        id: generateGUID(),
        name: "Land & Building",
      },
    ],
  },
];
