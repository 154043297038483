import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Avatar, Button, IconName, Inputfield, Typography } from "shared/components";
import { ProgressState, useProgressState } from "shared/hooks";
import { Contact } from "shared/types";
import styles from "./styles.module.css";

interface IProps {
  relation?: Contact;
  close?: () => void;
  submitUpdate: (e: Omit<Contact, "id">) => Promise<void>;
  settingsMenu?: JSX.Element;
}

export const EditDetail: FC<IProps> = ({ relation, settingsMenu, close, submitUpdate }) => {
  const [avatar, setAvatar] = useState("");
  const [state, setState] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    zip_code: "",
    state: "",
    country: "",
    city: "",
  });

  const { setLoading, progress, setSuccess, setFailure } = useProgressState();

  useEffect(() => {
    if (!relation) return;
    setState({
      name: relation.name,
      phone: relation.phone || "",
      email: relation.email || "",
      address: relation.address || "",
      city: relation.city || "",
      country: relation.country || "",
      state: relation.state || "",
      zip_code: relation.zip_code || "",
    });
    setAvatar(relation.avatar || "");
  }, [relation]);

  const handleInputChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setState({
        ...state,
        [name]: value,
      });
    },
    [state]
  );

  const submit = async () => {
    setLoading();
    submitUpdate({ ...state, avatar })
      .then(setSuccess)
      .catch(setFailure);
  };

  return (
    <>
      <div className={styles.title}>
        <div style={{ width: "3em" }} />
        <Avatar
          placeholderIcon={IconName.UPLOAD}
          backgroundColor={"var(--grey-scale-20)"}
          onChange={setAvatar}
          src={avatar}
          size={"picker"}
        />
        <div>
          {settingsMenu || (
            <Button
              style={{
                height: "3em",
                width: "3em",
              }}
              variant="alternative"
              icon={IconName.X}
              onClick={close}
            />
          )}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.input}>
          <Typography variant="body3" color="var(--grey-scale-50)">
            Name
          </Typography>
          <Inputfield
            type="text"
            borderBottom
            value={state.name}
            name={"name"}
            onChange={handleInputChange}
            mt={false}
          />
        </div>
        <div className={styles.input}>
          <Typography variant="body3" color="var(--grey-scale-50)">
            Phone Number
          </Typography>
          <Inputfield
            type="text"
            borderBottom
            value={state.phone}
            name={"phone"}
            onChange={handleInputChange}
            mt={false}
          />
        </div>
        <div className={styles.input}>
          <Typography variant="body3" color="var(--grey-scale-50)">
            Email
          </Typography>
          <Inputfield
            type="text"
            borderBottom
            value={state.email}
            name={"email"}
            onChange={handleInputChange}
            mt={false}
          />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.input}>
          <Typography variant="body3" color="var(--grey-scale-50)">
            Address
          </Typography>
          <Inputfield
            type="text"
            borderBottom
            value={state.address}
            name={"address"}
            onChange={handleInputChange}
            mt={false}
          />
        </div>
        <div className={styles.fields}>
          <div className={styles.field}>
            <Typography variant="body3" color="var(--grey-scale-50)">
              City
            </Typography>
            <Inputfield
              type="text"
              borderBottom
              value={state.city}
              name={"city"}
              onChange={handleInputChange}
              mt={false}
            />
          </div>
          <div className={styles.field}>
            <Typography variant="body3" color="var(--grey-scale-50)">
              State
            </Typography>
            <Inputfield
              type="text"
              borderBottom
              value={state.state}
              name={"state"}
              onChange={handleInputChange}
              mt={false}
            />
          </div>
        </div>
        <div className={styles.fields}>
          <div className={styles.field}>
            <Typography variant="body3" color="var(--grey-scale-50)">
              Zip Code
            </Typography>
            <Inputfield
              type="text"
              borderBottom
              value={state.zip_code}
              name={"zip_code"}
              onChange={handleInputChange}
              mt={false}
            />
          </div>
          <div className={styles.field}>
            <Typography variant="body3" color="var(--grey-scale-50)">
              Country
            </Typography>
            <Inputfield
              type="text"
              borderBottom
              value={state.country}
              name={"country"}
              onChange={handleInputChange}
              mt={false}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button variant="alternative" className={styles.button} onClick={close}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className={styles.button}
          onClick={submit}
          loading={progress === ProgressState.loading}
          disabled={
            !state.name ||
            (state.name === relation?.name &&
              state.email === relation?.email &&
              state.phone === relation?.phone &&
              state.address === relation.address &&
              state.city === relation.city &&
              state.zip_code === relation.zip_code &&
              state.country === relation.country &&
              state.state === relation.state &&
              !avatar)
          }
        >
          Save
        </Button>
      </div>
    </>
  );
};
