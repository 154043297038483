import { FC, useEffect, useMemo, useState } from "react";
import { Button, Icon, IconName, Typography } from "shared/components";
import { SidebarItem } from "./types";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import * as Sentry from "@sentry/react";
import { useAuth0 } from "@auth0/auth0-react";

interface IProps {
  items: SidebarItem[];
}
export const SidebarNew: FC<IProps> = ({ items }) => {
  const { logout } = useAuth0();

  const filteredMenu = useMemo(
    () =>
      items
        .filter((item) => !item.exclude)
        .map((item) => ({ ...item, subItems: item.subItems?.filter((sub) => !sub.exclude) })),
    [items]
  );

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } }).then(() => {
      Sentry.setUser(null);
    });
  };

  const handleDocs = () => {
    window.open("https://solofinance.readme.io/", "_blank");
  };

  const actions = [
    { label: "Documentation", icon: IconName.BOOK, onPress: handleDocs },
    { label: "Log Out", icon: IconName.LOGOUT, onPress: handleLogout },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        {filteredMenu.map((item, i) => (
          <RenderMenuItems menuItems={filteredMenu} item={item} key={`${item.url}-${item.label}-${i}`} />
        ))}
      </div>
      <div className={styles.actionsWrapper}>
        {actions.map((item) => (
          <div key={item.label} onClick={item.onPress} className={`${styles.navigationItem} ${styles.big}`}>
            <>
              <Icon color={"var(--grey-scale-50)"} size={"2em"} name={item.icon} />
              <Typography color={"var(--grey-scale-50)"} variant={"body2"} style={{ marginRight: "auto" }}>
                {item.label}
              </Typography>
            </>
          </div>
        ))}
      </div>
    </div>
  );
};

interface IRenderMenuItemsProps {
  menuItems: SidebarItem[];
  item: SidebarItem;
}
const RenderMenuItems: FC<IRenderMenuItemsProps> = ({ menuItems, item }) => {
  const [expanded, setExpanded] = useState(false);

  const location = useLocation();
  const navigateRoute = useNavigate();

  const isLabel = item.subItems && !item.url;

  useEffect(() => {
    const nestedItems = menuItems.reduce((acc, item) => [...acc, ...(item.subItems || [])], [] as SidebarItem[]);
    setExpanded(!!nestedItems.find((item) => location.pathname.includes(item.url || "")));
  }, [menuItems]);

  const nestedItems = (
    <>
      {expanded &&
        (item.subItems || []).map((subItem, i) => (
          <RenderMenuItems menuItems={menuItems} item={subItem} key={`key-${item.url}-${item.label}-${i}`} />
        ))}
      {item.subItems && !item.expandable && (
        <div className={styles.dividerWrap}>
          <div className={styles.divider} />
        </div>
      )}
    </>
  );
  const menuItem = isLabel ? (
    <>
      <div className={styles.small}>
        {item.label === "OVERVIEW" && (
          <Button
            icon={IconName.CHEVRON_LEFT}
            onClick={() => {
              navigateRoute("/tenant");
            }}
            variant="alternative"
            className={styles.button}
          />
        )}
        <Typography color={"var(--gold)"} style={{ fontFamily: "SecondaryFont" }} variant={"body3"} bold>
          {item.label}
        </Typography>
      </div>
      {nestedItems}
    </>
  ) : (
    <>
      <NavLink
        onClick={(e) => {
          if (item.subItems) {
            e.preventDefault();
            setExpanded((prevState) => !prevState);
          }
        }}
        to={item.url || ""}
        className={styles.navigationItemWrapper}
      >
        {({ isActive }) => (
          <div className={`${styles.navigationItem} ${isActive && styles.isActive}`}>
            {item.icon ? (
              <Icon
                color={isActive ? "var(--grey-scale-95)" : "var(--grey-scale-50)"}
                style={{ marginBottom: "0.2em" }}
                size={"2em"}
                name={item.icon}
              />
            ) : (
              <div style={{ width: !item.subItems && item.url ? "2.2em" : "0" }} />
            )}
            <Typography
              color={isActive ? "var(--grey-scale-95)" : "var(--grey-scale-50)"}
              variant={"body2"}
              bold={isActive}
            >
              {item.label}
            </Typography>
            {item?.subItems && (
              <Icon
                color={"var(--grey-scale-50)"}
                name={expanded ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN}
                size={"1.6em"}
              />
            )}
          </div>
        )}
      </NavLink>
      {nestedItems}
    </>
  );

  return item.component || menuItem;
};
