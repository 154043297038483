import styles from "./styles.module.css";
import { Table as AntTable, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { AnyObject } from "antd/es/_util/type";
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from "react";
import { Icon } from "../Icon";
import { IconName } from "../Icon/types";

interface IProps<T> {
  data: Array<T>;
  noData?: ReactNode;
  columns: ColumnsType<T>;
  expandable?: TableProps<T>["expandable"];
  selectedRowKeys?: string[];
  pagination?: TableProps<T>["pagination"];
  onChange?: TableProps<T>["onChange"];
  onScroll?: TableProps<T>["onScroll"];
  onRow?: TableProps<T>["onRow"];
  components?: TableProps<T>["components"];
  rowClassName?: TableProps<T>["rowClassName"];
  setSelectedRows?: Dispatch<SetStateAction<Array<T>>>;
  loading?: boolean;
  nested?: boolean;
  rowKey?: string;
  noPadding?: boolean;
  showHeader?: boolean;
  className?: string;
}
export function Table<T extends AnyObject>({
  data,
  className,
  loading,
  columns,
  noPadding,
  onChange,
  onScroll,
  pagination,
  nested,
  rowClassName,
  components,
  setSelectedRows,
  onRow,
  expandable,
  selectedRowKeys,
  noData,
  showHeader,
  rowKey,
}: IProps<T>) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [render, toggleRender] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      toggleRender(true);
    }, 50);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`${styles.container} ${className} ${noPadding && styles.noPadding}`} ref={wrapperRef}>
      {render && (
        <AntTable<T>
          pagination={pagination || false}
          rowKey={rowKey}
          onScroll={onScroll}
          rowClassName={rowClassName}
          components={components}
          expandable={expandable}
          showHeader={showHeader}
          onChange={onChange}
          locale={{
            emptyText: noData,
          }}
          onRow={
            onRow
              ? (record) => ({
                  ...onRow(record),
                  onClick: !(onRow(record) as any).disabled ? onRow(record).onClick : undefined,
                  className: !(onRow(record) as any).disabled ? styles.hover : undefined,
                })
              : undefined
          }
          loading={loading}
          rowSelection={
            setSelectedRows
              ? {
                  onChange: (_, selectedRows: T[]) => {
                    setSelectedRows(selectedRows);
                  },
                  selectedRowKeys: selectedRowKeys,
                }
              : undefined
          }
          scroll={{ y: (wrapperRef.current?.scrollHeight || 100) - (nested ? 0 : 50), x: "max-content" }}
          columns={columns.map((col) => ({
            ...col,
            sortIcon: ({ sortOrder }) => (
              <Icon
                name={
                  sortOrder ? (sortOrder === "ascend" ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN) : IconName.SELECTOR
                }
                size={"1.8em"}
                color={"var(--gold)"}
              />
            ),
          }))}
          dataSource={data}
        />
      )}
    </div>
  );
}
