import { useQuery } from "@tanstack/react-query";
import { useStore } from "../store";

export const useSoloFilePreview = (id: string) => {
  const getter = useStore((store) => store.files.getFilePreview);

  return useQuery({
    queryKey: ["file", id, "preview"],
    queryFn: async () => getter(id),
  });
};
