import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { usePlaidLink } from "react-plaid-link";
import { PulseLoader } from "react-spinners";
import { useSoloStore } from "../contexts/soloConfig";
import { Icon, IconName, Typography } from "shared/components";

export const useLinkPlaid = (onSuccess?: () => void) => {
  const [linkToken, setLinkToken] = useState<string>();

  const [api, contextHolder] = notification.useNotification();

  const listIntegrations = useSoloStore((state) => state.integrations.listIntegrations);
  const createLinkToken = useSoloStore((state) => state.integrations.createLinkToken);
  const savePublicToken = useSoloStore((state) => state.integrations.savePublicToken);
  const tenantId = useSoloStore((state) => state.tenant.selectedTenant?.id);

  const resetPlaidConnection = () => setLinkToken(undefined);

  const { open, ready } = usePlaidLink({
    token: linkToken ?? null,
    onExit: resetPlaidConnection,
    onSuccess: async (publicToken) => {
      await savePublicToken({
        tenantId,
        publicToken,
      });
      openNotification();
      onSuccess?.();
    },
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);
  const openNotification = () => {
    api.success({
      message: (
        <Typography style={{ marginTop: "-0.85em" }} variant={"body2"} color={"var(--white-scale-100)"}>
          Processing the data
        </Typography>
      ),
      placement: "bottom",
      icon: (
        <div style={{ marginTop: "0.3em" }}>
          <PulseLoader size={5} color="var(--white-scale-100)" />
        </div>
      ),
      closeIcon: <Icon color={"var(--white-scale-100)"} style={{ marginTop: "-2em" }} name={IconName.X} />,
      style: {
        background: "var(--grey-scale-95)",
        height: 40,
        color: "var(--white-scale-100)",
        borderRadius: "1em",
      },
      duration: 5,
      onClose: () => {
        if (!tenantId) return;
        resetPlaidConnection();
        listIntegrations([tenantId]);
      },
    });
  };
  const openPlaidLink = () => {
    if (!tenantId) return;
    createLinkToken(tenantId).then(({ link_token }) => {
      setLinkToken(link_token);
    });
  };

  return {
    contextHolder,
    openPlaidLink,
  };
};
