import { useEffect } from "react";

import avenirLight from "../assets/fonts/AvenirNextLTPro-Regular.otf";
import avenirRegular from "../assets/fonts/AvenirNextLTPro-Medium.otf";
import avenirMedium from "../assets/fonts/AvenirNextLTPro-Demi.otf";
import avenirBold from "../assets/fonts/AvenirNextLTPro-Bold.otf";

import suisseLight from "../assets/fonts/SuisseIntl-Light.otf";
import suisseRegular from "../assets/fonts/SuisseIntl-Regular.otf";
import suisseMedium from "../assets/fonts/SuisseIntl-Medium.otf";
import suisseBook from "../assets/fonts/SuisseIntl-Book.otf";
import suisseBold from "../assets/fonts/SuisseIntl-Bold.otf";

import IBMMedium from "../assets/fonts/IBMPlexSans-Medium.ttf";

import { LayoutStyles } from "..";

export const useGlobalStyles = (id: string, secondaryFont = false) => {
  useEffect(() => {
    const customStylesheet = new CSSStyleSheet();

    const styles = `
    @font-face {
      font-family: MainFont;
      font-weight: 300;
      font-display: swap;
      src: url(${!secondaryFont ? suisseLight : avenirLight});
    }
    @font-face {
      font-family: SecondaryFont;
      font-weight: 300;
      font-display: swap;
      src: url(${IBMMedium});
    }
    
    @font-face {
        font-family: MainFont;
        font-weight: 400;
        font-display: swap;
        src: url(${!secondaryFont ? suisseRegular : avenirRegular});
      }
      
      @font-face {
        font-family: MainFont;
        font-weight: 450;
        font-display: swap;
        src: url(${suisseBook});
      }
      
      @font-face {
        font-family: MainFont;
        font-weight: 500;
        font-display: swap;
        src: url(${!secondaryFont ? suisseMedium : avenirMedium});
      }
      
      @font-face {
        font-family: MainFont;
        font-weight: 600;
        font-display: swap;
        src: url(${!secondaryFont ? suisseBold : avenirBold});
      }
      
      #${id} {
        --grey-scale-100: #000000;
        --grey-scale-95: #002B31;
        --grey-scale-80: #000000;
        --grey-scale-60: #000000;
        --grey-scale-50: #7E7F7E;
        --grey-scale-40: #798283;
        --grey-scale-30: #DFE0DE;
        --grey-scale-20: #EFF0ED;
        --grey-scale-15: #e9eaec;
        --grey-scale-10: #F6F7F5;
        --grey-scale-05: #FBFCF9;
        --grey-scale-background: #fcfcfb;
        --brand-primary: #000;
        --additional-blue: #1a4c80;
        --additional-light-blue: #e5ecf3;
        --additional-disabled-blue: #b8d8f7;
        --additional-green: #396744;
        --green: #396744;
        --green-scale-20: #e4f0ec;
        --red: #db5935;
        --red-scale-20: #ffebe5;
        --orange: #e59900;
        --orange-scale-20: #faddd4;
        --lime: #6f801a;
        --lime-scale-20: #f9fedf;
        --blue: #1a4c80;
        --blue-scale-20: #e5ecf3;
        --additional-warning: #855d04;
        --additional-light-warning: #fff7e4;
        --additional-light-green: #e4f0ec;
        --additional-disabled-green: #cbe7dc;
        --additional-yellow: #e5a119;
        --additional-light-yellow: #fcf4e3;
        --additional-disabled-yellow: #f7e2b8;
        --additional-purple: #5f58ff;
        --additional-purple-hover: #7979f2;
        --additional-light-purple: #f6f6fb;
        --additional-light-purple-hover: #e1e1fa;
        --additional-disabled-purple: #ccccf2;
        --additional-highlight-purple: #f7f7ff;
        --gold: #7e6f4a;
        --gold-10: #F8F6F2;
      
        --additional-red: #db5935;
        --additional-light-red: #ffebe5;
        --additional-disabled-red: #f7d0d0;
      
        --white-scale-100: #fff;
        --brand-primary-light: rgba(0, 133, 255, 0.1);
        --white-scale-15: rgba(255, 255, 255, 0.15);
        --white-scale-80: rgba(255, 255, 255, 0.8);
        --off-white: #efeffd;
        --additional-off-white: #f2f2f7;
        --purple: #5C5CD6;
        --purple-scale-30: #CBCBFF;
        --purple-scale-10: #E5E5FF;
        --dark-purple: #1f1f30;
        --mercury-grey: #9d9db0;
        --mercury-green: #008375;
        --mercury-purple:#6379fd;
        --gun-powder: #464154;
        --pink: #d9177e;
        --pink-light: #ff77b4;
        --light-teal: #f9f9fb;
        --pastel-blue: #d3dbfd;
        --dark-blue: #5367e3;
        --muted-purple: #71718e;
        --deep-slate-grey: #41415a;
        --light-blue: #E4E5FC;
      
        --brand-secondary-light: rgba(255, 158, 204, 0.1);
        --brand-secondary: #ff9ecc;
        --white-scale-60: rgba(255, 255, 255, 0.6);
        --white-scale-30: rgba(255, 255, 255, 0.3);
        --additional-blue: #1980e5;
        --additional-light-blue: #e5f7ff;
        --primary-color: #5c5cd6;
        --additional-teal: #338899;
        --base-font-size: ${LayoutStyles.BASE_FONT_SIZE};
        --preview-font-size: ${LayoutStyles.PREVIEW_FONT_SIZE};
        --screen-padding-horizontal: ${LayoutStyles.SCREEN_PADDING_HORIZONTAL};
        --screen-padding-vertical: ${LayoutStyles.SCREEN_PADDING_VERTICAL};
        --page-title-margin-bottom: ${LayoutStyles.PAGE_TITLE_MARGIN_BOTTOM};
      }
      
      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }
      
      *,
      *::after,
      *::before {
        box-sizing: inherit
        cursor: inherit
        line-height: normal
      }
      
      html,
      body {
        height: 100%;
        overflow-x: hidden;
        font-family: MainFont;
        font-weight: 400;
      }
      
      body {
        font-family: MainFont;
        font-weight: 400;
        font-size: 62.5%;
        color: var(--color-black);
        background-color: var(--color-background);
        margin: 0;
        display: flex;
        flex-direction: row;
      }
      
      @media only screen and (max-width: 87.5em) {
        body {
          font-size: 55%;
        }
      }
      
      @media only screen and (max-width: 62em) {
        body {
          font-size: 50%;
        }
      }
      
      @media only screen and (max-width: 48em) {
        body {
          font-size: 45%;
        }
      }
      
      @media only screen and (max-width: 36em) {
        body {
          font-size: 40%;
        }
      }
      
      #root {
        color: var(--color-black);
        background-color: var(--color-background);
        margin: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
      }
      
      button {
        background-color: transparent;
        border-width: 0;
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        line-height: inherit;
        padding: 0;
      }
      
      #nav > * {
        height: 100%;
      }
      
      #main {
        flex: 1;
        height: 100%;
        overflow: auto;
      }
      
      #main > div {
        height: 100%;
      }
      
      .ant-drawer {
        outline: none !important;
      }
      .solo-container-active-fix {
        * {
          border-top-left-radius: 0 !important;
          
        }
      }
      .solo-container-active {
        cursor: pointer;
        user-select: none;
        position: relative;
        * {
          border-color: var(--purple) !important;
          &:focus {
            border-color: var(--purple) !important;
          }
        }
      }
      .solo-container-active-fix-address {
        input:first-child, > div {
          border-top-left-radius: 0 !important;
        }
      }
      .solo-container-active-address {
        cursor: pointer;
        user-select: none;
        position: relative;
        > div {
          border: 1px solid var(--purple) !important;
        }
      }
      
      .date-picker-override {
        width: 100%;
        height: 4em;
        border-radius: 0.4em;
        > div:first-child {
          height: 100%;
        }
      }
      .react-datepicker {
        border: 1px solid var(--grey-scale-20);
      }
      .date-picker-calendar-override {
        background: var(--white-scale-100);
      }
      .date-picker-calendar-override .react-datepicker__day-name,
      .date-picker-calendar-override .react-datepicker__day,
      .date-picker-calendar-override .react-datepicker__time-name {
        width: 27px;
        line-height: 27px;
        margin: 3px;
        font-size: 12px;
      }
      .react-datepicker__header {
        background-color: var(--white-scale-100) !important;
      }
      .react-datepicker__header {
        border: none !important;
      }
      .react-datepicker__day-name {
        color: var(--grey-scale-50) !important;
      }
      .react-datepicker__day {
        color: var(--grey-scale-95) !important;
        transition: all 0.2s;
      }
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--selected {
        border-radius: 25px !important;
        background: var(--grey-scale-30) !important;
      }
      .react-datepicker__day:hover {
        border-radius: 25px !important;
        background: var(--grey-scale-95) !important;
        color: var(--white-scale-100) !important;
      }
      .react-datepicker__day--disabled:hover {
        cursor: not-allowed;
        background: var(--white-scale-100) !important;
        color: var(--grey-scale-50) !important;
      }
      .react-datepicker__day--disabled,
      .react-datepicker__day--outside-month {
        color: var(--grey-scale-50) !important;
      }
      .ant-dropdown-menu-item-active {
        background: var(--grey-scale-20) !important;
      }
      .ant-dropdown-menu-item-active:has(input) {
        background: var(--white-scale-100) !important;
      }
      
      .ant-color-picker {
        z-index: 100000 !important;
      }
      
      .ant-notification-notice-wrapper {
        background: var(--grey-scale-95) !important;
      }
      .ant-checkbox-input,
      .ant-checkbox,
      .ant-checkbox-inner {
        width: 20px !important;
        height: 20px !important;
      }
      .ant-checkbox-inner::after {
        inset-inline-start: 29.5% !important;
      }
      .my-checkbox {
        position: relative;
        cursor: pointer;
      }
      .my-checkbox > span:first-child {
        z-index: 10;
        position: absolute;
        top: 16px;
        right: 16px;
      }
      
      .ant-dropdown {
        .ant-table-filter-dropdown {
          box-shadow: 0px 8px 16px 0px #00000033;
        }
        .ant-dropdown-menu {
          padding: 0 !important;
          .ant-dropdown-menu-item {
            padding: 1em 1.6em !important;
            &:hover {
              background-color: var(--grey-scale-10) !important;
            }
          }
      
          .ant-dropdown-menu-item-selected {
            background-color: var(--white-scale-100);
          }
        }
      }
      
      .ant-custom-paragraph * {
        box-shadow: none !important;
      }
      .ant-custom-menu {
        font-size: 6px;
        border-radius: 0.8em;
        .ant-menu {
          border-radius: 0.8em;
          min-width: 16em !important;
          max-height: calc(100vh - 10em) !important;
        }
        .ant-menu-item {
          height: 4em !important;
          line-height: 4em !important;
          padding-inline: 1.6em !important;
          margin-inline: 0.4em !important;
          margin-block: 0.4em !important;
          width: calc(100% - 0.8em) !important;
        }
        .ant-menu-title-content {
          font-size: 1.4em !important;
          margin-inline-start: 0 !important;
        }
      }
      .ant-custom-menu-large {
        font-size: 10px;
      }
      
      .ant-custom-dropdown {
        .ant-table-filter-dropdown {
          box-shadow: 0px 8px 16px 0px #00000033;
        }
        .ant-dropdown-menu {
          padding: 0 !important;
          font-size: inherit;
          border-radius: inherit;
          .ant-dropdown-menu-item {
            font-size: inherit;
            padding: 1em 1.6em !important;
            .ant-dropdown-menu-title-content:not(:has(input)) {
              font-size: 1.4em;
            }
            &:hover {
              background-color: var(--grey-scale-10) !important;
            }
            &:has(input):hover {
              background-color: var(--white-scale-100) !important;
            }
          }
          .ant-dropdown-menu-item-selected {
            background-color: var(--white-scale-100) !important;
          }
        }
      }
      
      .ant-skeleton,.ant-notification,.ant-notification-notice-icon,.ant-notification-notice-message {
        font-size: inherit !important;
      }
        `;

    customStylesheet.replaceSync(styles);

    // check if adoptedStyleSheets is supported
    if (document.adoptedStyleSheets !== undefined) {
      const styleSheetsList = document.adoptedStyleSheets;
      (styleSheetsList as CSSStyleSheet[]).push(customStylesheet);
    } else {
      // Fallback solution
      const styleElement = document.createElement("style");
      styleElement.innerHTML = styles;
      document.head.appendChild(styleElement);
    }
  }, []);
};
