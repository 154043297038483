export enum IconName {
  NOT_FOUND = "404",
  SERVICE_UNAVAILABLE = "503",
  ADDRESS_BOOK = "address-book",
  AND = "and",
  ALERT_CIRCLE = "alert-circle",
  LOGOUT = "logout",
  ARROW_AUTOFIT_HEIGTH = "arrow-autofit-height",
  ARROW_LEFT = "arrow-left",
  BOOK = "book",
  BUTTON = "button",
  BUILDING_BANK = "building-bank",
  CALENDAR = "calendar",
  SDK = "sdk",
  COPY = "copy",
  CHART_LINE = "chart-line",
  CHECK = "check",
  CHEVRON_DOWN = "chevron-down",
  CHEVRON_LEFT = "chevron-left",
  CHEVRON_RIGHT = "chevron-right",
  CHEVRON_UP = "chevron-up",
  CIRCLE_CHECK = "circle-check",
  CIRCLE_CHECK_DOTTED = "circle-check-dotted",
  CIRCLE_EXCLAMATION = "circle-exclamation",
  CIRCLE_MINUS = "circle-minus",
  CIRCLE_X = "circle-x",
  CONTACT_CIRCLE = "contact-circle",
  DATABASE = "database",
  DATA_SOURCE = "data-sources",
  DRAGGABLE = "draggable",
  PAGE = "page",
  DOTS_VERTICAL = "dots-vertical",
  EDIT = "edit",
  ENRICHMENT_LIST = "enrichment-list",
  EXPLORER = "explorer",
  EYE = "eye",
  EYE_OFF = "eye-off",
  FILE = "file",
  FILE_UPLOAD = "file-upload",
  FORM_BLOCK = "form-block",
  UPLOAD = "upload",
  FILTER = "filter",
  FIELD = "field",
  TEXT_AREA = "text-area",
  SELECT = "select",
  ADDRESS = "address",
  DATE_PICKER = "date-picker",
  HOUSE = "house",
  INTEGRATION_BLOCK = "integration-block",
  INVITE = "invite",
  IMPORT = "import",
  KEY = "key",
  LIGHTNING = "lightning",
  LINK = "link",
  LINK2 = "link-2",
  LINK3 = "link-3",
  LIST = "list",
  LOCK = "lock",
  PLUS = "plus",
  MULTIPLY = "multiply",
  OR = "or",
  PARENTHESES = "parentheses",
  PARENTHESES_LEFT = "parentheses-left",
  PARENTHESES_RIGHT = "parentheses-right",
  DIVIDE = "divide",
  SANDBOX = "sandbox",
  SEARCH = "search",
  SETTINGS = "settings",
  STACK_2 = "stack-2",
  STAR = "star",
  PENCIL = "pencil",
  PUBLISH = "publish",
  MINUS = "minus",
  SELECTOR = "selector",
  TABLE = "table",
  TAG = "tag",
  TRASH = "trash",
  USER = "user",
  USERS = "users",
  WEBSITE = "website",
  X = "x",
}
