import { Button, CustomSelect, Icon, IconName, Typography } from "../..";
import React, { FC } from "react";
import styles from "./styles.module.css";
import { Tooltip } from "antd";
import { useSoloFiles, useSoloFileUpdate, useSoloWorkflows } from "../../hooks";
import { format } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import { Table } from "../Table";
import { SoloFile, useSoloSchemas, Workflow, WorkflowStatus } from "../..";
import { useStore } from "../../store";
import { useSoloFilePreview } from "../../hooks/useSoloFilePreview";
import { capitalize } from "lodash-es";

interface IProps {
  tenantId?: string;
  onDocumentClicked?: (doc: SoloFile) => void;
  fileFilter?: (file: SoloFile) => boolean;
}

const soloFileStatusToName = (file: SoloFile, status?: WorkflowStatus): "Processing" | "Pending" | string => {
  if (status === WorkflowStatus.RUNNING) {
    return "Processing";
  } else {
    return capitalize(file.status?.replace(/_/g, " ") || "Pending");
  }
};

export const DocumentsTable: FC<IProps> = ({ tenantId, onDocumentClicked, fileFilter }) => {
  const qc = useQueryClient();

  const tenants = useStore((state) => state.tenant.tenants);
  const getTenants = useStore((state) => state.tenant.getTenants);

  const { data: files, isLoading: isFilesLoading, refetch } = useSoloFiles(tenantId);
  const {
    mutate: patchFile,
    isSuccess: isPatchFileSuccess,
    isPending: isPatchFilePending,
    reset,
  } = useSoloFileUpdate({
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ["files"],
      });

      qc.invalidateQueries({
        queryKey: ["workflows"],
      });

      getTenants();
    },
  });

  const { data: schemas, isLoading: isSchemasLoading } = useSoloSchemas();
  const { data: workflows } = useSoloWorkflows(
    `CustomKeywordField STARTS_WITH "file-processing-base-${tenantId || ""}"`
  );

  React.useEffect(() => {
    if (isPatchFileSuccess) {
      reset();
    }
  }, [isPatchFileSuccess, refetch]);

  const finalFiles = React.useMemo(() => {
    if (!files) {
      return [];
    }

    if (fileFilter) {
      return files.filter(fileFilter);
    }

    return files;
  }, [files, fileFilter]);

  if (isFilesLoading || !files || isSchemasLoading || !schemas) {
    return <div>Loading...</div>;
  }

  const getWorkflowForFile = (file: SoloFile): Workflow | null => {
    if (!workflows) {
      return null;
    }

    return workflows.find((workflow) => workflow.memo["args"]?.["fileId"] === file.id) || null;
  };

  const getTenantFromId = (tenantId: string): string | null => {
    return tenants.find((t) => t.id === tenantId)?.name || null;
  };

  // Simple table with the data
  return (
    <div className={styles.tableContainer}>
      <Table
        data={finalFiles}
        columns={[
          {
            title: "DATE",
            width: "10%",
            dataIndex: "createdAt",
            render: (value) => <Typography variant={"body2"}>{format(new Date(value), "MMM d, yyyy")}</Typography>,
          },
          ...(!tenantId
            ? [
                {
                  title: "CLIENT",
                  width: "20%",
                  dataIndex: "tenantId",
                  render: (_: any, record: SoloFile) => (
                    <CustomSelect
                      data={[
                        ...tenants.map((tenant) => ({
                          label: tenant.name,
                          value: tenant.id,
                        })),
                      ]}
                      disabled={isPatchFilePending}
                      style={{ height: "2em" }}
                      placeholder={"Select client"}
                      value={
                        record.tenantId
                          ? {
                              label: getTenantFromId(record.tenantId) || record.tenantId,
                              value: record.tenantId,
                            }
                          : null
                      }
                      handleChange={(selected) =>
                        !Array.isArray(selected) &&
                        patchFile({
                          id: record.id,
                          data: {
                            tenantId: selected.value,
                          },
                        })
                      }
                    />
                  ),
                },
              ]
            : []),
          {
            title: "NAME",
            width: "30%",
            render: (value: SoloFile) => <FileNameCell value={value} />,
          },
          {
            title: "SOURCE",
            width: "10%",
            render: (value: SoloFile) => (
              <Typography variant={"body2"}>{value.metadata?.source === "auto" ? "Integration" : "Manual"}</Typography>
            ),
          },
          {
            title: "FILE TYPE",
            width: "20%",
            render: (_, record) => (
              <CustomSelect
                data={[
                  ...schemas.map((table) => ({
                    label: table.inputName,
                    value: table.name,
                  })),
                  { label: "Create new", value: "create", exclude: true },
                ]}
                disabled={isPatchFilePending}
                style={{ height: "2em" }}
                placeholder={"Select file type"}
                value={
                  record.fileType
                    ? {
                        label: schemas.find((s) => s.name === record.fileType)?.inputName || record.fileType,
                        value: record.fileType,
                      }
                    : null
                }
                handleChange={(selected) =>
                  !Array.isArray(selected) &&
                  patchFile({
                    id: record.id,
                    data: {
                      fileType: selected.value,
                    },
                  })
                }
              />
            ),
          },
          {
            title: "STATUS",
            width: "20%",
            render: (_, record) => {
              const wf = getWorkflowForFile(record);
              const name = soloFileStatusToName(record, wf?.status);

              const base = (
                <div className={styles.cell}>
                  <Icon name={IconName.CIRCLE_EXCLAMATION} size={"1.6em"} color={"var(--gold)"} />
                  <Typography variant={"body2"} color={"var(--gold)"} style={{ whiteSpace: "nowrap" }}>
                    {name}
                  </Typography>
                </div>
              );

              const isMapped = !!record.fileType;

              if (name === "Processing" || name === "Pending") {
                return (
                  <div className={styles.cell}>
                    <Icon name={IconName.CIRCLE_CHECK_DOTTED} size={"1.6em"} color={"var(--blue)"} />
                    <Typography variant={"body2"} color={"var(--blue)"} style={{ whiteSpace: "nowrap" }}>
                      {name}
                    </Typography>
                  </div>
                );
              } else if (name === "Parsed") {
                return (
                  <div className={styles.cell}>
                    <Icon name={IconName.CIRCLE_EXCLAMATION} size={"1.6em"} color={"var(--gold)"} />
                    <Typography variant={"body2"} color={"var(--green)"} style={{ whiteSpace: "nowrap" }}>
                      {isMapped ? "Data mapping required" : "File type mapping required"}
                    </Typography>
                  </div>
                );
              } else if (name === "Mapped") {
                return (
                  <div className={styles.cell}>
                    <Icon name={IconName.CIRCLE_CHECK} size={"1.6em"} color={"var(--green)"} />
                    <Typography variant={"body2"} color={"var(--green)"} style={{ whiteSpace: "nowrap" }}>
                      {name}
                    </Typography>
                  </div>
                );
              }

              return base;
            },
          },
          {
            hidden: !onDocumentClicked,
            render: (_, record) => (
              <div className={styles.cell} style={{ justifyContent: "flex-end" }}>
                <Tooltip title={record.fileType ? `See details of ${record.name}` : "Please map file type"}>
                  <Button
                    disabled={!record.fileType}
                    variant="text"
                    onClick={() => onDocumentClicked && onDocumentClicked(record)}
                    icon={IconName.CHEVRON_RIGHT}
                  />
                </Tooltip>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

const FileNameCell: FC<{ value: SoloFile }> = ({ value }) => {
  const { data } = useSoloFilePreview(value.id);

  const previewDocument = () => {
    if (data?.url) {
      window.open(data.url, "_blank");
    }
  };

  return (
    <Tooltip title="Preview document">
      <Typography variant={"body2"} style={{ cursor: "pointer" }} onClick={previewDocument}>
        {value.name}
      </Typography>
    </Tooltip>
  );
};
