import { FC, ReactNode } from "react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { Button, Icon, IconName, Typography } from "..";
import styles from "./styles.module.css";

interface IProps {
  date: Date | null;
  onChange: (e: Date | null) => void;
  placeholder?: string;
  label?: string;
  error?: string;
  minDate?: Date;
  maxDate?: Date;
  inputContent?: ReactNode;
  size: "mid" | "big";
  format?: string;
}

export const DatePickerField: FC<IProps> = (props) => {
  return (
    <div
      style={{ width: "100%", position: "relative" }}
      className={`${styles.container} ${props.error && styles.containerError}`}
    >
      {props.label && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "2em",
          }}
        >
          <label className={`${styles.label} ${props.error ? styles.error : ""}`}>{props.label}</label>
        </div>
      )}
      <DatePicker
        wrapperClassName={`date-picker-override`}
        calendarClassName={`date-picker-calendar-override`}
        placeholderText={props.placeholder}
        showPopperArrow={false}
        disabledKeyboardNavigation
        dateFormat={props.format}
        popperPlacement="bottom-end"
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className={styles.customHeader}>
            <Typography style={{ marginRight: "auto", fontSize: "inherit" }} variant="body2">
              {date ? format(new Date(date), "MMMM yyyy") : "From Date"}
            </Typography>
            <Button
              variant={"text"}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              icon={IconName.CHEVRON_LEFT}
            />
            <Button
              variant={"text"}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              icon={IconName.CHEVRON_RIGHT}
            />
          </div>
        )}
        selected={props.date}
        onChange={props.onChange}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
      {props.error && (
        <div
          className={`${styles.messages}`}
          style={{ display: "flex", alignItems: "center", color: "var(--additional-red)" }}
        >
          <Icon name={IconName.CIRCLE_X} color="var(--additional-red)" />
          <p>{props.error}</p>
        </div>
      )}
    </div>
  );
};
