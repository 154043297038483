import { Avatar, IconName, Typography } from "shared/components";
import { useMemo } from "react";
import { useGetTenantId } from "../../hooks/useGetTenantId";
import { useStore } from "shared/store";
import { SidebarNew } from "../shared/SidebarNew";
import styles from "./styles.module.css";
import { SidebarItem } from "../shared/SidebarNew/types";

export const SideBarMain = () => {
  const { tenantId } = useGetTenantId();

  const tenants = useStore((state) => state.tenant.tenants);
  const user = useStore((state) => state.settings.user);

  const [userRole] = user.appMetadata?.roles || ["no_role"];
  const tenantData = (tenants || []).find((tenant) => tenant.id === tenantId);

  const menuItems: SidebarItem[] = useMemo(() => {
    return tenantData
      ? [
          {
            label: "OVERVIEW",
            subItems: [
              {
                component: (
                  <div className={styles.tenant}>
                    <Avatar
                      size="tiny"
                      viewOnly
                      src={tenantData?.image}
                      initials={{
                        color: "var(--grey-scale-95)",
                        text: tenantData?.name || "",
                      }}
                    />
                    <Typography
                      color={"var(--grey-scale-95)"}
                      variant={"body2"}
                      style={{
                        textOverflow: "ellipsis",
                        overflowX: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {tenantData?.name}
                    </Typography>
                  </div>
                ),
              },
              {
                icon: IconName.DATA_SOURCE,
                label: "Data Sources",
                url: `/tenant/${tenantData.id}/data-sources`,
              },
              ...(tenantData.onboarded
                ? [
                    {
                      icon: IconName.CHART_LINE,
                      label: "Reports",
                      url: `/tenant/${tenantData.id}/reports`,
                    },
                    {
                      icon: IconName.EXPLORER,
                      label: "Explorer",
                      url: `/tenant/${tenantData.id}/explorer`,
                    },
                  ]
                : []),
            ],
          },
        ]
      : [
          {
            label: "ACCOUNT",
            subItems: [{ label: "Settings", url: "/", icon: IconName.SETTINGS }],
          },
          {
            label: "SETUP",
            exclude: userRole === "officer",
            subItems: [
              { label: "Ingestion", url: "/ingestion", icon: IconName.STACK_2 },
              { label: "Reporting", url: "/reporting", icon: IconName.CHART_LINE },
            ],
          },
          // Removed for now
          // {
          //   label: "BUILD",
          //   exclude: userRole === "officer",
          //   subItems: [{ label: "Website", url: "/site", icon: IconName.WEBSITE }],
          // },
          {
            label: "MANAGE",
            subItems: [
              { label: "Client Book", url: "/tenant", icon: IconName.ADDRESS_BOOK },
              { label: "Documents", url: "/documents", icon: IconName.FILE },
            ],
          },
        ];
  }, [tenantData]);

  return <SidebarNew items={menuItems} />;
};
