import { Button, Icon, IconName, Typography } from "shared/components";
import React, { FC, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Integration, useIntegrationApp, useIntegrations } from "@integration-app/react";
import { useSoloStore } from "../../../contexts/soloConfig";
import { useLinkPlaid } from "../../../hooks/useLinkPlaid";
import plaidLogo from "shared/assets/plaid.svg";

interface IProps {
  isPreview?: boolean;
  summary?: boolean;
  handleGoNext: () => void;
  handleGoBack: () => void;
}
export const LinkAccounts: FC<IProps> = ({ isPreview, handleGoBack, summary, handleGoNext }) => {
  const { integrations, loading: integrationsLoading, refreshing: integrationsRefreshing, refresh } = useIntegrations();
  const loading = integrationsLoading || integrationsRefreshing;
  const linkedIntegrations = integrations.filter((int) => int.connection?.disconnected === false);

  const selectedTenant = useSoloStore((state) => state.tenant.selectedTenant);
  const createTenant = useSoloStore((state) => state.tenant.createTenant);

  const handleFinish = async () => {
    if (!selectedTenant) return;
    const { integrations: _, ...data } = selectedTenant;
    await createTenant({ ...data, onboarded: true } as any);
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Typography variant="body1" color="var(--grey-scale-95)" style={{ fontSize: "2em", marginBottom: "1em" }}>
          {summary ? "Integrations Summary" : "Setup Integration"}
        </Typography>
        <PlaidRow />
        {!loading &&
          (summary ? linkedIntegrations : integrations).map((option) => (
            <Row integration={option} key={option.id} onChange={refresh} />
          ))}
      </div>
      {!isPreview && (
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "6.4em" }}>
          {summary && (
            <Button onClick={handleGoBack} variant={"grey"} icon={IconName.ARROW_LEFT}>
              Back
            </Button>
          )}
          <Button
            style={{ marginLeft: "auto" }}
            variant={"secondary"}
            onClick={summary ? handleFinish : handleGoNext}
            color={"var(--dark-blue)"}
            disabledColor={"var(--grey-scale-10)"}
            disabled={linkedIntegrations.length === 0}
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
};

const PlaidRow: FC<{}> = () => {
  const listIntegrations = useSoloStore((state) => state.integrations.listIntegrations);
  const tenantId = useSoloStore((state) => state.tenant.selectedTenant?.id);
  const integrations = useSoloStore((state) => state.integrations.integrations);

  const { contextHolder, openPlaidLink } = useLinkPlaid();

  const plaidConnection = integrations.find(
    (i) => i.platform === "plaid" && i.tenant_id === tenantId && i.status === "synced"
  );

  useEffect(() => {
    if (tenantId) {
      listIntegrations([tenantId]);
    }
  }, [tenantId]);

  return (
    <div className={styles.row}>
      <div className={`${styles.rowImage}`}>
        <img alt={"logo"} src={plaidLogo} />
      </div>
      <Typography color={"var(--grey-scale-95)"} variant={"body2"}>
        Plaid
      </Typography>
      {contextHolder}
      {plaidConnection ? (
        <div className={styles.buttons}>
          <Button icon={IconName.CHECK} iconPlacement={"right"} className={styles.linked} variant={"tag"}>
            Linked
          </Button>
        </div>
      ) : (
        <Button onClick={openPlaidLink} variant={"grey"} color="var(--light-teal)" className={styles.button}>
          Link
        </Button>
      )}
    </div>
  );
};

const Row: FC<{ integration: Integration; onChange?: () => void }> = ({ integration, onChange }) => {
  const [imgError, setImgError] = useState(false);
  const integrationApp = useIntegrationApp();

  const handleConnect = () => {
    integrationApp
      .integration(integration.key)
      .openNewConnection({ showPoweredBy: false, allowMultipleConnections: false })
      .then(onChange);
  };

  const unlinkIntegration = () => {
    integrationApp.integration(integration.key).disconnect().then(onChange);
  };

  return (
    <div className={styles.row}>
      <div className={`${styles.rowImage} ${imgError && styles.defaultImg}`}>
        {imgError ? (
          <Icon name={IconName.INTEGRATION_BLOCK} />
        ) : (
          <img
            alt={"logo"}
            src={integration.logoUri}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              setImgError(true);
            }}
          />
        )}
      </div>
      <Typography color={"var(--grey-scale-95)"} variant={"body2"}>
        {integration.name}
      </Typography>
      {integration.connection?.disconnected === false ? (
        <div className={styles.buttons}>
          <Button icon={IconName.CHECK} iconPlacement={"right"} className={styles.linked} variant={"tag"}>
            Linked
          </Button>
          <Button
            onClick={unlinkIntegration}
            icon={IconName.TRASH}
            iconPlacement={"left"}
            className={styles.linked}
            variant={"alternative"}
          >
            Unlink
          </Button>
        </div>
      ) : (
        <Button onClick={handleConnect} variant={"grey"} color="var(--light-teal)" className={styles.button}>
          Link
        </Button>
      )}
    </div>
  );
};
