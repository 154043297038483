import React, { useEffect } from "react";

export const useOuterClickDetector = (ref: React.RefObject<HTMLDivElement>, fn: () => void) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        fn();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref.current, fn]);
};
