import React, { ChangeEvent, FC, PropsWithChildren, useCallback, useRef, useState } from "react";
import { AntDropDown, Button, CustomSelect, Expression, Icon, IconName, Inputfield, Typography } from "../..";
import styles from "./styles.module.css";
import { Aggregate, Column, DrillDown, ExpressionItem, Option, Table, TableRelation } from "../../../types";
import { expressionString, parseExpression } from "solo-expression-parser";
import {
  AGGREGATE_FUNCTIONS,
  buildExpression,
  FILTER_EXPRESSION_OPERATORS,
  generateGUID,
  stringifyExpression,
} from "../../../utils";
import { Drawer } from "antd";
import slug from "slug";

interface IProps {
  onClose: () => void;
  onSave: (newAgg: Option, selectedAgg?: Aggregate) => void;
  isOpen: boolean;
  noMargin?: boolean;
  selectedAggregate?: Aggregate;
  newAggregateConfig: {
    tables: Table[];
    tableRelations: TableRelation[];
    columns: { [x: string]: Column[] };
    createAggregate: (agg: Aggregate) => Promise<void>;
  };
}
export const CreateAggregate: FC<IProps> = ({
  onClose,
  noMargin,
  onSave,
  selectedAggregate,
  isOpen,
  newAggregateConfig,
}) => {
  return (
    <Drawer
      maskStyle={{ background: "transparent" }}
      contentWrapperStyle={{
        marginTop: noMargin ? "7.2em" : "21.6em",
        marginRight: noMargin ? "1em" : "8em",
        width: "60em",
        boxShadow: "-10px 0px 8px -6px rgba(0, 0, 0, 0.1)",
        borderLeft: "1px solid var(--grey-scale-20)",
      }}
      rootStyle={{
        fontSize: "inherit",
      }}
      styles={{
        body: { padding: "3.2em 0 0 3.2em", display: "flex", flexDirection: "column" },
      }}
      placement="right"
      onClose={onClose}
      closeIcon={false}
      open={isOpen}
    >
      {isOpen && (
        <Inner
          isOpen={isOpen}
          selectedAggregate={selectedAggregate}
          onClose={onClose}
          newAggregateConfig={newAggregateConfig}
          onSave={onSave}
        />
      )}
    </Drawer>
  );
};

const Inner: FC<IProps> = ({ newAggregateConfig, onSave, selectedAggregate, onClose }) => {
  const [aggregateName, setAggregateName] = useState(selectedAggregate?.title || "");
  const [table, setTable] = useState<Option | undefined>(() => {
    const originalTable = newAggregateConfig.tables.find((tbl) => tbl.Name === selectedAggregate?.table);
    if (originalTable) {
      return { value: originalTable.Name, label: originalTable.Name };
    }
  });
  const [timeline, setTimeline] = useState<Option | undefined>(() => {
    const originalTable = newAggregateConfig.tables.find((tbl) => tbl.Name === selectedAggregate?.table);
    if (originalTable) {
      const originalCol = newAggregateConfig.columns[originalTable.Name].find(
        (col) => col.Name === selectedAggregate?.timeline
      );
      if (originalCol) {
        return { value: originalCol.Name, label: originalCol.Name, type: originalCol.Type };
      }
    }
  });
  const [grouping, setGrouping] = useState<string[]>(selectedAggregate?.drillDown.map((drill) => drill.table) || []);
  const [filterExpr, setFilterExpr] = useState<ExpressionItem[]>(() => {
    if (!selectedAggregate?.where) return [];
    const stringExpression = expressionString(selectedAggregate?.where);
    return buildExpression(stringExpression);
  });
  const [functionExpr, setFunctionExpr] = useState<ExpressionItem[]>(() => {
    if (!selectedAggregate?.columns) return [];
    const stringExpression = expressionString(selectedAggregate?.columns[0].expr);
    return buildExpression(stringExpression);
  });
  const formRef = useRef<HTMLDivElement>(null);

  const { createAggregate, columns, tables, tableRelations } = newAggregateConfig;

  const availableDrills = tableRelations.filter((tblR) => tblR.left === table?.label);
  const groupingOptions = availableDrills.map((drill) => ({ value: drill.right, label: drill.right }));

  const selectedColumns = (columns[table?.label || ""] || []).map((col) => ({
    value: col.Name,
    label: col.Name,
    type: col.Type,
  }));

  const canCreate = aggregateName.length > 0 && !!table && !!timeline && functionExpr.length > 0;

  const handleRowNameChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setAggregateName(value);
  }, []);
  const handleTableSelect = useCallback((option?: Option) => {
    setTable(option);
    setTimeline(undefined);
    setGrouping([]);
    setFilterExpr([]);
    setFunctionExpr([]);
  }, []);
  const handleTimelineSelect = useCallback((option?: Option) => {
    setTimeline(option);
  }, []);
  const handleGroupingSelect = useCallback((selected: Option | Option[]) => {
    setGrouping((selected as Option[]).map((opt) => opt.value || ""));
  }, []);
  const onCreateAggregate = useCallback(() => {
    const stringFilter = stringifyExpression(filterExpr);
    const parsedFilter = stringFilter.length > 0 ? parseExpression(stringFilter) : undefined;
    const stringFunction = stringifyExpression(functionExpr);
    const parsedFunction = parseExpression(stringFunction);
    const originalGroupingTable = availableDrills.filter((drill) => !!grouping.find((gr) => gr === drill.right));
    const drillDown: DrillDown[] = originalGroupingTable.map((groupTbl) => ({
      table: groupTbl.right,
      on: [{ agg: groupTbl.leftFields[0], drill: groupTbl.rightFields[0] }],
      select: columns[groupTbl.right].map((col) => col.Name),
    }));
    const id = selectedAggregate ? selectedAggregate.id : generateGUID();
    const aggregate = {
      id,
      title: aggregateName,
      name: slug(aggregateName, "_"),
      table: table?.label || "",
      timeline: timeline?.label || "",
      columns: [{ name: "test", expr: parsedFunction }],
      where: parsedFilter,
      drillDown,
    };
    createAggregate(aggregate)
      .then(onClose)
      .finally(() =>
        onSave({ value: slug(aggregateName, "_"), label: aggregateName, id, extra: aggregate }, selectedAggregate)
      );
  }, [
    filterExpr,
    functionExpr,
    table,
    onSave,
    timeline,
    aggregateName,
    grouping,
    availableDrills,
    columns,
    selectedAggregate,
  ]);
  return (
    <>
      <div className={styles.target}>
        <Typography variant={"h5"}>{selectedAggregate ? "Edit" : "New"} Aggregate</Typography>
        <Button
          className={styles.chevrons}
          onClick={onClose}
          style={{ marginLeft: "auto" }}
          variant={"grey"}
          icon={IconName.X}
        />
      </div>
      <div ref={formRef} className={styles.form}>
        <InputWrapper icon={IconName.PENCIL} title={"NAME"}>
          <Inputfield
            borderBottom
            inputWrapperStyle={{
              height: "3.2em",
            }}
            type="text"
            mt={false}
            value={aggregateName}
            placeholder={"Aggregate Name"}
            name={""}
            onChange={handleRowNameChange}
          />
        </InputWrapper>
        <InputWrapper icon={IconName.TABLE} title={"TABLE"}>
          <AntDropDown
            className={styles.fullWidth}
            searchText={"Search table"}
            placeholder={"Select Table"}
            borderType={"bottom"}
            handleSelected={handleTableSelect}
            selected={table}
            options={tables.map((table) => ({ value: table.Name, label: table.Name }))}
          />
        </InputWrapper>
        <InputWrapper icon={IconName.CALENDAR} title={"TIMELINE"}>
          <AntDropDown
            className={styles.fullWidth}
            searchText={"Search timeline"}
            placeholder={"Select Date"}
            borderType={"bottom"}
            handleSelected={handleTimelineSelect}
            selected={timeline}
            options={selectedColumns}
          />
        </InputWrapper>
        <InputWrapper icon={IconName.LIST} title={"GROUP BY"}>
          <CustomSelect
            mode="multiple"
            placeholder={"Select Grouping"}
            handleChange={handleGroupingSelect}
            data={groupingOptions}
            borderType={"bottom"}
            disabled={groupingOptions.length === 0}
            value={grouping}
          />
        </InputWrapper>
        <InputWrapper icon={IconName.FILTER} title={"FILTER"} vertical>
          <Expression
            wrapperClassname={styles.cell}
            expressionOptions={selectedColumns}
            nestedOperatorOptions={FILTER_EXPRESSION_OPERATORS}
            setExpression={setFilterExpr}
            expression={filterExpr}
            header={"Column"}
            type={"filter"}
          />
        </InputWrapper>
        <InputWrapper icon={IconName.STACK_2} title={"FUNCTION"} vertical>
          <Expression
            expressionOptions={selectedColumns}
            nestedOperatorOptions={AGGREGATE_FUNCTIONS}
            setExpression={setFunctionExpr}
            expression={functionExpr}
            header={"Column"}
            wrapperClassname={styles.cell}
          />
        </InputWrapper>
      </div>
      <div className={styles.actions}>
        <Button variant="alternative" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onCreateAggregate} disabled={!canCreate}>
          {selectedAggregate ? "Edit" : "Create"}
        </Button>
      </div>
    </>
  );
};

const InputWrapper: FC<PropsWithChildren<{ title: string; icon: IconName; vertical?: boolean }>> = ({
  children,
  title,
  vertical,
  icon,
}) => {
  return (
    <div className={styles.inputWrapper}>
      <div className={styles.input}>
        <div className={styles.iconWrapper}>
          <div className={styles.line} />
          <div className={styles.icon}>
            <Icon size={"1.6em"} name={icon} />
          </div>
          <div className={styles.line} />
        </div>
        <Typography
          variant={"body3"}
          style={{
            fontFamily: "SecondaryFont",
          }}
          color={"var(--gold)"}
          className={styles.inputTitle}
        >
          {title}
        </Typography>
        {!vertical && children}
      </div>
      {vertical && <div className={styles.mainLine} style={{ minHeight: vertical ? "2em" : "4em" }} />}
      {vertical && children}
      <div className={styles.mainLine} style={{ minHeight: vertical ? "2em" : "4em" }} />
    </div>
  );
};
