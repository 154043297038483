import { Store } from "../types";
import * as API from "../../axios/files";
import { StateCreator } from "zustand";
import { SoloFileListParams } from "../../axios/files/types";
import { SoloFile, SoloFileData, SoloFileMapParams, SoloFilePatchParams, SoloFileRawData } from "../../types";

export const fileSlice: StateCreator<Store, [], [], Store["files"]> = (set, _) => ({
  filePreview: null,
  uploadedFile: null,
  file: null,
  files: null,
  getFile: async (id: string) => {
    const file = await API.getFileById<SoloFile>(id, "");

    set((state) => ({
      files: {
        ...state.files,
        file,
      },
    }));

    return file;
  },
  patchFile: async (id: string, params: SoloFilePatchParams) => {
    return API.patchFileById(id, params);
  },
  mapFile: async (id: string, params: SoloFileMapParams) => {
    return API.mapFileById(id, params);
  },
  getFileRawData: async (id: string) => {
    return API.getFileById<SoloFileRawData>(id, "/raw-data");
  },
  getFileData: async (id: string) => {
    return API.getFileById<SoloFileData>(id, "/data");
  },
  predictFileData: async (file) => {
    return API.predictFile(file);
  },
  getFiles: async (params?: SoloFileListParams) => {
    const files = await API.getFiles(params);

    set((state) => ({
      files: { ...state.files, files },
    }));

    return files;
  },
  getFilePreview: async (id: string) => {
    const filePreview = await API.getFilePreview(id);

    set((state) => ({
      files: {
        ...state.files,
        filePreview,
      },
    }));

    return filePreview;
  },
  uploadFile: async (data: File, tenantId: string) => {
    const file = await API.uploadFile(data, tenantId);

    set((state) => ({
      files: {
        ...state.files,
        file,
      },
    }));

    return file;
  },
  unsetFilePreview: () => {
    set((state) => ({
      files: { ...state.files, filePreview: null },
    }));
  },
  unsetUploadedFile: () => {
    set((state) => ({
      files: { ...state.files, uploadedFile: null },
    }));
  },
  unsetFile: () => {
    set((state) => ({
      files: { ...state.files, file: null },
    }));
  },
  unsetFiles: () => {
    set((state) => ({
      files: { ...state.files, files: [] },
    }));
  },
});
