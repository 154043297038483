import { ReactNode, useState } from "react";

export interface INestedStep {
  title: string;
  optional?: boolean;
  linked?: boolean;
  components: { component: ReactNode; disabled?: boolean }[];
}

export const useNestedSteps = (steps: INestedStep[]) => {
  const [activeStep, setActiveStep] = useState({
    main: 0,
    nested: 0,
  });
  const [skipped, setSkipped] = useState<{ [x: number]: number }>({});

  const handleGoNext = (_: React.MouseEvent<HTMLButtonElement>, nextStep = 1) => {
    setActiveStep((prevState) => {
      if (nextStep > 1) {
        setSkipped((skipPrevState) => ({
          ...skipPrevState,
          [prevState.main + 1]: nextStep,
        }));
      }
      const nestedCount = steps[prevState.main].components.length - 1;
      if (prevState.main === steps.length - 1 && prevState.nested === nestedCount) return prevState;
      if (prevState.nested < nestedCount && nextStep === 1) return { ...prevState, nested: prevState.nested + 1 };
      return { main: prevState.main + nextStep, nested: 0 };
    });
  };
  const handleGoBack = () => {
    setActiveStep((prevState) => {
      if (prevState.main === 0 && prevState.nested === 0) return prevState;
      if (prevState.nested > 0) return { ...prevState, nested: prevState.nested - 1 };
      const skip = skipped[prevState.main - 1];
      setSkipped((skipPrevState) => {
        if (skip) {
          return { ...skipPrevState, [prevState.main - 1]: 1 };
        }
        return skipPrevState;
      });
      return {
        main: prevState.main - (skip || 1),
        nested: skip !== undefined ? 0 : steps[prevState.main - 1].components.length - 1,
      };
    });
  };

  return {
    activeStep,
    isFirstStep: activeStep.main === 0 && activeStep.nested === 0,
    canSkip:
      (steps[activeStep.main].linked ? skipped[activeStep.main] > 1 : steps[activeStep.main].optional) &&
      activeStep.nested === 0,
    handleGoNext,
    handleGoBack,
  };
};
