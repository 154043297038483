import { CSSProperties } from "react";
import { TypographyProps } from "./types";

export const useTypographyStyles = ({
  variant,
  color,
  oneLine,
  bold,
}: {
  variant: TypographyProps["variant"];
  color: TypographyProps["color"];
  oneLine: TypographyProps["oneLine"];
  bold: TypographyProps["bold"];
}) => {
  const defaultStyles: CSSProperties = {
    color: color ?? "var(--grey-scale-95)",
    ...(oneLine ? { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } : {}),
  };
  const boldStyles = {
    fontFamily: "MainFont",
    fontWeight: 500,
  };
  const regularStyles = {
    fontFamily: "MainFont",
    fontWeight: 400,
  };
  switch (variant) {
    case "h1":
      return {
        fontSize: "3.6em",
        ...defaultStyles,
        ...boldStyles,
      };

    case "h2":
      return {
        fontSize: "3em",
        ...defaultStyles,
        ...boldStyles,
      };
    case "h5":
      return {
        fontSize: "1.8em",
        ...boldStyles,
        ...defaultStyles,
      };
    case "body1":
      return {
        fontSize: "1.6em",
        ...(bold ? boldStyles : regularStyles),
        ...defaultStyles,
      };
    case "body2":
      return {
        fontSize: "1.4em",
        ...(bold ? boldStyles : regularStyles),
        ...defaultStyles,
      };
    case "body3":
      return {
        fontSize: "1.2em",
        ...(bold ? boldStyles : regularStyles),
        ...defaultStyles,
      };
    default:
      return {};
  }
};
