import React, { FC, useEffect } from "react";
import { Button, Typography } from "shared/components";
import { format } from "date-fns";
import styles from "../Accounts/styles.module.css";
import styles1 from "./styles.module.css";
import { useSoloStore } from "../../../contexts/soloConfig";
import { useLinkPlaid } from "../../../hooks/useLinkPlaid";
import plaidLogo from "shared/assets/plaid-short.svg";

export const PlaidCard: FC = () => {
  const listIntegrations = useSoloStore((state) => state.integrations.listIntegrations);
  const tenantId = useSoloStore((state) => state.tenant.selectedTenant?.id);
  const integrations = useSoloStore((state) => state.integrations.integrations);

  const { contextHolder, openPlaidLink } = useLinkPlaid(() => {
    if (!tenantId) return;
    listIntegrations([tenantId]);
  });

  const plaidConnection = integrations.find(
    (i) => i.platform === "plaid" && i.tenant_id === tenantId && i.status === "synced"
  );

  useEffect(() => {
    if (tenantId) {
      listIntegrations([tenantId]);
    }
  }, [tenantId]);

  return (
    <>
      <div className={styles.styledIntegrationCard}>
        <div className={`${styles.rowImage} ${styles.defaultImg}`}>
          <img alt={"logo"} src={plaidLogo} />
        </div>
        <Typography className={styles1.integrationName} variant={"body2"} bold color={"var(--grey-scale-95)"}>
          Plaid
        </Typography>
        {contextHolder}
        <div className={styles1.isIntegration}>
          <div className={styles.footer}>
            {!plaidConnection ? (
              <Button onClick={openPlaidLink} variant={"grey"} color="var(--light-teal)" className={styles.button}>
                Link
              </Button>
            ) : (
              <Typography variant={"body3"} color={"var(--grey-scale-50)"} style={{ padding: "0.71em" }}>
                Linked on {format(new Date(plaidConnection.updated_at || Date.now()), "MMM d, yyyy")}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
