import { Store } from "../types";
import * as API from "../../axios/aggregates";
import { StateCreator } from "zustand";
import { SchemasQuery, Table, TableRelation } from "../../types";

export const aggregatesSlice: StateCreator<Store, [], [], Store["aggregates"]> = (set, get) => ({
  aggregates: [],
  tables: [],
  tableRelations: [],
  columns: {},
  getAggregates: () =>
    API.getAggregates().then((data) => {
      set((state) => ({
        aggregates: { ...state.aggregates, aggregates: data },
      }));
    }),
  getTables: (filter, mapper, sort) =>
    API.getTables().then(async ({ Tables: data }) => {
      const schemas = await get().aggregates.getSchemas();
      const filteredTables = data
        .filter((tbl) =>
          schemas.find((sch) => sch.name === tbl.Name && sch.struct.meta?.scope?.array?.[0]?.string === "reporting")
        )
        .map((tbl) => ({
          ...tbl,
          nickname: (
            schemas.find((sch) => sch.name === tbl.Name)?.struct.meta?.inputName?.string || tbl.Name
          ).replaceAll("_", " "),
          Columns: tbl.Columns.filter((col) => col.Name !== "__document__"),
        }));
      const columns = filteredTables.reduce((acc, table) => ({ ...acc, [table.Name]: table.Columns }), {});
      const trueFn = () => true;
      const mapFn = (a: Table) => a;
      const sortFn = (a: Table, b: Table) => a.Name.localeCompare(b.Name);
      const sortedTables = filteredTables
        .filter(filter || trueFn)
        .map(mapper || mapFn)
        .sort(sort || sortFn);
      const relations = filteredTables.reduce((acc, table) => {
        const schemaRelations = table.Relations || [];
        const mappedRelations: TableRelation[] = schemaRelations.map((rel) => ({
          leftFields: rel.From,
          rightFields: rel.To,
          left: table.Name,
          right: rel.Table,
        }));
        return [...acc, ...mappedRelations];
      }, [] as TableRelation[]);
      set((state) => ({
        aggregates: { ...state.aggregates, tables: sortedTables, columns, tableRelations: relations },
      }));
      return sortedTables;
    }),
  getTable: (name: string) => {
    return API.getTable(name);
  },
  createAggregate: (payload) =>
    API.createAggregate(payload).then(() => {
      set((state) => {
        const exist = state.aggregates.aggregates.find((agg) => agg.id === payload.id);
        return {
          aggregates: {
            ...state.aggregates,
            aggregates: exist
              ? state.aggregates.aggregates.map((agg) => {
                  if (agg.id === payload.id) {
                    return payload;
                  }
                  return agg;
                })
              : [payload, ...state.aggregates.aggregates],
          },
        };
      });
    }),
  getSchemas: (q?: SchemasQuery) => API.getSchemas(q),
  getSchema: (id: string) => API.getSchema(id),
  getSchemaNested: (id: string) => API.getSchemaNested(id),
});
