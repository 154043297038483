import React, { FC, useState } from "react";
import styles from "./styles.module.css";

export const Focusable: FC<{
  children: ((options: { focus: () => void; blur: () => void; focused: boolean }) => React.ReactNode) | React.ReactNode;
  disabled?: boolean;
  autoFocus?: boolean;
  highlight?: boolean;
  customClassname?: string;
}> = ({ children, autoFocus, disabled, highlight = true, customClassname }) => {
  const [focused, toggleFocus] = useState(!!autoFocus);
  const focus = () => {
    if (disabled) return;
    toggleFocus(true);
  };
  const blur = () => {
    if (disabled) return;
    toggleFocus(false);
  };

  return (
    <div
      className={`${styles.cell} ${(disabled || !highlight) && styles.disabled} ${
        focused && highlight && styles.cellFocus
      } ${customClassname}`}
      onClick={(e) => e.stopPropagation()}
    >
      {typeof children === "function"
        ? children({
            focus,
            blur,
            focused,
          })
        : children}
    </div>
  );
};
