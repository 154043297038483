import React, { FC } from "react";
import { Button, Icon, IconName, Typography } from "shared/components";
import styles from "./styles.module.css";

interface IProps {
  image?: string;
  title?: string;
  isPreview?: boolean;
  subTitle?: string;
  btnText?: string;
  showAttributes?: boolean;
  handleBtnClick?: () => void;
}

export const NoIntegrations: FC<IProps> = ({
  image,
  title = "Link Your Accounts",
  subTitle = "SOLO uses state of the art security protocols to ensure no one can access your data.",
  btnText = "Link Account",
  showAttributes = true,
  handleBtnClick,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Typography color={"var(--grey-scale-90)"} variant={"h2"}>
          {title}
        </Typography>

        <Typography style={{ margin: "32px 0" }} color={"var(--grey-scale-95)"} variant={"body2"}>
          {subTitle}
        </Typography>

        <Button variant={"primary"} onClick={handleBtnClick}>
          {btnText}
        </Button>

        {showAttributes && (
          <div className={styles.attributes}>
            <div className={styles.readOnly}>
              <Icon size={"2em"} name={IconName.CIRCLE_CHECK} />
              <Typography color={"var(--grey-scale-95)"} variant={"body2"}>
                Read-only
              </Typography>
            </div>
            <div className={styles.privacy}>
              <Icon size={"2em"} name={IconName.CIRCLE_CHECK} />
              <Typography color={"var(--grey-scale-95)"} variant={"body2"}>
                Data Privacy
              </Typography>
            </div>
            <div className={styles.readOnly}>
              <Icon size={"2em"} name={IconName.CIRCLE_CHECK} />
              <Typography color={"var(--grey-scale-95)"} variant={"body2"}>
                Secure
              </Typography>
            </div>
          </div>
        )}
      </div>

      {image && <img alt={"logo"} src={image} />}
    </div>
  );
};
