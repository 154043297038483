import { useQueryClient } from "@tanstack/react-query";
import { useStore } from "../store";
import { IEntityBase, SupportedColumnType } from "../types";
import * as yup from "yup";
import { FieldArrayWithId, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { sleep } from "../utils";
import { RefObject, useState } from "react";
import snakeCase from "lodash-es/snakeCase";

export interface IAttribute {
  name: string;
  dataType: SupportedColumnType;
  nested: IAttribute[];
}
export interface FileTypesFormValues {
  name: string;
  attributes: IAttribute[];
}

export const REQUIRED_STRING = yup.string().required("This field is required");
const schema = yup
  .object({
    name: REQUIRED_STRING,
    attributes: yup
      .array(
        yup.object({
          name: REQUIRED_STRING,
          dataType: yup.mixed<SupportedColumnType>().required("This field is required"),
        })
      )
      .min(1)
      .required("This field is required"),
  })
  .required();

export const useFileTypeFormFields = (formRef: RefObject<HTMLFormElement>, nestedIndex?: number) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<FileTypesFormValues>();
  const [selectedRows, setSelectedRows] = useState<FieldArrayWithId<FileTypesFormValues, "attributes", "id">[]>([]);

  const fieldsPath = (nestedIndex !== undefined ? `attributes.${nestedIndex}.nested` : "attributes") as "attributes";
  const fieldsData = watch()[fieldsPath];
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldsPath,
  });

  const addNewAttribute = async () => {
    append({
      name: "",
    } as any);
    await sleep(100);
    formRef.current?.scrollTo({ top: formRef.current?.scrollHeight, behavior: "smooth" });
  };

  const removeAttribute = () => {
    const selectedIds = selectedRows.map((row) => row.id);
    const indexes = fields
      .map((fld, index) => {
        if (selectedIds.includes(fld.id)) {
          return index;
        }
        return -1;
      })
      .filter((ind) => ind > 0);
    remove(indexes);
    setSelectedRows([]);
  };

  return {
    fields,
    selectedRows,
    control,
    errors,
    addNewAttribute,
    removeAttribute,
    setSelectedRows,
    fieldsPath,
    fieldsData,
  };
};

export const useFieldTypeForm = (closeModal: () => void, initialValues?: FileTypesFormValues) => {
  const qc = useQueryClient();
  const createFileType = useStore((state) => state.fileTypes.createFileType);

  const methods = useForm<FileTypesFormValues>({
    resolver: yupResolver<FileTypesFormValues>(schema as any),
    defaultValues: initialValues || { attributes: [{ name: "" }] },
  });

  const constructEntity = (
    entityName: string,
    columns: IAttribute[],
    relationsTables?: string[],
    hasRelation?: boolean
  ): IEntityBase => {
    const defaultCluster = ["id", "tenantId", "fileId"];
    let fields: { [x: string]: { type: SupportedColumnType } } = {
      ...columns
        .filter((col) => col.name.length > 0)
        .reduce(
          (acc, att) => ({
            ...acc,
            [snakeCase(att.name)]: {
              type: att.dataType,
              meta: {
                inputName: {
                  string: att.name,
                },
              },
            },
          }),
          {}
        ),
      ...defaultCluster.reduce((acc, key) => ({ ...acc, [key]: { type: "uuid" } }), {}),
    };
    if (hasRelation) {
      defaultCluster.push("parentId");
      fields = {
        ...fields,
        parentId: {
          type: "uuid",
        },
      };
    }
    const relations = relationsTables
      ? {
          array: relationsTables.map((tbl) => ({
            map: {
              table: {
                string: snakeCase(tbl),
              },
              tableFk: {
                string: "parentId",
              },
              tableFkTarget: {
                string: "id",
              },
            },
          })),
        }
      : {};

    return {
      kind: "Entity",
      name: snakeCase(entityName),
      cluster: defaultCluster,
      struct: {
        type: null,
        meta: {
          relations,
          inputName: {
            string: entityName,
          },
          scope: {
            array: [
              {
                string: "reporting",
              },
            ],
          },
          ...(relationsTables
            ? {
                parent: {
                  bool: true,
                },
                // NOTE(taras)
                // Only for parent entities, might change later
                entityType: {
                  string: "fileType",
                },
              }
            : {}),
        },
        fields: fields,
      },
    };
  };
  const handleSubmit = async (data: FileTypesFormValues) => {
    const dataAttrs = data.attributes.filter((att) => att.dataType !== "any");
    const nestedAttrs = data.attributes.filter((att) => att.dataType === "any");
    const entities = [
      constructEntity(
        data.name,
        dataAttrs,
        nestedAttrs.map((att) => att.name)
      ),
      ...nestedAttrs.map((att) => constructEntity(att.name, att.nested, undefined, true)),
    ];
    await createFileType(entities);

    await qc.invalidateQueries({
      queryKey: ["schemas"],
    });

    closeModal();
  };
  return {
    methods,
    handleSubmit: methods.handleSubmit(handleSubmit),
  };
};
