import React, { FC } from "react";
import { ButtonProps as Props } from "./types";
import { Icon } from "../Icon";
import { ClipLoader } from "react-spinners";
import { useButtonStyles } from "./useButtonStyles";
import styles from "./styles.module.css";
import { IconName } from "../Icon/types";

export const Button: FC<Props> = ({
  variant = "primary",
  disabled = false,
  loading,
  children,
  icon,
  disabledColor,
  color,
  border,
  fullWidth,
  style,
  iconSize,
  iconPlacement = "left",
  fontWeight,
  className,
  ...rest
}) => {
  const buttonStyles = useButtonStyles({
    color,
    fullWidth,
    icon,
    loading,
    variant,
    disabled,
    square: React.Children.count(children) === 0,
  });

  return (
    <button
      style={{ ...buttonStyles, ...style }}
      className={`${styles.customButton} ${className}`}
      disabled={disabled}
      {...rest}
    >
      {loading && <ClipLoader size={16} color={"inherit"} />}
      {icon &&
        !loading &&
        iconPlacement === "left" &&
        (typeof icon === "string" ? (
          <Icon name={icon as IconName} size={iconSize || "1.6em"} color={"inherit"} />
        ) : (
          icon
        ))}
      {React.Children.count(children) > 0 && (
        <p
          style={{
            fontSize: variant === "tag" ? "1.2em" : "1.4em",
            fontFamily: "inherit",
            fontWeight: fontWeight ?? 500,
          }}
        >
          {children}
        </p>
      )}
      {icon &&
        !loading &&
        iconPlacement === "right" &&
        (typeof icon === "string" ? (
          <Icon name={icon as IconName} size={iconSize || "1.6em"} color={"inherit"} />
        ) : (
          icon
        ))}
    </button>
  );
};
