import { useQuery } from "@tanstack/react-query";
import { useStore } from "../store";

export const useSoloWorkflows = (q?: string) => {
  const getter = useStore((store) => store.workflows.getWorkflows);

  return useQuery({
    queryKey: ["workflows", q],
    queryFn: async () => getter(q),
    refetchInterval: 2_000,
  });
};
