import { AvatarSize } from "./types";
import { CSSProperties } from "react";

export const useAvatarSize = (size: AvatarSize): CSSProperties => {
  switch (size) {
    case "profile":
      return {
        width: "4.8em",
        height: "4.8em",
        minWidth: "4.8em",
        minHeight: "4.8em",
        borderRadius: "2.5em",
      };
    case "list":
      return {
        width: "3.2em",
        height: "3.2em",
        minWidth: "3.2em",
        minHeight: "3.2em",
        borderRadius: "0.8em",
      };
    case "picker":
      return {
        width: "4.8em",
        height: "4.8em",
        minWidth: "4.8em",
        minHeight: "4.8em",
        borderRadius: "1.2em",
      };
    case "tiny":
      return {
        width: "2.4em",
        height: "2.4em",
        minWidth: "2.4em",
        minHeight: "2.4em",
        borderRadius: "0.4em",
      };
    case "mini":
      return {
        width: "4em",
        height: "4em",
        minWidth: "4em",
        minHeight: "4em",
        borderRadius: "1em",
      };
    case "small":
      return {
        width: "5.6em",
        height: "5.6em",
        minWidth: "5.6em",
        minHeight: "5.6em",
        borderRadius: "1.8em",
      };
    case "medium":
      return {
        width: "7.2em",
        height: "7.2em",
        minWidth: "7.2em",
        minHeight: "7.2em",
        borderRadius: "2.6em",
      };
    default:
    case "large":
      return {
        width: "15.2em",
        height: "15.2em",
        minWidth: "15.2em",
        minHeight: "15.2em",
        borderRadius: "2.6em",
      };
  }
};
