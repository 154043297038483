import { Store } from "../types";
import * as API from "../../axios/sdk";
import { StateCreator } from "zustand";

export const sdkSlice: StateCreator<Store, [], [], Store["sdk"]> = (set, get) => ({
  initialized: false,
  sdkConfigurations: {
    financials: { summary: true, balance_sheet: true, cashflow_statement: true, profit_and_loss: true },
    calendar: {},
    directory: { vendors: true, edit_contacts: true, customers: true, products: true },
    link_accounts: {
      plaid: true,
      shopify: true,
    },
    manage_accounts: {
      delete_digitally_connected_account: true,
      unlink_digitally_connected_account: true,
      delete_uploaded_bank_statement: true,
    },
    summary: {
      balance_sheet: true,
      burn: true,
      expense_brief: true,
      expense_details: true,
      income_brief: true,
      income_details: true,
      gross_profit: true,
      net_profit: true,
    },
    transactions: { edit_category: true, edit_contact_name: true },
  },
  setInitialized: (value) => {
    set((state) => ({
      sdk: { ...state.sdk, initialized: value },
    }));
  },
  setSDKConfigurations: async (payload) =>
    await API.saveSDKConfigurations(payload).then((data) => {
      set((state) => ({
        sdk: { ...state.sdk, sdkConfigurations: { ...state.sdk.sdkConfigurations, [payload.module]: payload.config } },
      }));
    }),
  getSDKConfigurations: async () =>
    await API.getSDKConfigurations().then((data) => {
      set((state) => ({
        sdk: { ...state.sdk, sdkConfigurations: data },
      }));
    }),
});
