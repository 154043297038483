import { Store } from "../types";
import * as API from "../../axios/workflows";
import { StateCreator } from "zustand";

export const workflowsSlice: StateCreator<Store, [], [], Store["workflows"]> = () => ({
  getWorkflows: async (q?: string) => {
    return API.getWorkflows(q);
  },
  triggerWorkflow: async (name: string, data: any) => {
    return API.triggerWorkflow(name, data);
  },
});
