import React, { ChangeEvent, Dispatch, FC, RefObject, SetStateAction, useState } from "react";
import { AntDropDown, Icon, IconName, Inputfield, Typography } from "../..";
import { ExpressionItem, Option } from "../../../types";
import styles from "./styles.module.css";
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, horizontalListSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable";
import { listSortingStrategy } from "./sortingStrategy";

interface IProps {
  formulaSearch: string;
  header: string;
  stringResultEnabled?: boolean;
  focused: boolean;
  disabled?: boolean;
  builderOpen?: boolean;
  placeholder?: boolean;
  searchBar?: boolean;
  inputPlaceholder?: string;
  handleRemoveExpression: (id: string) => void;
  setFormulaSearch: Dispatch<SetStateAction<string>>;
  expression: ExpressionItem[];
  setExpression: Dispatch<SetStateAction<ExpressionItem[]>>;
  setOpenLayout: Dispatch<SetStateAction<"dropdown" | "drawer" | undefined>>;
  resultScrollableRef?: RefObject<HTMLDivElement>;
}
export const ExpressionResult: FC<IProps> = ({
  expression,
  formulaSearch,
  setOpenLayout,
  header,
  builderOpen,
  setFormulaSearch,
  setExpression,
  disabled,
  resultScrollableRef,
  placeholder,
  stringResultEnabled,
  handleRemoveExpression,
  focused,
  inputPlaceholder,
  searchBar,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const hide = !focused && !!stringResultEnabled;
  const activeColor = stringResultEnabled
    ? "var(--primary-color)"
    : focused
    ? "var(--grey-scale-95)"
    : "var(--grey-scale-30)";
  const borderRadius = stringResultEnabled ? "0" : "0.4em";
  const background = stringResultEnabled ? "var(--white-scale-100)" : "var(--grey-scale-05)";

  const changeFormulaSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setFormulaSearch(value);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) {
      return;
    }
    if (active.id !== over.id) {
      setExpression((data) => {
        const oldIndex = data.findIndex((item) => item.id === active.id);
        const newIndex = data.findIndex((item) => item.id === over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }
  };

  return (
    <div
      className={`${styles.inner} ${hide && styles.hide} ${placeholder && styles.placeholder}`}
      style={{
        border: `1px solid ${activeColor}`,
        borderRadius,
        background,
        flexWrap: stringResultEnabled || searchBar ? "nowrap" : "wrap",
        padding: searchBar ? "0.8em 5em 0.8em 4em" : stringResultEnabled ? "0 0 0 1.6em" : "0.8em 5em 0.8em 1.6em",
      }}
    >
      {searchBar && (
        <Icon name={IconName.SEARCH} size={"2em"} color={"var(--grey-scale-50)"} className={styles.search} />
      )}
      <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
        <SortableContext
          items={expression}
          strategy={stringResultEnabled || searchBar ? horizontalListSortingStrategy : listSortingStrategy}
        >
          <div
            ref={resultScrollableRef}
            style={{
              flexWrap: stringResultEnabled || searchBar ? "nowrap" : "wrap",
            }}
            className={styles.expressions}
          >
            {expression.map((formulaItem, index) => (
              <Suggestion
                key={formulaItem.id + formulaItem.label + `${index}`}
                setOpenLayout={setOpenLayout}
                suggestion={formulaItem}
                handleRemoveExpression={handleRemoveExpression}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <Inputfield
        rowV={!builderOpen}
        placeholder={inputPlaceholder || `Search ${header.toLowerCase()} or operator...`}
        onChange={changeFormulaSearch}
        inputWrapperStyle={{ height: "2.4em" }}
        wrapperClassname={searchBar ? styles.inputSearch : stringResultEnabled ? styles.input : styles.input2}
        value={formulaSearch}
        disabled={disabled}
        mt={false}
        name={"formelaSearch"}
      />
      {searchBar ? (
        <div className={styles.iconWrapper}>
          <Icon size={"1.8em"} name={IconName.FILTER} />
        </div>
      ) : (
        !stringResultEnabled && (
          <Icon
            className={styles.icon}
            size={"1.8em"}
            name={IconName.CHEVRON_DOWN}
            style={focused ? { transform: "rotate(-180deg)", transition: "all 0.2s" } : { transition: "all 0.2s" }}
          />
        )
      )}
    </div>
  );
};

interface ISuggestionProps {
  suggestion: ExpressionItem;
  handleRemoveExpression: (id: string) => void;
  setOpenLayout: Dispatch<SetStateAction<"dropdown" | "drawer" | undefined>>;
}

const Suggestion: FC<ISuggestionProps> = ({ suggestion, setOpenLayout, handleRemoveExpression }) => {
  const [menuOpen, toggleMenu] = useState(false);

  const items: Option[] = [
    {
      label: "Remove",
      onClick: () => handleRemoveExpression(suggestion.id),
    },
  ];

  const { listeners, transform, transition, isDragging, setNodeRef } = useSortable({ id: suggestion.id });
  const commonStyle: React.CSSProperties = {
    cursor: "move",
    transition: "unset", // Prevent element from shaking after drag
  };
  const style = transform
    ? {
        ...commonStyle,
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition: isDragging ? "unset" : transition, // Improve performance/visual effect when dragging
      }
    : commonStyle;

  const handleOpenChange = (state: boolean) => {
    if (state) {
      setOpenLayout("dropdown");
    } else {
      setTimeout(() => {
        setOpenLayout(undefined);
      }, 50);
    }
    toggleMenu(state);
  };

  return (
    <div style={style} ref={setNodeRef} {...listeners}>
      <AntDropDown width={"12em"} options={items} onOpenChange={handleOpenChange}>
        {suggestion.type === "operator" ? (
          <div className={`${styles.operator} ${menuOpen && styles.active}`}>
            <Icon size={"1.6em"} square={suggestion.label !== IconName.AND} name={suggestion.label as any} />
          </div>
        ) : (
          <div className={`${styles.item} ${menuOpen && styles.active}`}>
            <Typography style={{ whiteSpace: "nowrap" }} variant={"body2"}>
              {suggestion.label}
            </Typography>
            <Icon
              size={"1.6em"}
              style={menuOpen ? { transform: "rotate(-180deg)", transition: "all 0.2s" } : { transition: "all 0.2s" }}
              name={IconName.CHEVRON_DOWN}
            />
          </div>
        )}
      </AntDropDown>
    </div>
  );
};
