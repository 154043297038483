import React, { FC } from "react";
import { Icon, IconName, Typography } from "shared/components";
import styles from "./styles.module.css";

interface IProps {
  title?: string;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  active?: boolean;
  checked?: boolean;
  stepCount: number;
  hideTrailing?: boolean;
}

export const Checkbox: FC<IProps> = ({ checked, stepCount, onClick, hideTrailing, active, title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.checkboxBody}>
        <div
          className={styles.box}
          onClick={onClick}
          style={{
            background: active ? "var(--gold)" : "var(--white-scale-100)",
            borderColor: active ? "var(--gold)" : "var(--grey-scale-20)",
          }}
        >
          {checked ? (
            <Icon name={IconName.CHECK} size={"1.4em"} color={"var(--grey-scale-95)"} />
          ) : (
            <Typography variant={"body3"} style={{ color: active ? "var(--white-scale-100)" : "var(--grey-scale-40)" }}>
              {stepCount}
            </Typography>
          )}
        </div>
        {title && (
          <Typography variant="body2" className={styles.text} color={active ? "var(--gold)" : "var(--grey-scale-40)"}>
            {title}
          </Typography>
        )}
      </div>
      {!hideTrailing && <div className={styles.divider1} style={{ background: "var(--grey-scale-20)" }} />}
    </div>
  );
};
